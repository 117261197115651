<template>
  <div>
    <ContentBlock v-if="contentOwnership.systemRequirements">
      <template #instructions>
        <h5>{{ $t('System Requirements') }}</h5>
      </template>
      <p
        class="f-body __content"
        v-html="contentOwnership.systemRequirements"
      ></p>
    </ContentBlock>
    <ContentBlock v-if="contentOwnership.operatorGame && contentOwnership.updateNotes">
      <template #instructions>
        <h5>{{ $t('Update notes') }}</h5>
        <p></p>
      </template>
      <div
        class="f-body __content"
        v-html="contentOwnership.updateNotes"
      ></div>
    </ContentBlock>
    <ContentBlock
      v-if="contentOwnership.contentOwnershipLanguages && contentOwnership.contentOwnershipLanguages.length"
    >
      <template #instructions>
        <h5>{{ $t('Supported Languages') }}</h5>
        <p></p>
      </template>
      <div>
        <table class="w-100 dt--fixed f-body">
          <thead class="bb b--geyser">
            <th class="f-thead w-50 tl pb3">{{ $t('Languages') }}</th>
            <th class="f-thead pb3">{{ $t('Interface') }}</th>
            <th class="f-thead pb3">{{ $t('Full Audio') }}</th>
            <th class="f-thead pb3">{{ $t('Subtitles') }}</th>
          </thead>
          <tbody>
            <tr
              v-for="(language, i) in contentOwnership.contentOwnershipLanguages"
              :key="language.displayLanguage"
            >
              <td
                class="v-mid pv3 pl3 truncate"
                :class="i % 2 === 0 ? 'bg-casper' : ''"
              >{{ language.displayLanguage }}</td>
              <td
                class="v-mid pv3 tc"
                :class="i % 2 === 0 ? 'bg-casper' : ''"
              >
                <UIIcon
                  v-if="language.interface"
                  name="check"
                  class="w2 fill-black"
                />
              </td>
              <td
                class="v-mid pv3 tc"
                :class="i % 2 === 0 ? 'bg-casper' : ''"
              >
                <UIIcon
                  v-if="language.fullAudio"
                  name="check"
                  class="w2 fill-black"
                />
              </td>
              <td
                class="v-mid pv3 tc"
                :class="i % 2 === 0 ? 'bg-casper' : ''"
              >
                <UIIcon
                  v-if="language.subtitles"
                  name="check"
                  class="w2 fill-black"
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </ContentBlock>
  </div>
</template>

<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import ContentBlock from '@/core/shared/components/ContentBlock';
import UIIcon from '@/core/shared/components/ui/UIIcon';

@Component({
  components: {
    ContentBlock,
    UIIcon,
  },
  props: {
    contentOwnership: {
      required: true,
      validator (val) {
        return val.__typename === 'ContentOwnership';
      },
    },
  },
})
export default class GameInfoDetails extends Vue {

}
</script>

<style scoped lang="postcss">
@import "core/shared/styles";
</style>
