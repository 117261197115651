<template>
  <div class="sub-header">
    <div class="tabscontainer">
      <slot name="tabs"/>
    </div>
    <slot/>
  </div>
</template>

<script>
import Vue from 'vue';
import Component from 'vue-class-component';

@Component()
export default class SubHeader extends Vue {

}
</script>

<style scoped lang="postcss">
@import "../styles";

.sub-header {
  @apply --panelDropShadow;
  width: 100%;
  margin: 0 0 var(--spacingSm) 0;
  background-color: var(--colorWhite);
  position: sticky;
  top: -1px;
  z-index: 100;
  @media (--tablet) {
    position: static;
  }
  @media (--desktop) {
    margin-bottom: var(--spacingMd);
  }

  & .tabscontainer {
    overflow-x: auto;
    width: 100%;
    & ul {
      display: flex;
      margin: 0;
      list-style-type: none;
      padding-left: 0;

      & li {
        @apply --f5;
        font-weight: 600;
        white-space: nowrap;
        margin: 0;
        cursor: pointer;
        padding: 2.6rem;
        padding-bottom: calc(2.6rem - 1rem);
        transition: border-bottom 0.3s ease;
        will-change: border-bottom;
        border-bottom: 0.5rem solid transparent;
        transform: translateZ(0);

        &:hover {
          background-color: var(--colorCasper);
        }

        &.router-link-active {
          border-bottom: 0.4rem solid var(--colorSlate);
        }
      }
    }
  }
}
</style>
