<template>
  <div class="content-show-route" v-if="game">
    <SubHeader class="subheader">
      <template #tabs>
        <ul class="tabs">
          <router-link tag="li" :to="{ name: 'content-station-management' }">
            <span class="relative">
              {{ $t('Station Management') }}
              <ErrorBadge v-if="hasStationManagementErrors" />
            </span>
          </router-link>
          <router-link tag="li" :to="{ name: 'content-details' }">
            <span class="relative">
              {{ $t('Content Details') }}
              <ErrorBadge v-if="hasPendingEULAs" />
            </span>
          </router-link>
          <router-link tag="li" :to="{ name: 'content-settings' }">{{ $t('Launch Settings') }}</router-link>
          <router-link
            tag="li"
            :to="{ name: 'content-controllers' }"
          >{{ $t('Controller Instructions') }}</router-link>
        </ul>
      </template>
    </SubHeader>
    <router-view
      :storedGame="storedGame"
      :game="game"
      :contentOwnership="contentOwnership"
      :loading="isLoading"
      @edit="editGame"
    />
    <BottomBar
      :valid="isDirty && isValid"
      :saving="isSaving"
      :backText="$t('Back')"
      @save="onSave"
      :backRouterLink="{ name: 'content-library' }"
    />
  </div>
</template>

<script>
import Component, { mixins } from 'vue-class-component';
import SubHeader from '@/core/shared/components/SubHeader';
import BottomBar from '@/core/shared/components/BottomBar';
import FocusModeMixin from '@/core/shared/misc/FocusModeMixin';
import { isEqual } from 'lodash-es';
import { fromContent } from '@/shared/content-station-management/module';

const ErrorBadge = {
  functional: true,
  name: 'ErrorBadge',
  render (h) {
    return h('svg', {
      class: 'errorbadge absolute right-0 top-0 nr3 fill-red',
      attrs: {
        width: '8',
        viewBox: '0 0 6 6',
      },
    }, [h('circle', {
      attrs: {
        r: '3',
        cx: '3',
        cy: '3',
      },
    })]);
  },

};
@Component({
  components: {
    SubHeader,
    BottomBar,
    ErrorBadge,
  },
  props: {
    gameId: {
      required: true,
    },
  },
  beforeRouteEnter (to, from, next) {
    // TODO handle direct hit from browser URL for disabled game
    const { query, name } = to;
    if (query.eula && name !== 'content-details') next({ ...to, name: 'content-details' });
    else next();
  },
  beforeRouteLeave (to, from, next) {
    if (this.isDirty) {
      this.$store.commit('setConfirm', {
        show: true,
        title: this.$t('Are you sure you want to quit without saving?'),
        message: this.$t('All of your changes will be lost, including any enabled content on stations.'),
        icon: 'error',
        buttons: [
          {
            name: this.$t('No, Go Back'),
            type: 'cancel',
            action: () => next(false),
          },
          {
            name: this.$t('Yes, Leave'),
            action: next,
          },
        ],
      });
    } else {
      next();
    }
  },
})
export default class ContentShowRoute extends mixins(FocusModeMixin) {
  gameLocalChanges = {}
  isSaving = false
  isLoading = false
  routeComponentsValidity = {}

  editGame (k, val, routeComponentName, routeComponentValidity) {
    if (routeComponentName) {
      this.$set(this.routeComponentsValidity, routeComponentName, routeComponentValidity);
    }
    const dirty = !isEqual(this.storedGame[k], val);
    if (dirty) this.$set(this.gameLocalChanges, k, val);
    else this.$delete(this.gameLocalChanges, k);
  }

  get isDirty () {
    return !!Object.keys(this.gameLocalChanges).length || !!Object.keys(this.stationGamesOverrides).length;
  }

  get isValid () {
    let isValid = true;
    for (const key in this.routeComponentsValidity) {
      if (!Object.prototype.hasOwnProperty.call(this.routeComponentsValidity, key)) continue;

      isValid &= this.routeComponentsValidity[key];
    }
    return Boolean(isValid);
  }

  get storedGame () {
    return this.$store.getters['entities/Game'](this.gameId);
  }

  get game () {
    if (!this.storedGame) return;
    return new Proxy(this.storedGame, {
      get: (obj, key) => {
        return key in this.gameLocalChanges ? this.gameLocalChanges[key] : obj[key];
      },
    });
  }

  get hasPendingEULAs () {
    return this.contentOwnership && this.contentOwnership.operatorLicense && (this.contentOwnership.operatorLicense.promptForCurrentLicense || this.contentOwnership.operatorLicense.promptForUpcomingLicense);
  }

  get hasStationManagementErrors () {
    const {
      installErrorMessage,
      cdsTransitionError,
    } = this.storedGame;
    return installErrorMessage || cdsTransitionError;
  }

  get stationGamesOverrides () {
    return this.$store.state[`stationManagement:${this.gameId}`].stationGamesOverrides;
  }

  get contentOwnership () {
    return this.storedGame && this.storedGame.ownership;
  }

  async created () {
    this.isLoading = true;
    this.$store.commit('setLoading', true);
    this.$store.registerModule(`stationManagement:${this.gameId}`, fromContent);
    this.$store.commit(`stationManagement:${this.gameId}/setGameId`, this.gameId);
    if (this.game) {
      this.setTitleAndBreadcrumb();
    }
    try {
      await this.$store.dispatch('fetchGame', { id: this.gameId });
    } finally {
      this.isLoading = false;
    }
    this.setTitleAndBreadcrumb();
    this.$store.commit('setLoading', false);
  }

  destroyed () {
    this.$store.unregisterModule(`stationManagement:${this.gameId}`);
  }

  setTitleAndBreadcrumb () {
    this.$store.commit('setTitle', this.game.title);
    this.$store.commit('setBreadcrumbItems', [
      {
        name: this.$t('My Library'),
        routerLink: { name: 'content-library' },
      },
      {
        name: this.game.title,
      },
    ]);
  }

  async onSave () {
    this.isSaving = true;
    this.$store.commit('setLoading', true);
    const successMsg = this.$t('Changes succesfully saved.');
    const errorMsg = this.$t('There was an error saving your changes, please try again.');
    try {
      const promises = [];
      promises.push(this.$store.dispatch(`stationManagement:${this.gameId}/persistState`));
      if (Object.keys(this.gameLocalChanges).length) {
        promises.push(this.$store.dispatch('persistGame', { id: this.gameId, ...this.gameLocalChanges }));
      }
      await Promise.all(promises);
      this.gameLocalChanges = {};

      this.$store.commit('setFlash', {
        type: 'success',
        message: successMsg,
      });
      this.$router.push({ name: 'content-library' });
    } catch (e) {
      this.$store.commit('setFlash', {
        type: 'error',
        message: errorMsg,
      });
      throw e;
    } finally {
      this.$store.commit('setLoading', false);
      this.isSaving = false;
    }
  }
}
</script>

<style scoped lang="postcss">
@import "core/shared/styles";

.content-show-route {
  & .container {
    @apply --container;
  }
  & .subheader {
    margin-bottom: 0;
  }
}
</style>
