import { ref, computed } from '@vue/composition-api';
import { useLoading } from '@/core/shared/composition-helpers';
import { marketplaceQuery } from '../store/actions';
import store from '@/core/store';
export function useFeaturedContent () {
  const ids = ref();
  const featuredContent = computed(() => ids.value && store.getters['entities/ContentOwnership'](ids.value));
  const { loading, subscribeToPromise } = useLoading();
  (async function fetchFeaturedContent () {
    const promise = (async function () {
      const data = await marketplaceQuery({
        filterFeatured: true,
      }, { cancelKey: 'featuredContentQuery' }); ;
      if (data) {
        const {
          contentOwnerships,
        } = data.marketplaceContentOwnerships;
        ids.value = await store.dispatch('insertEntities', {
          name: 'ContentOwnership',
          payload: contentOwnerships,
        });
      }
    })();
    subscribeToPromise(promise);
  })();
  return {
    loading,
    featuredContent,
  };
}
