
<script>
import UIFileUpload from '../components/ui/UIFileUpload';

import FileHelper from '../helpers/FileHelper';
const { file, loading, ...inheritedProps } = UIFileUpload.options.props;

export default {
  name: 'FileUploadWithThumbnail',
  props: {
    ...inheritedProps,
    id: { required: true },
    type: { type: String },
    media: {},
  },
  model: {
    prop: 'internalValue',
  },
  data: () => ({
    loading: null,
  }),
  render (h) {
    const tag = (this.type === 'image' && 'img') || (this.type === 'video' && 'video');
    const mediaNode = this.media && this.media.src && h(tag, {
      style: {
        position: 'absolute',
        top: 0,
        left: 0,
      },
      attrs: {
        src: this.media.src,
        controls: 'controls',
        poster: this.media.poster,
        disablepictureinpicture: true,
        controlslist: 'nodownload',
      },
    });
    return h(UIFileUpload, {
      props: {
        ...this.$props,
        loading: this.loading,
        file: this.media && { name: this.media.title || '' },
      },
      attrs: this.$attrs,
      on: {
        clear: () => {
          this.$emit('clear', { id: this.id });
        },
        change: async ([file]) => {
          const { name, type } = file;
          const isImage = type.match(/image/);
          const isVideo = type.match(/video/);
          const media = {
            name: this.id,
            title: name,
            file,
          };
          try {
            this.loading = true;
            if (isImage) {
              media.src = await FileHelper.encodeImage(file);
            } else if (isVideo) {
              media.src = await FileHelper.encodeVideo(file);
            }
          } finally {
            this.loading = false;
          }
          this.$emit('input', {
            ...media,
            id: this.id,
          });
        },
      },
    }, [
      mediaNode,
    ]);
  },
};
</script>
