<template>
  <div class="tags-route">
    <div class="headerbar">
      <div class="info">
        <h2 class="f3">{{ $t('Tags in the Launcher') }}</h2>
        <p
          class="f5"
          v-if="$permissions['content-only']"
        >{{ $t("Tags are used to help players find content in the SpringboardVR launcher. You can reorder the tags here to change their order in the launcher.") }}</p>
        <p
          class="f5"
          v-else
        >{{ $t("Tags are used to help players find content in the launcher. You can reorder the tags here to change their order in the launcher. If you don't want to show tags in the main view in the launcher, go to your experiences and edit Launcher Home Screen Tags Layout.") }}</p>
      </div>
      <UIButton
        class="newtag"
        :to="{ name: 'tag-show', params: { tagId: 'new' } }"
        primary
      >{{ $t('New Tag') }}</UIButton>
    </div>

    <div class="tagscontainer">
      <div class="empty f-body tc w-60 center fill-geyser fadeIn animated" v-if="noTags">
        <UIIcon name="no-results" class="dib mv5" style="width:8rem;" />
        <i18n
          tag="p"
          path="You don't have tags yet. Click on {newTagLink} to create a new tag, like 'Staff Favorites', 'Action' or 'Sports'."
        >
          <template #newTagLink>
            <router-link class="link-style" :to="{ name: 'new-tag' }">{{ $t('New Tag') }}</router-link>
          </template>
        </i18n>
      </div>

      <UIRowIndexLoader v-if="isLoading" />

      <div class="tagsgrid" v-if="!isLoading && !noTags">
        <div class="tagsheadergrid f6 fadeIn animated">
          <UIIcon class="w2 draghandle" name="reorder" />
          <div class="mobile">{{ $t('Tag Name') }} / {{ $t('Number Of Titles') }}</div>
          <div class="tablet">{{ $t('Tag Name') }}</div>
          <div class="tablet">{{ $t('Number Of Titles') }}</div>
        </div>
        <Draggable
          tag="div"
          class="fadeIn animated tagsgriditems"
          :value="tags"
          v-bind="draggableOpts"
          @input="sortTags"
        >
          <TagListItem v-for="tag in tags" :key="tag.id" :tag="tag" />
        </Draggable>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import UIButton from '@/core/shared/components/ui/UIButton';
import UIIcon from '@/core/shared/components/ui/UIIcon';
import UIRowIndexLoader from '@/core/shared/components/ui/UIRowIndexLoader';
import TagListItem from './components/TagListItem';
import Panel from '@/core/shared/components/Panel';
import Draggable from 'vuedraggable';

@Component({
  components: {
    Draggable,
    UIButton,
    UIIcon,
    Panel,
    TagListItem,
    UIRowIndexLoader,
  },
})
export default class TagsIndexRoute extends Vue {
  get tags () {
    return this.$store.getters['entities/Tag/all']().sort((a, b) => a.order - b.order);
  }

  get draggableOpts () {
    return { handle: '.draghandle', animation: 150, scroll: true };
  }

  get isLoading () {
    return this.$store.state.tags.isFetchingTags && this.tags.length === 0;
  }

  get noTags () {
    return !this.$store.state.tags.isFetchingTags && this.tags.length === 0;
  }

  created () {
    this.$store.commit('setTitle', this.$t('Tags'));
  }

  sortTags (tags) {
    this.$store.dispatch('saveTagsOrder', tags);
  }
}
</script>

<style scoped lang="postcss">
@import "core/shared/styles";

.tags-route {
  @apply --container;
  margin: 3rem 0 0 0;

  & .tagscontainer {
    display: grid;
    grid-gap: var(--spacingSm);
  }

  & .headerbar {
    display: grid;
    grid-gap: var(--spacingMd);
    margin-bottom: var(--spacingMd);

    @media (--tablet) {
      align-items: center;
      grid-template-columns: minmax(auto, 50rem) auto;

      & .newtag {
        min-width: 15rem;
        justify-self: end;
      }
    }
  }

  & .tagsgrid {
    display: grid;
    grid-gap: var(--spacingSm);

    & .tagsgriditems {
      display: grid;
      grid-gap: var(--spacingSm);
    }

    & .tagsheadergrid {
      display: grid;
      grid-template-columns: auto 1fr;
      align-items: center;
      grid-gap: var(--spacingSm);
      padding: 0 var(--spacingSm);
      position: relative;
      left: 0.2rem;

      @media (--tablet) {
        grid-gap: var(--spacingMd);
        padding: 0 var(--spacingAltMd);
        grid-template-columns: auto 1fr auto 5.5rem;
      }

      & .draghandle {
        fill: var(--colorFjord);
      }
    }
  }
}
</style>
