<template>
  <div class="content-show-details-route">
    <template v-if="localGame">
      <ValidationObserver ref="validationObserver">
        <BreadcrumbNavConnected class="mb4" />
        <ContentBlock>
          <template #instructions>
            <h5>{{ $t('Content Basics') }}</h5>
            <p>{{ $t('Basic information for this title.') }}</p>
          </template>
          <GameBasicsForm
            :game="game"
            :disableTitle="!!game.preset || game.cds"
            @edit="editGame"
          />
        </ContentBlock>
        <ContentBlock>
          <template #instructions>
            <h5>{{ $t('Content Category') }}</h5>
            <p>{{ $t('This information will be visible in the Launcher to your customers and help categorize your content.') }}</p>
          </template>
          <GameDetailsForm
            :game="game"
            :contentOwnership="contentOwnership"
            @edit="editGame"
          ></GameDetailsForm>
        </ContentBlock>
        <GameReviewDetails
          v-if="contentOwnership"
          :contentOwnership="contentOwnership"
        />
        <GameHeadsetCompatibility
          v-if="contentOwnership"
          :game="contentOwnership"
        />
        <GameInfoDetails
          v-if="contentOwnership"
          :contentOwnership="contentOwnership"
        />
      </ValidationObserver>
    </template>
  </div>
</template>

<script>
import Component from 'vue-class-component';
import Vue from 'vue';
import ContentBlock from '@/core/shared/components/ContentBlock';
import BreadcrumbNavConnected from '@/core/shared/components/_connected/BreadcrumbNavConnected';
import GameReviewDetails from '@/content/shared/GameReviewDetails';
import GameHeadsetCompatibility from '@/content/shared/GameHeadsetCompatibility.vue';
import GameInfoDetails from '@/content/shared/GameInfoDetails';
import GameDetailsForm from '@/content/shared/GameDetailsForm';
import GameBasicsForm from '@/content/shared/GameBasicsForm';
import RatioContainer from '@/core/shared/components/utils/RatioContainer';
import { ValidationObserver } from 'vee-validate';

@Component({
  components: {
    ContentBlock,
    BreadcrumbNavConnected,
    GameBasicsForm,
    GameDetailsForm,
    RatioContainer,
    GameReviewDetails,
    GameHeadsetCompatibility,
    GameInfoDetails,
    ValidationObserver,
  },
  props: {
    game: { required: true },
    contentOwnership: { required: true },
  },
})
export default class ContentShowDetailsRoute extends Vue {
  async editGame (k, val) {
    const isValid = await this.$refs.validationObserver.validate({ silent: true });
    this.$emit('edit', k, val, 'content-show-details-route', isValid);
  }

  get localGame () {
    return new Proxy(this.game, {
      get (obj, k) {
        return obj[k];
      },
      set: (obj, k, val) => {
        this.editGame(k, val);
        return true;
      },
    });
  }
}
</script>

<style scoped lang="postcss">
@import "core/shared/styles";

.content-show-details-route {
  @apply --container;
  padding-top: var(--spacingSm);
  @media (--desktop) {
    padding-top: var(--spacingMd);
  }
}
</style>
