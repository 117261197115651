<template>
  <div class="content-add-route">
    <LicenceSourceModalWrapper ref="licensingsrcmodal" />
    <BreadcrumbNavConnected class="mb4" />
    <ContentBlock>
      <template #instructions>
        <h5>{{ $t('Add a Title Manually') }}</h5>
        <p>{{ $t('Select a content preset with pre-filled content details or enter content details from scratch.') }}</p>
      </template>
    </ContentBlock>
    <div class="center w-90-l w-80-m flex-l items-center justify-center">
      <UIFormItem
        class="flex-auto w-50-l mb4 mb0-m"
        :root="true"
        :title="$t('Select a Content Preset')"
        :instructions="$t('Select from our growing database of titles, and use our pre-filled details for images, videos, descriptions and launch parameters.')"
      >
        <div class="flex">
          <UIMultiselect
            class="tagselector flex-auto"
            :placeholder="$t('Search for content here')"
            :value="selectedPreset"
            id="presets"
            label="title"
            track-by="id"
            open-direction="bottom"
            :options="presets"
            :searchable="true"
            :loading="isLoading"
            :internal-search="false"
            :close-on-select="true"
            :options-limit="300"
            :max-height="600"
            :show-no-results="true"
            :show-no-options="false"
            :hide-selected="true"
            @search-change="searchPresets"
            @select="selectPreset"
            @close="presets = []; isLoading = false"
          >
            <template #option="{ option }">
              <div class="flex items-center">
                <div class="w-25 mr2">
                  <RatioContainer :ratio="46.5863">
                    <img :src="option.imageUrl" />
                  </RatioContainer>
                </div>
                <h6 class="flex-auto truncate">{{ option.title }}</h6>
              </div>
            </template>
            <template #noResult>{{ $t('No presets found.') }}</template>
          </UIMultiselect>
          <UIButton
            class="addbtn"
            :disabled="!selectedPreset"
            @click="createNewEntryFromPreset"
          >{{$t('Add')}}</UIButton>
        </div>
      </UIFormItem>
      <div class="ph6 tc flex items-center db-l">
        <span class="separator db w-50 h3-l b--geyser"></span>
        <span class="dib f-tiny slate pv4 ph4 ttu">{{$t('or')}}</span>
        <span class="separator db w-50 h3-l b--geyser"></span>
      </div>
      <UIFormItem
        class="flex-auto w-50-l"
        :root="true"
        :instructions="$t('This option will require you to input all of the content’s details and information.')"
      >
        <template #title>&nbsp;</template>
        <UIButton
          secondary
          class="w-100"
          @click="createNewEntry"
        >{{$t('Create a Custom Content Entry')}}</UIButton>
      </UIFormItem>
      <BottomBar :backOnly="true" :backRouterLink="{ name: 'content-library' }" />
    </div>
  </div>
</template>

<script>
import Component, { mixins } from 'vue-class-component';
import FocusModeMixin from '@/core/shared/misc/FocusModeMixin';
import ContentBlock from '@/core/shared/components/ContentBlock';
import UIFormItem from '@/core/shared/components/ui/UIFormItem';
import UIButton from '@/core/shared/components/ui/UIButton';
import UIMultiselect from '@/core/shared/components/ui/UIMultiselect';
import BreadcrumbNavConnected from '@/core/shared/components/_connected/BreadcrumbNavConnected';
import BottomBar from '@/core/shared/components/BottomBar';
import { createModalWrapper } from '@/core/shared/helpers/HOCHelper';
import LicenceSourceModal from '@/content/library/components/LicenceSourceModal';
import RatioContainer from '@/core/shared/components/utils/RatioContainer';

const LicenceSourceModalWrapper = createModalWrapper(LicenceSourceModal);
@Component({
  components: {
    ContentBlock,
    UIFormItem,
    UIButton,
    UIMultiselect,
    BottomBar,
    RatioContainer,
    LicenceSourceModalWrapper,
    BreadcrumbNavConnected,
  },
})
export default class ContentAddRoute extends mixins(FocusModeMixin) {
  presets = []
  isLoading = false
  selectedPreset = null
  created () {
    this.$store.commit('setTitle', this.$t('Add a Title Manually'));
    this.setBreadcrumb();
  }

  selectPreset (val) {
    this.selectedPreset = val;
  }

  async searchPresets (query) {
    if (!query) return;
    this.isLoading = true;
    this.presets = [];
    try {
      const games = await this.$store.dispatch('searchPresets', query);
      if (games) this.presets = games;
    } finally {
      this.isLoading = false;
    }
  }

  createNewEntryFromPreset () {
    this.$refs.licensingsrcmodal.show(null, {
      submit: async ({ licensingSource }) => {
        this.$store.commit('contentNew/setLicensingSource', licensingSource);
        await this.$store.dispatch('contentNew/prefillWithPreset', this.selectedPreset);
        this.$router.push({ name: 'content-new' });
      },
    });
  }

  createNewEntry () {
    this.$refs.licensingsrcmodal.show(null, {
      submit: ({ licensingSource }) => {
        this.$store.commit('contentNew/setLicensingSource', licensingSource);
        this.$router.push({ name: 'content-new' });
      },
    });
  }

  setBreadcrumb () {
    this.$store.commit('setBreadcrumbItems', [
      {
        name: this.$t('My Library'),
        routerLink: { name: 'content-library' },
      },
      {
        name: this.$t('Add a Title Manually'),
      },
    ]);
  }
}
</script>

<style scoped lang="postcss">
@import "core/shared/styles";

.content-add-route {
  @apply --container;
  padding-top: var(--spacingSm);
  @media (--desktop) {
    padding-top: var(--spacingMd);
  }
  & .separator {
    border-style: solid;
    border-bottom-width: 1px;
    @media (--tablet) {
      border-bottom-width: 0;
      border-right-width: 1px;
    }
  }
  & .tagselector {
    z-index: 201;
    position: relative;
  }
  & .tagselector >>> .multiselect__tags {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
  }
  & .addbtn {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
  }
}
</style>
