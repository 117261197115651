<template>
  <div class="marketplace-notices-bar">
    <div
      v-if="contentOwnership.operatorLicense && contentOwnership.operatorLicense.disabledForOutOfDateAgreement"
      class="f5 bg-orange"
    >{{ $t('This content was disabled from your Launcher and your Library because you didn’t accept the new End User License Agreement.') }}</div>
    <div
      v-else-if="game"
      class="f5 bg-blue"
    >{{ $t('You already own this content.') }}</div>
    <div
      v-else-if="!canLicenseContent"
      class="f5 bg-orange"
    >{{ $t('You must be on a paid plan to use our commercial licensing features.') }}</div>
  </div>
</template>

<script>
export default {
  props: {
    contentOwnership: {
      type: Object,
      required: true,
    },
    game: {
      type: Object,
      required: true,
    },
    canLicenseContent: {
      type: Boolean,
      required: true,
    },
  },
};
</script>

<style lang="postcss" scoped>
@import "core/shared/styles";

.marketplace-notices-bar {
  text-align: center;
  color: white;

  & > * {
    padding: 1rem;
  }
}
</style>
