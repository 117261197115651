<template>
  <div class="marketplace-bundle-show-route">
    <template v-if="bundle && bundle.contentOwnership">
      <PurchaseModalWrapper
        ref="purchasemodal"
        class="purchasemodal"
        :title="bundle.title"
        :bundle="bundle"
      />
      <HeaderGameShow usage="marketplace" class="heroheader" :content="bundle.contentOwnership"></HeaderGameShow>
      <div class="infocontainer container f-body">
        <ContentBlock>
          <template #instructions>
            <h5>{{ $t('Included Items') }}</h5>
            <p>{{ $t('These titles are part of the bundle.') }}</p>
          </template>

          <div class="content-table w-100">
            <div class="content-item-tr f-thead" v-for="content in bundle.contents" :key="content.id">
              <div class="imagecell dtc-m" @click="openContent(content)">
                <div v-if="content.contentOwnership && content.contentOwnership.badgeTop10" class="top10badgeshadow f-body b absolute left-0 mt4">
                  <span class="top10badge fill-white tc dib bg-gradient-red white pa3 ph4">
                    <svg
                      viewBox="0 0 95 69"
                      xmlns="http://www.w3.org/2000/svg"
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      class="white w2 ml2 v-mid dib"
                      stroke-linejoin="round"
                      stroke-miterlimit="2"
                    >
                      <path
                        d="M75.9056732 68.2818104H18.8912668L-.0005796 8.7259l31.7688 18.3411872L47.39847.0000696l15.6090704 27.0246592 31.7688-18.3411872-18.8706672 59.5982688zm-50.83008-8.47168h44.6457536L80.120334 26.9188328 59.894198 38.6097512 47.39847 16.9434296 34.902742 38.6097512 14.676606 26.9188328l10.3989872 32.8912976z"
                        fill-rule="nonzero"
                      />
                    </svg>
                    <span class="ml2 v-mid dib">{{ $t('Top 10') }}</span>
                  </span>
                </div>
                <div class="badge absolute right-0 z-5 f-tiny white mt3 flex flex-column items-end" style="margin-top:1rem;">
                  <span v-if="content.contentOwnership.game" class="librarybadge bg-green ph2 pv1 db mb2">{{ $t('In Library') }}</span>
                  <span v-if="content.contentOwnership.bundle" class="bundlebadge bg-red ph2 pv1 db mb2">{{ $t('Bundle') }}</span>
                </div>
                <div class="image">
                  <RatioContainer class="ratiocontainer" :ratio="46.5863">
                    <LazyImg
                      v-if="content.contentOwnership.imageUrl"
                      :src="content.contentOwnership.imageUrl"
                      :srcset="content.contentOwnership.imageSrcSet"
                      :placeholder="content.contentOwnership.imageSvgUrl"
                      :alt="content.contentOwnership.title"
                      class="posterimg db w-100 h-100 z-3"
                    />
                  </RatioContainer>
                </div>
              </div>
              <div>
                <h5 @click="openContent(content)" class="content-title f-header nowrap dib truncate slate w-100">{{ content.contentOwnership.title }}</h5>
                <div v-if="isPanCcBundle">{{ content.contentOwnership.organization.name }}</div>
                <div>{{ getGenres(content.contentOwnership) }}</div>
              </div>
              <div class="discount-cell text-right">
                <div>{{ discountPercentageText(content) }}</div>
              </div>
              <div v-if="content.discountPercentage > 0" class="total-cell text-right">
                <div class="regular-price">${{ regularPrice(content) }}</div>
                <div>${{ discountedPrice(content) }}</div>
              </div>
              <div v-else class="total-cell text-right">
                <div>${{ regularPrice(content) }}</div>
              </div>
            </div>
            <div class="content-item-tr f-thead">
              <div>&nbsp;</div>
              <div>&nbsp;</div>
              <div class="text-right">Regular price</div>
              <div class="regular-price text-right">${{ regularTotal() }}</div>
            </div>
            <div class="content-item-tr f-thead">
              <div>&nbsp;</div>
              <div>&nbsp;</div>
              <div class="total-price text-right">Bundle price</div>
              <div class="total-price text-right">${{ discountedTotal() }}</div>
            </div>
          </div>
        </ContentBlock>
      </div>
      <BottomBar
        :backRouterLink="{ name: 'marketplace' }"
        :backText="$mq.current === 'phone' ? $t('Back') : $t('Back to the Marketplace')"
        :saving="isActivatingLicense"
        :actionText="bottomBarActionText"
        :actionRouterLink="bottomBarRouterLink"
        @save="purchaseBundle"
      >
      </BottomBar>
    </template>
  </div>
</template>

<script>
import Component, { mixins } from 'vue-class-component';
import GameReviewDetails from '@/content/shared/GameReviewDetails';
import GameInfoDetails from '@/content/shared/GameInfoDetails';
import PurchaseBundleModal from '@/content/shared/PurchaseBundleModal';
import BreadcrumbNavConnected from '@/core/shared/components/_connected/BreadcrumbNavConnected';
import BottomBar from '@/core/shared/components/BottomBar';
import HeaderGameShow from '@/content/shared/HeaderGameShow';
import ContentBlock from '@/core/shared/components/ContentBlock';
import FocusModeMixin from '@/core/shared/misc/FocusModeMixin';
import RatioContainer from '@/core/shared/components/utils/RatioContainer';
import UIIcon from '@/core/shared/components/ui/UIIcon';
import UIButton from '@/core/shared/components/ui/UIButton';
import { createModalWrapper } from '@/core/shared/helpers/HOCHelper';
const PurchaseModalWrapper = createModalWrapper(PurchaseBundleModal, { requireAgreement: false });
import LazyImg from '@/core/shared/components/utils/LazyImg';
import { i18n } from '@/core/setup/i18n';

@Component({
  components: {
    GameReviewDetails,
    GameInfoDetails,
    PurchaseBundleModal,
    PurchaseModalWrapper,
    ContentBlock,
    BreadcrumbNavConnected,
    HeaderGameShow,
    BottomBar,
    UIIcon,
    UIButton,
    RatioContainer,
    LazyImg,
  },
  props: {
    bundleId: {
      required: true,
    },
  },
  data: () => {
    return {
      bundle: {},
    };
  },
})
export default class MarketplaceBundleRoute extends mixins(FocusModeMixin) {
  get bottomBarRouterLink () {
    // if (!this.canLicenseBundle && !this.subscriptionExclusion) return { name: 'billing-account', query: { subscribe: 1 } };
    return null;
  }

  get bottomBarActionText () {
    return !this.hasBundle ? i18n.t('Purchase') : i18n.t('Change');
  }

  get subscriptionExclusion () {
    return this.$store.getters.currentOrganization.subscriptionExclusion;
  }

  get hasMarketplaceAccess () {
    return this.$store.getters.currentOrganization.hasMarketplaceAccess;
  }

  get hasBundle () {
    return this.bundle.licenses.some(x => x.operator.id === this.$store.getters.currentOrganization.id);
  }

  // get canLicenseBundle () {
  //   return this.hasMarketplaceAccess;
  // }

  get isOnTrial () {
    return this.$store.getters.currentOrganization.isOnTrial;
  }

  isActivatingLicense = false
  // get bundle () {
  //   return this.$store.getters['entities/Bundle'](this.bundleId);
  // }

  get contentOwnershipGenres () {
    return this.contentOwnership && this.contentOwnership.genres && this.contentOwnership.genres.map(({ title }) => title).join(', ');
  }

  get contentOwnerships () {
    return this.bundle.contents.map(x => x.contentOwnership);
  }

  get isPanCcBundle () {
    const firstId = this.bundle.contents[0].contentOwnership.organizationId;
    return !this.bundle.contents.every(x => x.contentOwnership.organizationId === firstId);
  }

  openContent (content) {
    const routeData = this.$router.resolve({ name: 'marketplace-content-show', params: { contentOwnershipId: content.contentOwnership.id } });
    window.open(routeData.href, '_blank');
  }

  getGenres (contentOwnership) {
    return contentOwnership.genres
      ? contentOwnership.genres.filter(x => x.title !== 'Multiplayer').map(x => x.title).join(', ')
      : '';
  }

  discountPercentageText (content) {
    return content.discountPercentage
      ? `${content.discountPercentage}% OFF`
      : '';
  }

  regularPrice (content) {
    if (content.contentOwnership.monthlyPrice) {
      return Math.round(content.contentOwnership.monthlyPrice.current.price) / 100;
    }
    return 0;
  }

  regularTotal () {
    let total = 0;
    this.bundle.contents.forEach(x => {
      total += this.regularPrice(x);
    });
    return Math.round(total * 100) / 100;
  }

  discountedPrice (content) {
    if (content.contentOwnership.monthlyPrice) {
      return Math.round(content.contentOwnership.monthlyPrice.current.price * (1 - content.discountPercentage / 100)) / 100;
    }
    return 0;
  }

  discountedTotal () {
    let total = 0;
    this.bundle.contents.forEach(x => {
      total += this.discountedPrice(x);
    });
    return Math.round(total * 100) / 100;
  }

  async created () {
    this.bundle = await this.$store.dispatch('fetchBundleWithOperatorLicense', this.bundleId);
    this.$store.commit('setTitle', this.bundle.title);
    this.setBreadcrumb();
  }

  setBreadcrumb () {
    this.$store.commit('setBreadcrumbItems', [
      {
        name: this.$t('Marketplace'),
        routerLink: { name: 'marketplace' },
      },
      {
        name: this.bundle.title,
      },
    ]);
  }

  purchaseBundle () {
    const showModal = (contentOwnerships, { next, cancel }) => this.$refs.purchasemodal.show({
      contentOwnerships,
    }, {
      next: (order) => { next(order); },
      cancel: () => { cancel(); },
    });
    showModal(this.contentOwnerships, {
      next: (order) => {
        this.$store.dispatch('purchaseBundle', order);
      },
      cancel: () => { },
    });
  }

  showDeclineNotification () {
    const message = this.$t('You have to agree to all End User License Agreements to get this bundle.');
    this.$store.commit('setFlash', {
      type: 'info',
      message,
    });
  }
}
</script>

<style scoped lang="postcss">
@import "core/shared/styles";

.marketplace-bundle-show-route {
  & .container {
    @apply --contentContainer;
  }
  & .infocontainer {
    padding-top: var(--spacingLg);

    & .content-row {
      cursor: pointer;
    }
  }

  & h5 {
    @apply --f3;

    margin: var(--spacingXS) 0;
    @media (--tablet) {
      margin: var(--spacingSm) 0;
    }
  }

  & .posterimg {
    background: var(--colorPlaceholderGradient);
  }
  & .top10badge {
    line-height: 0;
    clip-path: polygon(
      100% 0,
      calc(100% - 8px) 50%,
      100% 100%,
      0% 100%,
      0 50%,
      0% 0%
    );
  }
  & .top10badgeshadow {
    left: -10px;
    filter: drop-shadow(4px 2px 4px rgba(50, 50, 0, 0.5));
    z-index: 500;
  }
  & .librarybadge {
    box-shadow: 0 0 0.4rem 0 rgba(0, 0, 0, 0.25);
  }
  & .regular-price {
    text-decoration: line-through;
    color: red;
  }
}

& .content-table {
  display: grid;
  grid-template-columns: 1fr;
}

.content-item-tr {
  display: grid;
  grid-template-columns: 1fr;
  grid-column-gap: 10px;
  border-spacing: 100px;
  margin-bottom: var(--spacingSm);

  @media (--tablet) {
    grid-template-columns: 33% 25% 25% 12%;
  }

  & .w-1 {
    width: 1px;
  }
  & >>> .w2 {
    width: 2em;
  }

  & .imagecell {
    cursor: pointer;

    & .image {
      & .ratiocontainer {
        overflow: hidden;
      }
    }
  }

  & .content-title {
    cursor: pointer;
  }

  & .discount-cell {
    color: var(--colorSlate);
    font-weight: bold;
    padding-top: var(--spacingSm);
  }

  & .total-cell {
    color: var(--colorSlate);
    font-weight: bold;
    padding-top: var(--spacingSm);
  }

  & .total-price {
    color: var(--colorSlate);
    font-weight: bold;
  }
}

.text-right {
  text-align: right;
}
</style>
