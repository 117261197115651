<script>
export default {
  name: 'FunctionalState',
  data: () => ({
    state: {},
  }),
  methods: {
    async setState (state) {
      Object.keys(state).forEach((key) => {
        this.$set(this.state, key, state[key]);
      });
      return this.$nextTick();
    },
  },
  render (h) {
    return this.$scopedSlots.default(this.state);
  },
};
</script>
