<template>
  <div class="tag-details">
    <!-- <div>{{ localTag }}</div> -->
    <div class="inputs">
      <div class="input measure-wide">
        <UIInput type="text" v-model="localTag.text" :title="$t('Tag Name')" required rules="required" />
      </div>

      <div class="input operator-switch ">
        <UISwitch
          :title="$t('Operator only')"
          :instructions="$t('Hide the tag from the customer')"
          v-model="localTag.operatorOnly"
        />
      </div>
    </div>

    <div class="stats">
      <div class="titleswithtag">
        <div class="label f6">{{ $t('Number of Titles with this Tag') }}</div>
        <div class="value f3">{{ tag.games.length }}</div>
      </div>
      <div class="titleswithouttag">
        <div class="label f6">{{ $t('Number of Titles in My Library') }}</div>
        <div class="value f3">{{ games.length }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import UIInput from '@/core/shared/components/ui/UIInput';
import UISwitch from '@/core/shared/components/ui/UISwitch';

@Component({
  components: {
    UISwitch,
    UIInput,
  },
  props: {
    tag: {
      type: Object,
      required: true,
    },
    games: {
      type: Array,
      required: true,
    },
  },
})
export default class TagDetails extends Vue {
  get localTag () {
    return new Proxy(this.tag, {
      set: (obj, key, val) => {
        this.$emit('edit', key, val);
        return true;
      },
    });
  }
}
</script>

<style lang="postcss" scoped>
@import 'core/shared/styles';

.tag-details {
  display: grid;
  grid-gap: var(--spacingMd);

  & .inputs {
    display: grid;
    grid-gap: var(--spacingSm);
    grid-template-columns: 1fr 1fr;

    & .operator-switch {
      padding-top: 2em;
    }
  }

  & .stats {
    display: grid;
    grid-gap: var(--spacingSm);
    grid-template-columns: 1fr 1fr;
  }
}
</style>
