import Vue from 'vue';
import Component from 'vue-class-component';
import AgreementModalConnected from '@/content/shared/AgreementModalConnected';

@Component
export default class OwnershipCard extends Vue {
  async openEULAModal (ownership) {
    const node = document.createElement('div');
    const mountingNode = node.appendChild(document.createElement('div'));
    document.body.appendChild(node);
    const vm = new Vue({
      parent: this.$root,
      render (h) {
        return h(AgreementModalConnected, {
          props: {
            ownership,
          },
          ref: 'agreementmodal',
        });
      },
    }).$mount(mountingNode);
    await vm.$refs.agreementmodal.requireAgreementsForGame();
    node.parentNode.removeChild(node);
    vm.$destroy();
  }
}
