<template>
  <div class="tag-list-item f6">
    <template v-if="tag && tag.games">
      <div class="draghandle">
        <UIIcon class="fill-inherit" name="menu"/>
      </div>
      <div class="title">
        <h4 class="title f4 nowrap dib truncate slate">{{ tag.text }}</h4>
        <div class="numberoftitles mobile">{{ tag.games.length }} {{ $tc('title | titles', tag.games.length) }}</div>
      </div>
      <div class="operatoronly tablet "><div v-if="tag.operatorOnly"> Operator only </div></div>
      <div class="operatoronly mobile "><div v-if="tag.operatorOnly"> Operator only </div></div>
      <div class="numberoftitles tablet">
        {{ tag.games.length }} {{ $tc('title | titles', tag.games.length) }}
      </div>
      <div class="edit">
        <UIButton
          tiny
          icon="edit"
          :to="{ name: 'tag-show', params: { tagId: tag.id } }"
          class="editbtn"
        >{{ $t('Edit') }}</UIButton>
      </div>
    </template>
  </div>
</template>

<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import UIButton from '@/core/shared/components/ui/UIButton';
import UIIcon from '@/core/shared/components/ui/UIIcon';

@Component({
  components: {
    UIButton,
    UIIcon,
  },
  props: {
    tag: {
      type: Object,
      required: true,
    },
  },
})
export default class TagListItem extends Vue {}
</script>

<style scoped lang="postcss">
@import "core/shared/styles";
.tag-list-item {
  background-color: var(--colorWhite);
  box-shadow: var(--shadowSm);
  border-radius: var(--radius);

  display: grid;
  grid-template-columns: 2.5rem auto 6rem 6rem;
  align-items: center;
  grid-gap: var(--spacingSm);
  padding: var(--spacingSm);

  @media (--tablet) {
    padding: var(--spacingAltSm) var(--spacingAltMd);
    grid-gap: var(--spacingMd);
    grid-template-columns: auto 1fr auto auto auto;

    & .edit {
      padding-left: var(--spacingMd);
    }
  }

  & .operatoronly{
    color: red;
    text-align: center;
  }

  & .draghandle {
    cursor: grab;
    position: relative;
    text-align: center;
    fill: var(--colorFjord);

    & svg {
      width: 2.5rem;
    }
  }
}
</style>
