<template>
  <div class="marketplace-content-show-route">
    <template v-if="contentOwnership">
      <AgreementModalWrapper
        v-if="contentOwnership.currentAgreement"
        ref="agreementmodal"
        class="agreementmodal"
        :title="contentOwnership.title"
        :agreement="contentOwnership.currentAgreement"
      />
      <div class="mobileheadernotices mobile">
        <MarketplaceNoticesBar
          v-if="game"
          :contentOwnership="contentOwnership"
          :game="game"
          :canLicenseContent="canLicenseContent"
        />
      </div>
      <HeaderGameShow
        usage="marketplace"
        class="heroheader"
        :content="contentOwnership"
      ></HeaderGameShow>
      <div class="infocontainer container f-body">
        <ContentBlock>
          <template #instructions>
            <h5>{{ $t('Content Description') }}</h5>
            <p>{{ $t('A general overview of the title. This information will be visible in the Launcher to your customers and will help categorize your content.') }}</p>
          </template>
          <h6 class="f-thead">{{ $t('Description') }}</h6>
          <p class="contentdesc mb5" v-html="contentOwnership.description"></p>
          <dl class="contentdl mb5">
            <div class="difficulty">
              <dd class="f-thead mb2">{{ $t('Difficulty') }}</dd>
              <dt class="f-header">
                <UIIcon
                  class="difficultyicon mr2 dib fill-geyser"
                  :name="`difficulty-${contentOwnership.difficultyDisplay.toLowerCase()}`"
                  style="width: .75em"
                ></UIIcon>
                <span class="ttc dib">{{ contentOwnership.difficultyDisplay }}</span>
              </dt>
            </div>
            <div
              class="genres"
              v-if="contentOwnershipGenres && contentOwnershipGenres.length"
            >
              <dd class="f-thead mb2">{{ $t('Genres') }}</dd>
              <dt class="f-header">{{ contentOwnershipGenres }}</dt>
            </div>
            <div
              class="playermode"
              v-if="contentOwnership.singleplayer || contentOwnership.multiplayer"
            >
              <dd class="f-thead mb2">{{ $t('Number of Players') }}</dd>
              <dt class="f-header">
                <p v-if="contentOwnership.singleplayer">{{ $t('Single player') }}</p>
                <p v-if="contentOwnership.multiplayer">
                  {{ $t('Multiplayer') }}&nbsp;
                  <template
                    v-if="contentOwnership.minPlayers && contentOwnership.maxPlayers"
                  >
                    <i18n path="({min} to {max})">
                      <template #min>{{ contentOwnership.minPlayers }}</template>
                      <template #max>{{ contentOwnership.maxPlayers }}</template>
                    </i18n>
                  </template>
                </p>
              </dt>
            </div>
          </dl>
          <RatioContainer
            :ratio="9 * 100 / 16"
            v-if="contentOwnership.videoMp4Url || contentOwnership.videoWebmUrl"
          >
            <video
              disablepictureinpicture
              controlslist="nodownload"
              controls
              autoplay
              muted
            >
              <source
                :src="contentOwnership.videoWebmUrl"
                type="video/webm"
              />
              <source
                :src="contentOwnership.videoMp4Url"
                type="video/mp4"
              />
            </video>
          </RatioContainer>
        </ContentBlock>
        <GameReviewDetails :contentOwnership="contentOwnership" />
        <GameInfoDetails :contentOwnership="contentOwnership" />
        <GameHeadsetCompatibility :game="contentOwnership" />
      </div>
      <BottomBar
        :backRouterLink="{ name: 'marketplace' }"
        :backText="$mq.current === 'phone' ? $t('Back') : $t('Back to the Marketplace')"
        :saving="isActivatingLicense"
        :actionText="bottomBarActionText"
        :actionRouterLink="bottomBarRouterLink"
        :valid="canLicenseContent || !subscriptionExclusion"
        @save="!game && (canLicenseContent ? addGameToLibrary() : () => {})"
      >
        <div class="ownershipbottombarbanner">
          <MarketplaceNoticesBar
            v-if="game"
            :contentOwnership="contentOwnership"
            :game="game"
            :canLicenseContent="canLicenseContent"
          />
        </div>
      </BottomBar>
    </template>
  </div>
</template>

<script>
import Component, { mixins } from 'vue-class-component';
import GameReviewDetails from '@/content/shared/GameReviewDetails';
import GameInfoDetails from '@/content/shared/GameInfoDetails';
import GameHeadsetCompatibility from '@/content/shared/GameHeadsetCompatibility.vue';
import AgreementModal from '@/content/shared/AgreementModal';
import BreadcrumbNavConnected from '@/core/shared/components/_connected/BreadcrumbNavConnected';
import BottomBar from '@/core/shared/components/BottomBar';
import HeaderGameShow from '@/content/shared/HeaderGameShow';
import ContentBlock from '@/core/shared/components/ContentBlock';
import FocusModeMixin from '@/core/shared/misc/FocusModeMixin';
import RatioContainer from '@/core/shared/components/utils/RatioContainer';
import UIIcon from '@/core/shared/components/ui/UIIcon';
import UIButton from '@/core/shared/components/ui/UIButton';
import { createModalWrapper } from '@/core/shared/helpers/HOCHelper';
const AgreementModalWrapper = createModalWrapper(AgreementModal, { requireAgreement: false });
import MarketplaceNoticesBar from './components/MarketplaceNoticesBar';

@Component({
  components: {
    GameReviewDetails,
    GameInfoDetails,
    GameHeadsetCompatibility,
    AgreementModalWrapper,
    ContentBlock,
    BreadcrumbNavConnected,
    HeaderGameShow,
    BottomBar,
    UIIcon,
    UIButton,
    RatioContainer,
    MarketplaceNoticesBar,
  },
  props: {
    contentOwnershipId: {
      required: true,
    },
  },
})
export default class MarketplaceContentRoute extends mixins(FocusModeMixin) {
  get bottomBarActionText () {
    if (this.game) return this.$t('View in My Library');
    else if (!this.canLicenseContent) return this.$t('Upgrade Now');
    else return this.$t('Activate License');
  }

  get bottomBarRouterLink () {
    if (this.game) return { name: 'content-library-show', params: { gameId: this.game.id } };
    else if (!this.canLicenseContent && !this.subscriptionExclusion) return { name: 'billing-account', query: { subscribe: 1 } };
  }

  get subscriptionExclusion () {
    return this.$store.getters.currentOrganization.subscriptionExclusion;
  }

  get hasMarketplaceAccess () {
    return this.$store.getters.currentOrganization.hasMarketplaceAccess;
  }

  get canLicenseContent () {
    return this.hasMarketplaceAccess || this.isOnTrial;
  }

  get isOnTrial () {
    return this.$store.getters.currentOrganization.isOnTrial;
  }

  isActivatingLicense = false
  get contentOwnership () {
    return this.$store.getters['entities/ContentOwnership'](this.contentOwnershipId);
  }

  get game () {
    return this.contentOwnership && this.contentOwnership.operatorGame;
  }

  get contentOwnershipGenres () {
    return this.contentOwnership && this.contentOwnership.genres && this.contentOwnership.genres.slice(0, 3).map(({ title }) => title).join(', ');
  }

  async created () {
    if (!this.contentOwnership) {
      await this.$store.dispatch('fetchContentOwnership', this.contentOwnershipId);
    }
    this.$store.commit('setTitle', this.contentOwnership.title);
    this.setBreadcrumb();
  }

  setBreadcrumb () {
    this.$store.commit('setBreadcrumbItems', [
      {
        name: this.$t('Marketplace'),
        routerLink: { name: 'marketplace' },
      },
      {
        name: this.contentOwnership.title,
      },
    ]);
  }

  addGameToLibrary () {
    if (this.contentOwnership.currentAgreement) {
      this.showAgreementModal();
    } else {
      this.addContentOwnershipToLibrary();
    }
  }

  showAgreementModal () {
    const showModal = (agreement, { accept, decline }) => this.$refs.agreementmodal.show({
      agreement,
      requireAgreement: true,
    }, {
      accept: () => { accept(); },
      decline: () => { decline(); },
    });
    const accept = this.addContentOwnershipToLibrary;
    const decline = this.showDeclineNotification;
    // if upcoming EULA the user need to accept it right away as well
    showModal(this.contentOwnership.currentAgreement, {
      accept: () => {
        if (this.contentOwnership.upcomingAgreement) {
          this.$nextTick(() => {
            showModal(this.contentOwnership.upcomingAgreement, {
              accept,
              decline,
            });
          });
        } else {
          accept();
        }
      },
      decline,
    });
  }

  showDeclineNotification () {
    const message = this.$t('You have to agree to the End User License Agreement in order to add this content to your Library.');
    this.$store.commit('setFlash', {
      type: 'info',
      message,
    });
  }

  async addContentOwnershipToLibrary () {
    const { title } = this.contentOwnership;
    this.isActivatingLicense = true;
    try {
      const { id } = await this.$store.dispatch('addContentOwnershipToLibrary', this.contentOwnership);
      const { href } = this.$router.resolve({ name: 'content-library-show', params: { gameId: id } });
      const sucessMsg = this.$t('{title} was successfully added to your Library. Don’t forget to {enable}.', {
        title,
        enable: `<a class="link-style" href="${href}">enable it on your stations</a>`,
      });
      this.$router.push({ name: 'content-library' });
      this.$store.commit('setFlash', {
        type: 'success',
        message: sucessMsg,
      });
    } catch (e) {
      const failureMsg = this.$t('{title} was not added to your Library, please try again.', { title });
      this.$store.commit('setFlash', {
        type: 'error',
        message: failureMsg,
      });
      throw e;
    } finally {
      this.isActivatingLicense = false;
    }
  }
}
</script>

<style scoped lang="postcss">
@import "core/shared/styles";

.marketplace-content-show-route {
  & .container {
    @apply --contentContainer;
  }
  & .ownershipbottombarbanner {
    position: absolute;
    top: 0;
    left: 0;
    transform: translateY(-100%);
    width: 100%;
  }
  & .contentdesc {
    white-space: pre-wrap;
  }
  & .techcaption {
    display: initial;
    @media (--tablet) {
      display: none;
    }
  }
  & .infocontainer {
    padding-top: var(--spacingLg);
  }
  & .subheader {
    margin-bottom: var(--spacingXS);
  }
  & .headerhero {
    position: relative;
    overflow: hidden;
    &:before {
      background-color: rgba(0, 0, 0, 0.6);
      background-image: var(--localBackgroundImg);
      filter: blur(16px);
      background-blend-mode: multiply;
      background-position: center;
      background-size: cover;
      content: "";
      position: absolute;
      top: -25px;
      left: -25px;
      right: -25px;
      bottom: -25px;
      z-index: -1;
    }
    padding-top: var(--spacingLg);
    padding-bottom: var(--spacingLg);
    padding-right: var(--spacingXL);
  }
  & .headercontentcard {
    cursor: default;
    pointer-events: none;
  }
  & .headerinfo {
    display: flex;
    padding-left: var(--spacingXL);
    justify-content: center;
    height: 100%;
    flex-direction: column;
  }
  & .headerdl {
    column-count: 3;

    /* display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-auto-flow: row; */
  }
  & .contentdl {
    display: grid;
    grid-gap: var(--spacingMd);
    grid-template-columns: repeat(3, 1fr);
  }
  & .icon-thumbs-up {
    transform: scale(-1, 1);
    vertical-align: sub;
  }
  & .icon-thumbs-up,
  & .icon-thumbs-down {
    margin-right: 1rem;
  }
  & .otherdl {
    display: grid;
    grid-gap: var(--spacingMd);
    grid-template-columns: repeat(3, 1fr);
  }
}
</style>
