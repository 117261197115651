<template lang="pug">
div.lazy-img.relative(v-observe-visibility="{ callback: visibilityChanged, once: true, intersection: { rootMargin: '0px 0px 50px 0px' } }")
  img.loadingimg(v-if="isVisible && sizes" :src="src" :sizes="sizes" v-bind="$attrs" @load="onLoad")
  transition(:enter-active-class="shouldTransition ? 'animated-300 fadeIn' : ''" :leave-active-class="shouldTransition ? 'animated-300 fadeOut' : ''")
    img.img.absolute.w-100.h-100.top-0.left-0.z-1(v-if="hasWaitedInitialTimeout && isLoaded" :src="src" :sizes="sizes" v-bind="$attrs" key="img")
    img.placeholder.absolute.w-100.h-100.top-0.left-0(v-else-if="placeholder" :src="placeholder" key="placeholder")
    span.placeholder.absolute.w-100.h-100.top-0.left-0(v-else) &nbsp;
</template>

<script>
import { ObserveVisibility } from 'vue-observe-visibility';
export default {
  name: 'LazyImg',
  directives: {
    'observe-visibility': ObserveVisibility,
  },
  inheritAttrs: false,
  props: {
    src: String,
    placeholder: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    isVisible: false,
    isLoaded: false,
    sizes: null,
    shouldTransition: true,
    hasWaitedInitialTimeout: false,
  }),
  created () {
    this.$nextTick(() => {
      this.computeSize();
    });
  },
  methods: {
    checkIfShouldTransition () {
      this.$nextTick(() => {
        setTimeout(() => {
          if (this.isVisible && this.isLoaded) this.shouldTransition = false;
          this.hasWaitedInitialTimeout = true;
        }, 100);
      });
    },
    onLoad () {
      this.isLoaded = true;
    },
    computeSize () {
      if (this.$el) {
        this.sizes = Math.ceil(this.$el.getBoundingClientRect().width / window.innerWidth * 100) + 'vw';
      }
    },
    visibilityChanged (isVisible) {
      this.checkIfShouldTransition();
      this.isVisible = isVisible;
    },
  },
};
</script>

<style scoped lang="postcss">
@import "../../styles";
.loadingimg {
  display: none;
}
.img,
.placeholder {
  object-fit: cover;
  object-position: center;
}
</style>
