import { gql } from '@/core/shared/api';

export const batchRequestGameKey = async ({ commit, dispatch, rootGetters }, stationGames) => {
  if (!(stationGames && stationGames.length)) return;
  const subQueries = stationGames.map((stationGame, i) => {
    const { station, game } = stationGame;
    const { ownership } = rootGetters['entities/Game'](game.id);
    const variables = {
      station: { id: station.id },
      contentOwnership: { id: ownership.id },
    };
    return `
      requestGameKey${i}: requestGameKey(
        station: ${JSON.stringify(variables.station).replace(
    /"([^(")"]+)":/g,
    '$1:',
  )}
        contentOwnership: ${JSON.stringify(
    variables.contentOwnership,
  ).replace(/"([^(")"]+)":/g, '$1:')}
      ) {
        id
        key
        station { id }
        game { id }
      }
    `;
  });
  const query = `
    mutation {
      ${subQueries.join('\n')}
    }
  `;

  await gql.request(query);
  const { game: updatedGame } = await gql.request(
    `query { game(id:"${stationGames[0].game.id}") {
    id
    stationGames {
      id
      key {
        id
        key
      }
      station { id }
      game { id }
    }
  } }`,
  );
  dispatch(
    'insertEntities',
    {
      name: 'Game',
      payload: updatedGame,
    },
    { root: true },
  );
};

export const requestGameKeysForLocation = async ({ commit, dispatch, getters }, location) => {
  const { getAllStationGamesForLocation } = getters;
  const stationGames = getAllStationGamesForLocation(location);
  await dispatch('batchRequestGameKey', stationGames.filter(({ key }) => !key));
};

export const requestGameKeysForExperience = async (
  { commit, dispatch, getters },
  experience,
) => {
  const { getAllStationGamesForExperience } = getters;
  const stationGames = getAllStationGamesForExperience(experience);
  await dispatch('batchRequestGameKey', stationGames.filter(({ key }) => !key));
};

export const installAllStationGamesTransitioningtoCDS = ({ getters, dispatch }) => {
  const { stationGames } = getters;
  const stationGamesToInstall = stationGames.filter(
    ({ enabled, targetStatus, status }) =>
      enabled && !targetStatus && !status,
  );
  stationGamesToInstall.forEach(stationGame => {
    dispatch('installStationGame', stationGame);
  });
};

const doActionForAllStationGamesInExperience = action => (
  { commit, getters, dispatch },
  experience,
) => {
  const { getAllStationGamesForExperience } = getters;
  getAllStationGamesForExperience(experience).forEach(stationGame =>
    dispatch(action, stationGame),
  );
};

const doActionForAllStationGamesInLocation = action => (
  { commit, getters, dispatch },
  location,
) => {
  const { getAllStationGamesForLocation } = getters;
  getAllStationGamesForLocation(location).forEach(stationGame =>
    dispatch(action, stationGame),
  );
};

const doActionForAllStationGamesInAccount = action => (
  { commit, getters, dispatch },
) => {
  const { stationGames } = getters;
  stationGames.forEach(stationGame =>
    dispatch(action, stationGame),
  );
};

export const installAllStationGamesForExperience = doActionForAllStationGamesInExperience(
  'installStationGame',
);

export const uninstallAllStationGamesForExperience = doActionForAllStationGamesInExperience(
  'uninstallStationGame',
);

export const disableAllStationGamesForExperience = doActionForAllStationGamesInExperience(
  'disableStationGame',
);

export const enableAllStationGamesForExperience = doActionForAllStationGamesInExperience(
  'enableStationGame',
);

export const installAllStationGamesForLocation = doActionForAllStationGamesInLocation(
  'installStationGame',
);

export const uninstallAllStationGamesForLocation = doActionForAllStationGamesInLocation(
  'uninstallStationGame',
);

export const disableAllStationGamesForLocation = doActionForAllStationGamesInLocation(
  'disableStationGame',
);

export const enableAllStationGamesForLocation = doActionForAllStationGamesInLocation(
  'enableStationGame',
);

export const installAllStationGamesForAccount = doActionForAllStationGamesInAccount(
  'installStationGame',
);

export const uninstallAllStationGamesForAccount = doActionForAllStationGamesInAccount(
  'uninstallStationGame',
);

export const disableAllStationGamesForAccount = doActionForAllStationGamesInAccount(
  'disableStationGame',
);

export const enableAllStationGamesForAccount = doActionForAllStationGamesInAccount(
  'enableStationGame',
);
