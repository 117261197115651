<template>
  <div v-if="!hasNoInfo">
    <AgreementModalConnected
      v-if="contentOwnership.currentAgreement"
      ref="agreementmodal"
      class="agreementmodal"
      :ownership="contentOwnership"
    />
    <ContentBlock class="game-review-details f-body">
      <template #instructions>
        <h5>{{ $t('Additional Information') }}</h5>
        <p>{{ $t('Additional information for this title.') }}</p>
      </template>
      <div
        class="reviewsection"
        v-if="contentOwnership.reviewPros || contentOwnership.reviewCons"
      >
        <h4 class="f-header mb4">{{ $t('SpringboardVR’s Review') }}</h4>
        <div
          class="prossection"
          v-if="contentOwnership.reviewPros"
        >
          <h6 class="f-label mb2 fill-green green">
            <UIIcon
              name="thumbs-up"
              class="icon-thumbs-up v-mid dib mr1"
              style="font-size: 1.5em; vertical-align:-0.25rem; transform:scale(-1, 1);margin-right: .7rem"
            />
            <span class="dib">{{ $t('Pros') }}</span>
          </h6>
          <p class="reviewpros mb5">{{ contentOwnership.reviewPros }}</p>
        </div>
        <div
          class="conssection"
          v-if="contentOwnership.reviewCons"
        >
          <h6 class="f-label mb2 fill-red red">
            <UIIcon
              name="thumbs-down"
              class="icon-thumbs-down v-mid dib mr1"
              style="font-size: 1.5em; vertical-align:-.65rem;margin-right: .7rem"
            />
            <span class="dib">{{ $t('Cons') }}</span>
          </h6>
          <p class="reviewcons">{{ contentOwnership.reviewCons }}</p>
        </div>
        <hr class="hrseparator mv5" />
      </div>
      <h6 class="f-header mb4">{{ $t('Credits & Legal') }}</h6>
      <dl class="otherdl">
        <div
          class="developer"
          v-if="contentOwnership.developers"
        >
          <dd class="f-thead mb2">{{ $t('Developer') }}</dd>
          <dt class="f-body">{{ contentOwnership.developers }}</dt>
        </div>
        <div
          class="publisher"
          v-if="contentOwnership.publishers"
        >
          <dd class="f-thead mb2">{{ $t('Publisher') }}</dd>
          <dt class="f-body body-break">{{ contentOwnership.publishers }}</dt>
        </div>
        <div
          class="supportemail"
          v-if="contentOwnership.developerSupportEmail && contentOwnership.operatorLicense"
        >
          <dd class="f-thead mb2">{{ $t('Support Email') }}</dd>
          <dt class="f-body body-break">{{ contentOwnership.developerSupportEmail }}</dt>
        </div>
        <div
          class="licenseagreement"
          v-if="contentOwnership.currentAgreement"
        >
          <dd class="f-thead mb2">{{ $t('End User License Agreement') }}</dd>
          <dt class="f-body">
            <EULAStatus
              class="currentagreement"
              :agreement="contentOwnership.currentAgreement"
              :status="getAgreementStatus(contentOwnership.currentAgreement)"
              @preview="showAgreementModal"
            />
            <div
              class="upcomingagreement mt3"
              v-if="contentOwnership.upcomingAgreement"
            >
              <EULAStatus
                :agreement="contentOwnership.upcomingAgreement"
                :status="getAgreementStatus(contentOwnership.upcomingAgreement)"
                @preview="showAgreementModal"
              >{{ $t('View New EULA') }}</EULAStatus>
            </div>
          </dt>
        </div>
        <div
          class="releasedate"
          v-if="contentOwnership.releaseDate"
        >
          <dd class="f-thead mb2">{{ $t('Release Date') }}</dd>
          <dt class="f-body">{{ formattedReleaseDate }}</dt>
        </div>
        <div
          class="latestversiondate"
          v-if="contentOwnership.latestVersionDate"
        >
          <dd class="f-thead mb2">{{ $t('Latest Version') }}</dd>
          <dt class="f-body">{{ formattedLatestVersionDate }}</dt>
        </div>
        <div
          class="marketingassets"
          v-if="contentOwnership.marketingAssetsUrl && contentOwnership.operatorGame"
        >
          <dd class="f-thead mb2">{{ $t('Marketing Assets') }}</dd>
          <dt class="f-body">
            <UIButton
              link
              :href="contentOwnership.marketingAssetsUrl"
              rel="noopener"
              target="_blank"
            >{{ $t('Download') }}</UIButton>
          </dt>
        </div>
      </dl>
    </ContentBlock>
  </div>
</template>

<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import BreadcrumbNavConnected from '@/core/shared/components/_connected/BreadcrumbNavConnected';
import BottomBar from '@/core/shared/components/BottomBar';
import ContentBlock from '@/core/shared/components/ContentBlock';
import UIIcon from '@/core/shared/components/ui/UIIcon';
import UIButton from '@/core/shared/components/ui/UIButton';
import EULAStatus from './EULAStatus';
import moment from 'moment';
import AgreementModalConnected from '@/content/shared/AgreementModalConnected';

@Component({
  components: {
    ContentBlock,
    BreadcrumbNavConnected,
    AgreementModalConnected,
    BottomBar,
    UIIcon,
    UIButton,
    EULAStatus,
  },
  props: {
    contentOwnership: {
      required: true,
      validator (val) {
        return val.__typename === 'ContentOwnership';
      },
    },
  },
})
export default class GameReviewDetails extends Vue {
  created () {
    if (this.$route.query.eula) {
      this.$nextTick(() => {
        if (this.contentOwnership.currentAgreement) this.requireAgreementsForGame();
      });
    }
  }

  get hasNoInfo () {
    if (!this.contentOwnership) return true;
    const {
      releaseDate,
      latestVersionDate,
      reviewCons,
      reviewPros,
      currentAgreement,
      developers,
      publishers,
    } = this.contentOwnership;
    return [
      releaseDate,
      latestVersionDate,
      reviewCons,
      reviewPros,
      currentAgreement,
      developers,
      publishers,
    ].every(v => !v);
  }

  get formattedReleaseDate () {
    return this.contentOwnership && this.contentOwnership.releaseDate && moment(this.contentOwnership.releaseDate).format('LL');
  }

  get formattedLatestVersionDate () {
    return this.contentOwnership && this.contentOwnership.latestVersionDate && moment(this.contentOwnership.latestVersionDate).format('LL');
  }

  getAgreementStatus (agreement) {
    if (!this.contentOwnership.operatorGame || !this.contentOwnership.operatorLicense || !agreement) return;
    const { id } = agreement;
    const isCurrent = id === this.contentOwnership.currentAgreement.id;
    const isWaitingForCurrent = !isCurrent && this.contentOwnership.operatorLicense.promptForCurrentLicense;
    if (isWaitingForCurrent) return 'disabled';
    else if (this.contentOwnership.operatorLicense[`promptFor${isCurrent ? 'Current' : 'Upcoming'}License`]) return 'pending';
    else return 'accepted';
  }

  showAgreementModal (agreement) {
    this.$refs.agreementmodal.showAgreement(agreement);
  }

  requireAgreementsForGame () {
    this.$refs.agreementmodal.requireAgreementsForGame();
  }
}
</script>

<style scoped lang="postcss">
@import "core/shared/styles";

.game-review-details {
  & .otherdl {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: var(--spacingMd);
    @media (--tablet) {
      grid-template-columns: repeat(3, 1fr);
    }
  }
}
.body-break {
  word-break: break-all;
}
</style>
