<template>
  <ContentBlock class="game-headsets-details f-body">
    <template #instructions>
      <h5>{{ $t('Headset Compatibility') }}</h5>
      <p>{{ $t('Shows compatibility of the game with the different major VR headset') }}</p>
    </template>
    <div class="headsets">
      <div class="headset f-header mb3">
          <div class="headset-tick">
            <svg>
              <use v-bind:xlink:href="getTick(game.headsetHtcVive)"></use>
            </svg>
          </div>
          <h6 class="f-header headset-name">{{ $t('Htc Vive') }}</h6>
      </div>
      <div class="headset f-header mb3">
          <div class="headset-tick">
            <svg>
              <use v-bind:xlink:href="getTick(game.headsetValveIndex)"></use>
            </svg>
          </div>
          <h6 class="f-header headset-name">{{ $t('Valve Index') }}</h6>
      </div>
      <div class="headset f-header mb3">
          <div class="headset-tick">
            <svg>
              <use v-bind:xlink:href="getTick(game.headsetWindowsMr)"></use>
            </svg>
          </div>
          <h6 class="f-header headset-name">{{ $t('Windows MR') }}</h6>
      </div>
      <div class="headset f-header mb3">
          <div class="headset-tick">
            <svg>
              <use v-bind:xlink:href="getTick(game.headsetOculusRift)"></use>
            </svg>
          </div>
          <h6 class="f-header headset-name">{{ $t('Oculus Rift') }}</h6>
      </div>
    </div>
  </ContentBlock>
</template>

<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import ContentBlock from '@/core/shared/components/ContentBlock';

@Component({
  components: {
    ContentBlock,
  },
  props: {
    game: {
      type: Object,
      required: true,
    },
  },
})
export default class GameHeadsetCompatibility extends Vue {
  created () {
    if (this.$route.query.eula) {
      this.$nextTick(() => {
        if (this.contentOwnership.currentAgreement) this.requireAgreementsForGame();
      });
    }
  }

  getTick (status) {
    if (status === true) return '#icon-check';
    else if (status === false) return '#icon-x';
    else return '#icon-unknown';
  }
}
</script>

<style scoped lang="postcss">
@import "core/shared/styles";

.game-headsets-details {

  /* Vertical display */
  & .headsets {
    display: block;
    & .headset {
      display: flex;
      & .headset-name{
        display: inline-flex;
        justify-content: center;
        align-items: center;
      }
      & .headset-tick{
        width: 20px;
        height: 20px;
        /* margin-left: 10%; */
        margin-right: 5%;

        display: inline-flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}
.body-break {
  word-break: break-all;
}
</style>
