<template>
  <div class="content-show-management-route">
    <DeleteContentConfirm ref="DeleteContentConfirm"></DeleteContentConfirm>
    <HeaderGameShow usage="library" class="heroheader" :content="storedGame">
      <template #deletebtn>
        <UIButton
          class="deletebtn"
          tiny
          icon="trash"
          @click="confirmDeleteContent"
        >{{ $t('Delete Content') }}</UIButton>
      </template>
    </HeaderGameShow>
    <div class="container f-body">
      <template v-if="!loading">
        <div class="transitiontocds pa5 ba b--geyser mb5" v-if="isTransitioningToCDS">
          <h3 class="f-tinycta fjord mb4">{{$t('Transition to "One-Click" Titles')}}</h3>
          <p>{{$t('This title is now being distributed through our Content Distribution System instead of Steam. You’ll need to update your current installation:')}}</p>
          <ul>
            <li>- {{$t('For stations the title is already enabled on, uninstall your old copy of the content and click the update button. The "One-Click" version of your title will get installed and enabled automatically.')}}</li>
            <li>- {{$t('For stations the content is not enabled on yet, no extra steps will be required when you decide to enable the content as it will automatically install the proper version.')}}</li>
          </ul>
          <UIButton class="mt4" @click="updateAll">{{$t('Update All')}}</UIButton>
        </div>
        <LicenseManagement :ownership="contentOwnership" v-if="game.license && contentOwnership" />
        <ContentBlock
          class="buyonsteamblock"
          v-if="!game.manuallyAdded && !game.cds && game.license && contentOwnership && !game.ownership.freeDownload"
        >
          <template #instructions>
            <h5>{{ $t('Buy on Steam') }}</h5>
            <p>{{ $t('This content is not available for download through the SpringboardVR Marketplace and will need to be purchased directly through Steam.') }}</p>
          </template>
          <div class="f-body flex-m justify-between">
            <p
              class="mr4 mb3 mb0-m"
            >{{ $t('Buy this content on Steam before installing it and enabling it on your stations.') }}</p>
            <UIButton
              class="buyonsteambtn w-100 w-auto-m"
              secondary
              :href="'https://store.steampowered.com/app/' + contentOwnership.steamId"
            >Buy on Steam</UIButton>
          </div>
        </ContentBlock>
        <StationGamesManagement
          :game="game"
          :gameType="gameType"
          class="deployementmanagementblock"
        />
      </template>
      <ContentBlock v-else :loading="true"></ContentBlock>
    </div>
  </div>
</template>

<script>
import Component from 'vue-class-component';
import Vue from 'vue';
import ContentBlock from '@/core/shared/components/ContentBlock';
import HeaderGameShow from '@/content/shared/HeaderGameShow';
import UISwitch from '@/core/shared/components/ui/UISwitch';
import UIButton from '@/core/shared/components/ui/UIButton';
import UIIcon from '@/core/shared/components/ui/UIIcon';
import UIExpandable from '@/core/shared/components/ui/UIExpandable';
import UpcomingInvoice from '../components/UpcomingInvoice';
import LicenseManagement from '../components/LicenseManagement';
import DeleteContentConfirm from '@/content/shared/DeleteContentConfirm';
import { createModalWrapper } from '@/core/shared/helpers/HOCHelper';

@Component({
  components: {
    ContentBlock,
    UISwitch,
    UIExpandable,
    UIButton,
    UIIcon,
    UpcomingInvoice,
    StationGamesManagement: () => {
      if (Vue.prototype.$permissions['content-only']) {
        return import('../components/StationGamesManagementContentOnly');
      } else {
        return import('../components/StationGamesManagementOperator');
      }
    },
    LicenseManagement,
    HeaderGameShow,
    DeleteContentConfirm: createModalWrapper(DeleteContentConfirm),
  },
  props: {
    game: { required: true },
    storedGame: { required: true },
    contentOwnership: { },
    loading: { },
  },
})
export default class ContentShowManagementRoute extends Vue {
  isInvoiceExpanded = false
  get upcomingInvoiceProps () {
    return {
      upcomingInvoiceAmount: this.game.license.upcomingInvoiceAmount,
      currentTotalUsage: this.game.license.currentTotalUsage,
      displayPriceMinute: this.contentOwnership.currentPrice && this.contentOwnership.currentPrice.displayPriceMinute,
      stationsInstalled: this.game.stationsInstalled,
    };
  }

  get gameType () {
    if (!this.game) return;
    let type;
    if (this.contentOwnership && this.contentOwnership.cds) type = 'cds';
    else if (this.contentOwnership && !this.contentOwnership.cds) type = 'steam';
    else if (!this.contentOwnership) type = 'manual';
    return type;
  }

  created () {
    this.$store.dispatch('getAllLocations');
  }

  get isTransitioningToCDS () {
    const stationGames = this.$store.getters[`stationManagement:${this.game.id}/stationGamesTransitioningToCDS`];
    return this.game.cds && stationGames && stationGames.length > 0;
  }

  updateAll () {
    const stationGames = this.$store.getters[`stationManagement:${this.game.id}/stationGamesTransitioningToCDS`];
    this.$store.commit('setConfirm', {
      show: true,
      message: this.$t('{count} of your stations will get updated: after you save, we will install the new version of the title on these stations. Please remember to uninstall the old version.', { count: stationGames.length }),
      icon: 'check-circle',
      buttons: [
        {
          name: this.$t('Cancel'),
          type: 'cancel',
        },
        {
          name: this.$t('Update All'),
          action: () => {
            this.$store.dispatch(`stationManagement:${this.game.id}/installAllStationGamesTransitioningtoCDS`);
          },
        },
      ],
    });
  }

  async deleteContent () {
    this.$store.commit('setLoading', true);
    try {
      await this.$store.dispatch('removeGameFromLibrary', this.game);
      this.$router.push({ name: 'content-library' });
    } finally {
      this.$store.commit('setLoading', false);
    }
  }

  async confirmDeleteContent () {
    const isCDSGame = this.game.cds;
    const isLicensedGame = 'license' in this.game;
    let licenses = this.$store.getters['entities/BundleLicense/all']();
    if (!licenses || licenses.length === 0) {
      licenses = await this.$store.dispatch('fetchBundleLicenses');
    }
    const isBundledGame = licenses.some(x => x.bundle.contents.some(c => c.contentOwnership.id === this.game.ownership.id));
    this.$refs.DeleteContentConfirm.show({
      isCDSGame,
      isLicensedGame,
      isBundledGame,
    }, {
      confirm: () => {
        this.deleteContent();
      },
    });
  }
}
</script>

<style scoped lang="postcss">
@import "core/shared/styles";

.content-show-management-route {
  & .container {
    @apply --container;
    padding-top: var(--spacingLg);
  }
  & .switchgrid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: var(--spacingSm);
  }
  & .buyonsteambtn {
    min-width: 33%;
  }
  & .rotate-180 {
    transform: rotate(180deg);
  }
}
</style>
