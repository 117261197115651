<template>
  <UIMultiselect
    class="genres-multiselect"
    :title="$t('Filter By Genres')"
    :placeholder="$t('Select genres')"
    :value="value"
    label="title"
    track-by="id"
    open-direction="bottom"
    :options="genres || []"
    :multiple="true"
    :searchable="true"
    :internalSearch="true"
    :loading="loading"
    :internal-search="false"
    :close-on-select="true"
    :options-limit="300"
    :max-height="600"
    :show-no-results="true"
    :show-no-options="false"
    :hide-selected="true"
    @input="val => $emit('input', val)"
  ></UIMultiselect>
</template>

<script>
import { useGenres } from '../compositions/useGenres';
import UIMultiselect from '@/core/shared/components/ui/UIMultiselect';

export default {
  props: {
    value: {},
  },
  components: {
    UIMultiselect,
  },
  setup () {
    const { loading, genres } = useGenres();
    return {
      loading,
      genres,
    };
  },
};
</script>

<style lang="postcss" scoped>
@import "core/shared/styles";
</style>
