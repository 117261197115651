<template>
  <div>
    <div class="upcoming-invoice w-100">
      <div
        v-for="(key, i) in Object.keys(headers)"
        :key="key"
        class="head tophead bb-m b--slate pb3-m nowrap f-thead storm tl pr4"
        :class="{ '-first': i === 0, '-last': i === Object.keys(headers).length - 1 }"
      >{{ headers[key] }}</div>
      <template v-for="(item, i) in summaryItem">
        <div
          :key="i"
          class="summary bn-m bb b--slate b f-body b pt4 pr4 pb4 pb0-m"
          :class="{'-first': i === 0, '-last': i === summaryItem.length - 1 }"
          v-html="item.title"
        ></div>
        <div
          v-for="({ header, value, key }) in item.values"
          :key="header + i"
          class="values pv3-m tl-m tr flex items-center db-m"
          :class="{'-first': i === 0, '-last': i === summaryItem.length - 1, '-last-in-row': key === 'total' }"
          :style="!(value !== null && value !== undefined) && { padding: 0, margin: 0 }"
        >
          <span
            class="f-label mr-auto dn-m"
            v-show="value !== null && value !== undefined"
          >{{ header }}</span>
          {{ value }}
        </div>
      </template>
    </div>
    <div class="flex bt b--geyser f-body b pt4 mt3 mt0-m totaldue items-start">
      <i18n tag="p" class="pr6" path="Estimated Total due on {date}">
        <template #date>
          <UseVuex v-slot="{ state }">
            <span v-html="state.common.firstOfNextMonth"></span>
          </UseVuex>
        </template>
      </i18n>
      <span
        v-if="upcomingInvoiceAmount"
        class="ml-auto f-header"
      >{{ $t('USD') }}&nbsp;{{ upcomingInvoiceAmount.displayValue }}</span>
      <span class="ml-auto f-header" v-else>{{ $t('Unknown') }}</span>
    </div>
    <div class="infomsg flex f-tiny mt5" v-if="yearlyLicenses && yearlyLicenses.length">
      <UIIcon
        name="alert-circle"
        style="width:2em;"
        class="flex-shrink-0 rotate-180 dib fill-blue mr3"
      />
      <i18n
        tag="p"
        path="You are billed immediately when you buy brand new monthly or yearly licenses. As a result, these purchases don’t appear in your recurring monthly invoice table seen above, but they can be reviewed separately in your {link}."
      >
        <template #link>
          <UIButton link :to="{ name: 'billing-invoices' }">{{ $t('Invoice History') }}</UIButton>
        </template>
      </i18n>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import DateHelper from '@/core/shared/helpers/DateHelper';
import moment from 'moment';
import UseVuex from '@/core/shared/components/utils/UseVuex';
import UIButton from '@/core/shared/components/ui/UIButton';
import UIIcon from '@/core/shared/components/ui/UIIcon';
import { get } from 'lodash-es';

@Component({
  components: {
    UseVuex,
    UIIcon,
    UIButton,
  },
  props: {
    game: { required: true },
  },
})
export default class UpcomingInvoice extends Vue {
  get headers () {
    return {
      license: this.$t('License Option'),
      quantity: this.$t('Quantity'),
      unitPrice: this.$t('Unit Price'),
      usage: this.$t('Minutes Used'),
      total: this.$t('Total'),
    };
  }

  get summaryItem () {
    const result = [];
    if (get(this.game, 'license.monthlyLicenses.length')) {
      result.push({
        title: `Monthly Charges (${this.nextMonthRangeDateDisplay})`,
        values: {
          license: {
            header: this.headers.license,
            key: 'license',
            value: this.$t('Monthly License'),
          },
          quantity: {
            header: this.headers.quantity,
            key: 'quantity',
            value: get(this.game, 'license.currentMonthlyCost.quantity'),
          },
          unitPrice: {
            header: this.headers.unitPrice,
            key: 'unitPrice',
            value: get(this.game, 'ownership.monthlyPrice.current.displayPrice'),
          },
          usage: {
            header: this.headers.usage,
            key: 'usage',
            value: get(this.game, 'license.currentMonthlyUsage.displayValue', this.$t('Unknown')),
          },
          total: {
            header: this.headers.total,
            key: 'total',
            value: get(this.game, 'license.currentMonthlyCost.total.displayValue', this.$t('Unknown')),
          },
        },
      });
    }
    if (get(this.game, 'ownership.minutePrice.current')) {
      result.push({
        title: `Usage Summary (${this.monthRangeDateDisplay})`,
        values: {
          license: {
            header: this.headers.license,
            value: this.$t('Per Minute License'),
          },
          quantity: {
            header: this.headers.quantity,
            value: null,
          },
          unitPrice: {
            header: this.headers.unitPrice,
            value: get(this.game, 'ownership.minutePrice.current.displayPrice'),
          },
          usage: {
            header: this.headers.usage,
            value: get(this.game, 'license.currentMinuteUsage.displayValue', this.$t('Unknown')),
          },
          total: {
            header: this.headers.total,
            value: get(this.game, 'license.currentMinuteCost.total.displayValue', this.$t('Unknown')),
          },
        },
      });
    }
    return result;
  }

  get upcomingInvoiceAmount () {
    return this.game.license.upcomingInvoiceAmount;
  }

  get displayPriceMinute () {
    return this.game.ownership.minutePrice && this.game.ownership.minutePrice.current && this.game.ownership.minutePrice.current.displayPrice;
  }

  get stationsInstalled () {
    return this.game.stationsInstalled;
  }

  get minuteLicenseAvailable () {
    return this.game.ownership.minutePrice.current;
  }

  get monthlyLicenses () {
    return this.game.license.monthlyLicenses;
  }

  get yearlyLicenses () {
    return this.game.license.yearlyLicenses;
  }

  get monthRangeDateDisplay () {
    const now = DateHelper.getClientNowMoment();
    const endOfMonth = moment(now).endOf('month').format('DD');
    const localizedFormat = moment.localeData().longDateFormat('LL');
    const finalFormat = localizedFormat.replace('D', `1 - ${endOfMonth}`);
    return moment(now).startOf('month').format(finalFormat).replace(/\s/g, '&nbsp;');
  }

  get nextMonthRangeDateDisplay () {
    const fisrtOfNextMonth = DateHelper.getClientNowMoment().startOf('month')
      .add(1, 'months');
    const endOfMonth = moment(fisrtOfNextMonth).endOf('month').format('DD');
    const localizedFormat = moment.localeData().longDateFormat('LL');
    const finalFormat = localizedFormat.replace('D', `1 - ${endOfMonth}`);
    return moment(fisrtOfNextMonth).startOf('month').format(finalFormat).replace(/\s/g, '&nbsp;');
  }
}
</script>

<style scoped lang="postcss">
@import "core/shared/styles";

.upcoming-invoice {
  & .w-1 {
    width: 1px;
  }
  display: grid;
  grid-template-columns: 1fr 1fr;
  @media (--tablet) {
    display: grid;
    grid-template-columns: 1fr repeat(4, auto);
  }
  & .tophead {
    display: none;
    padding-bottom: 1.6rem;
    @media (--tablet) {
      display: initial;
      &.-last {
        text-align: right;
        padding-right: 0;
      }
    }
  }
  & .head {
    grid-column: 1 / 1;
    @media (--tablet) {
      grid-column: auto;
    }
  }
  & .values {
    grid-column: 1 / -1;
    @media (--tablet) {
      padding: 1.4rem 0;
      grid-column: auto;
      &.-last {
        padding-bottom: 3rem;
      }
      &.-last-in-row {
        text-align: right;
      }
    }
  }
  & .values,
  & .head {
    margin-bottom: 1rem;
    @media (--tablet) {
      margin-bottom: 0rem;
      order: initial !important;
      width: initial;
    }
  }

  & .summary {
    grid-column: 1 / -1;
    margin-bottom: 2rem;
    @media (--tablet) {
      &.-first {
        padding-top: 3.3rem;
      }
      padding-top: 1.6rem;
      margin-bottom: 0;
      grid-column: auto;
      grid-row: auto;
      & + * {
        grid-column-start: 1;
      }
    }
  }
}
</style>
