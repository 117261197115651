import { ref, computed } from '@vue/composition-api';
import { useLoading } from '@/core/shared/composition-helpers';
import store from '@/core/store';
import { gql } from '@/core/shared/api';

export function useGenres () {
  const ids = ref();
  const genres = computed(() => ids.value && store.getters['entities/Genre'](ids.value));
  const { loading, subscribeToPromise } = useLoading();
  (async function fetchGenres () {
    const promise = (async function () {
      const data = await gql.request(`query {
        genres {
          id
          title
        }
      }`, { cancelKey: 'genresQuery' }); ;
      if (data) {
        const {
          genres,
        } = data;
        ids.value = await store.dispatch('insertEntities', {
          name: 'Genre',
          payload: genres,
        });
      }
    })();
    subscribeToPromise(promise);
  })();
  return {
    loading,
    genres,
  };
}
