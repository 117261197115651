<template>
  <Modal
    class="agreement-modal"
    ref="modal"
    :open="true"
    :prevent-scroll="true"
    @closeModal="(reason) => $emit('closeModal', reason)"
  >
    <template v-if="step === 'eulas'">
      <div class="flex flex-column h-100">
        <h2 class="title f-subtitle mh6-m mh5 mv5">{{ $t('Accept all End User License Agreements') }}</h2>
        <div class="selectwrapper mh6-m mh5 mb4 ">
          <UISelect
            id="selectedContentOwnership"
            :value="selectedContentOwnershipId"
            @change="selectContentOwnership"
          >
            <option
              v-for="contentOwnership in contentOwnershipsWithoutLicense"
              :key="contentOwnership.id"
              :value="contentOwnership.id"
              :UISelected="contentOwnership.Id === selectedContentOwnershipId"
            >{{ contentOwnership.title }}</option>
          </UISelect>
          <div class="radio-grid">
            <UIRadio
              :title="$t('Current agreement')"
              :value="agreementType === 'current'"
              @change="selectAgreementType('current')"
            />
            <UIRadio
              :title="$t('Upcoming agreement')"
              :value="agreementType === 'upcoming'"
              :disabled="!selectedContentOwnership.upcomingAgreement"
              @change="selectAgreementType('upcoming')"
            />
          </div>
        </div>
        <div class="contentwrapper mh6-m mh5 mb4 f-body flex-auto h0">
          <div class="content f-body overflow-scroll h-100">
            <div v-html="agreement.content" class="__content mb6 pr3"></div>
          </div>
        </div>
        <div class="mh6-m mh5 pb5 f-body flex flex-wrap flex-shrink-0 items-center justify-between">
          <div class="flex-shrink-0 mr5">
            <div class="acceptbydate" v-if="formattedRequireAgreementByDate">
              <h6 class="f-instructions orange">{{ $t('This new EULA needs to be accepted by') }}</h6>
              <p class="f-tiny">{{ formattedRequireAgreementByDate }}</p>
            </div>
            <div class="expirationdate" v-else-if="formattedExpirationDate">
              <h6 class="f-label">{{ $t('Expires On') }}</h6>
              <p class="f-tiny">{{ formattedExpirationDate }}</p>
            </div>
            <div class="latestupdate" v-else>
              <h6 class="f-label">{{ $t('Latest Update') }}</h6>
              <p class="f-tiny">{{ formattedLatestUpdateDate }}</p>
            </div>
          </div>
          <div class="acceptdeclinebuttons mt4 mt0-m flex flex-auto">
            <UIButton
              class="declinebtn db mr2 w-50 flex-auto"
              ghost
              @click="cancel"
            >{{ $t('Cancel') }}</UIButton>
            <UIButton class="acceptbtn db w-50 flex-auto" @click="next">{{ $t('Accept All & Continue') }}</UIButton>
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="flex flex-column h-100">
        <h2 class="title f-subtitle mh6-m mh5 mv5">{{ $t('Purchase Licenses') }}</h2>
        <div class="contentwrapper mh6-m mh5 mb4 f-body flex-auto h0">
          <UIInput
            :title="$t('Number of bundles')"
            v-model="order.quantity"
            type="number"
            rules="required|minValue:1"
          />
          <table class="contenttable">
            <tr>
              <th>{{ $t('Title') }}</th>
              <th align="center">{{ $t('Current licenses') }}</th>
            </tr>
            <tr v-for="content in order.contents" :key="content.contentOwnershipId">
              <td>{{ getTitle(content) }}</td>
              <td align="center">{{ content.activeQuantity }}</td>
            </tr>
          </table>
          <div class="orderdescription" v-for="content in order.contents" :key="content.contentOwnershipId">
            <div v-if="content.activeQuantity < order.quantity">
              {{ order.quantity - content.activeQuantity }} {{ getTitle(content) }} licenses will be purchased.
            </div>
          </div>
          <div class="acceptdeclinebuttons mt4 mt0-m flex flex-auto">
              <UIButton
                class="declinebtn db mr2 w-50 flex-auto"
                ghost
                @click="cancel"
              >{{ $t('Cancel') }}</UIButton>
            <UIButton class="acceptbtn db w-50 flex-auto" @click="next">{{ $t('Purchase') }}</UIButton>
          </div>
        </div>
      </div>
    </template>
  </Modal>
</template>

<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import Modal from '@/core/shared/components/Modal';
import UIButton from '@/core/shared/components/ui/UIButton';
import UIIcon from '@/core/shared/components/ui/UIIcon';
import UISelect from '@/core/shared/components/ui/UISelect';
import UIRadio from '@/core/shared/components/ui/UIRadio';
import UIInput from '@/core/shared/components/ui/UIInput';
import moment from 'moment';

@Component({
  components: {
    Modal,
    UIButton,
    UIIcon,
    UISelect,
    UIRadio,
    UIInput,
  },
  props: {
    bundle: {
      type: Object,
      required: true,
    },
    requireAgreement: {
      type: Boolean,
    },
  },
})
export default class PurchaseBundleModal extends Vue {
  step = this.contentOwnershipsWithoutLicense.length > 0 ? 'eulas' : 'licenses';
  selectedContentOwnershipId = this.contentOwnershipsWithoutLicense.length > 0 ? this.contentOwnershipsWithoutLicense[0].id : null;
  agreementType = 'current';
  order = {
    quantity: 1,
    contents: [],
  };

  async mounted () {
    this.order = await this.$store.dispatch('bundlePurchaseMinimumLicenseQuantities', this.bundle.id);
  }

  get contentOwnershipsWithoutLicense () {
    return this.bundle.contents.filter(x => x.contentOwnership.operatorGame === null).map(x => x.contentOwnership);
  }

  get contentOwnerships () {
    return this.bundle.contents.map(x => x.contentOwnership);
  }

  get formattedLatestUpdateDate () {
    return this.agreement.updatedAt && moment(this.agreement.updatedAt).format('LL');
  }

  get formattedExpirationDate () {
    return this.agreement.endsAt && moment(this.agreement.endsAt).format('LL');
  }

  get formattedRequireAgreementByDate () {
    return !this.agreement.isCurrent && this.requireAgreement && this.agreement.startsAt && moment(this.agreement.startsAt).format('LL');
  }

  get agreement () {
    return this.agreementType === 'current'
      ? this.selectedContentOwnership.currentAgreement
      : this.selectedContentOwnership.upcomingAgreement;
  }

  get selectedContentOwnership () {
    return this.contentOwnerships.find(x => x.id === this.selectedContentOwnershipId);
  }

  selectAgreementType (value) {
    this.agreementType = value;
  }

  selectContentOwnership ({ target }) {
    this.agreementType = 'current';
    this.selectedContentOwnershipId = target.value;
  }

  getTitle (content) {
    return this.contentOwnerships.find(x => x.id === content.contentOwnershipId).title;
  }

  getMinimumQuantity (content) {
    return Math.max(content.minimumQuantity, this.order.quantity);
  }

  closeModal (reason) {
    this.$refs.modal.close(reason);
  }

  cancel () {
    this.$emit('cancel');
    this.closeModal('cancel');
  }

  next () {
    if (this.step === 'eulas') {
      this.step = 'purchase';
    } else {
      this.$emit('next', this.order);
      this.closeModal('next');
    }
  }
}
</script>

<style scoped lang="postcss">
@import "core/shared/styles";

.agreement-modal {
  & >>> .panel {
    min-height: 300px;
    max-height: 100%;
    display: flex;
    flex: 1 1 100%;
    /* display: flex; */
  }
  & >>> .main-content {
    /* height: 100%; */
    flex: 1 1 auto;
    min-height: 0;
  }
  & >>> pre {
    white-space: pre-wrap;
  }
  & .selectwrapper {
    position: relative;
  }
  & .radio-grid {
    display: grid;
    grid-row-gap: 1rem;
    padding-top: var(--spacingSm);
  }
  & .contentwrapper {
    position: relative;
    &:after {
      content: "";
      pointer-events: none;
      background: linear-gradient(to top, #fff, rgba(255, 255, 255, 0));
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      z-index: 5;
      height: 30%;
    }
  }
  & .acceptdeclinebuttons {
    min-width: 30rem;
    max-width: 60rem;
  }
  & .content {
    white-space: pre-wrap;
  }
  & .contenttable {
    margin-top: var(--spacingMd);
    width: 100%;
  }
  & .contenttable th {
    text-align: left;
    font-weight: bold;
  }
  & .orderdescription {
    margin-top: var(--spacingMd);
    margin-bottom: var(--spacingMd);
    text-align: left;
  }
}
</style>
