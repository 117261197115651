<script>
import AgreementModal from './AgreementModal';
import { createModalWrapper } from '@/core/shared/helpers/HOCHelper';
const AgreementModalWrapper = createModalWrapper(AgreementModal, { requireAgreement: false });
import Component from 'vue-class-component';

@Component({
  components: {
    AgreementModalWrapper,
  },
  inheritAttrs: false,
  props: {
    ownership: { required: true },
  },
})
export default class AgreementModalConnected extends Component {
  get currrentOrUpcoming () {
    if (this.ownership.operatorLicense.promptForCurrentLicense) return 'current';
    else if (this.ownership.operatorLicense.promptForUpcomingLicense) return 'upcoming';
  }

  async requireAgreementsForGame () {
    const shouldRequireUpcoming = this.currrentOrUpcoming === 'current' && this.ownership.upcomingAgreement;
    let reason = await this.showAgreement(this.ownership[`${this.currrentOrUpcoming}Agreement`]);
    if (shouldRequireUpcoming && reason === 'accept') reason = await this.showAgreement(this.ownership.upcomingAgreement);
    return reason === 'accept' ? Promise.resolve() : Promise.reject(new Error());
    // if (reason === 'accept' && !this.$route.matched.some(({ name }) => name === 'content-library-show')) this.$router.push({ name: 'content-library-show', params: { gameId: this.ownership.operatorGame.id } })
  }

  async showAgreement (agreement) {
    return new Promise((resolve, reject) => {
      const expired = this.ownership.operatorLicense && this.ownership.operatorLicense.disabledForOutOfDateAgreement;
      const status = this.getAgreementStatus(agreement);
      const props = {
        agreement,
        title: this.ownership.title,
        expired,
        requireAgreement: status === 'pending',
      };
      const show = () => this.$refs.modal.show(props, {
        accept: async () => {
          try {
            await this.acceptAgreement(agreement);
            resolve('accept');
          } catch (e) {
            reject(e);
          }
        },
        decline: async () => {
          try {
            try {
              await this.declineAgreement();
              resolve('decline');
            } catch (e) {
              this.__backAction();
            }
          } catch (e) {
            reject(e);
          }
        },
        closeModal: (reason) => {
          if (!reason) resolve('closeModal');
        },
      });
      show();
      this.__backAction = show;
    });
  }

  getAgreementStatus (agreement) {
    if (!this.ownership.operatorGame || !this.ownership.operatorLicense || !agreement) return;
    const { id } = agreement;
    const isCurrent = id === this.ownership.currentAgreement.id;
    const isWaitingForCurrent = !isCurrent && this.ownership.operatorLicense.promptForCurrentLicense;
    if (isWaitingForCurrent) return 'disabled';
    else if (this.ownership.operatorLicense[`promptFor${isCurrent ? 'Current' : 'Upcoming'}License`]) return 'pending';
    else return 'accepted';
  }

  async acceptAgreement (agreement) {
    await this.$store.dispatch('acceptContentOwnershipAgreement', agreement);
  }

  async declineAgreement () {
    return new Promise((resolve, reject) => {
      this.$store.commit('setConfirm', {
        show: true,
        title: this.$t('Are you sure you want to delete this content?'),
        message: this.$t('Declining the End User License Agreement will deactivate the license, delete the title from your Library and uninstall it from all of your computers.'),
        icon: 'icon-trash',
        closable: false,
        buttons: [
          {
            name: this.$t('Cancel'),
            type: 'cancel',
            action: () => {
              reject(new Error());
            },
          },
          {
            name: this.$t('Decline & Delete'),
            action: () => {
              this.deleteContent();
              resolve();
            },
          },
        ],
      });
    });
  }

  async deleteContent () {
    await this.$store.dispatch('removeGameFromLibrary', this.ownership.operatorGame);
    this.$router.push({ name: 'content-library' });
  }
}
</script>

<template>
  <AgreementModalWrapper ref="modal" class="modal" :agreement="null" :title="null" v-bind="$attrs"/>
</template>
