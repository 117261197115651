import { ref } from '@vue/composition-api';

export default function useLoading () {
  const promisesToSubcribeTo = new Set();
  const loading = ref(false);
  function refreshPromiseSubscription () {
    loading.value = Boolean(promisesToSubcribeTo.size);
  };
  async function subscribeToPromise (promise) {
    promisesToSubcribeTo.add(promise);
    refreshPromiseSubscription();
    promise.finally(() => {
      promisesToSubcribeTo.delete(promise);
      refreshPromiseSubscription();
    });
  }
  return {
    loading,
    subscribeToPromise,
  };
}
