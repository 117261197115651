<template>
  <div
    class="marketplace-featured relative overflow-hidden"
    v-if="featuredContent && featuredContent.length"
    :style="{ '--localBackgroundImg': currentContent && `url('${currentContent.imageSvgUrl || currentContent.imageUrl}')` }"
  >
    <div class="featuredgames mb4 mt4" v-if="!loadingFeaturedContent">
      <div class="flex items-center ph4" style="max-width: 100rem;margin: 0 auto;">
        <UIButton unstyled @click="slideLeft">
          <UIIcon class="fill-white w3" name="chevron-left"></UIIcon>
        </UIButton>
        <div class="hoopercarousel w-80 center flex-shrink-0">
          <Hooper
            ref="sliderRef"
            :infiniteScroll="true"
            :itemsToShow="3.5"
            :centerMode="true"
            :wheelControl="false"
            style="height: auto"
            @slide="onSlide"
          >
            <Slide
              v-for="(content, i) in featuredContent"
              :key="content.id"
              :style="{opacity: i === currentSlide ? 1 : 0.6 }"
            >
              <div class="thumbnailcontainer pa2 relative">
                <div
                  class="featuredthumbnail relative"
                  :class="i === currentSlide ? 'ba b--white bw2' : ''"
                  @click="slideTo(i)"
                >
                  <RatioContainer class="ratiocontainer" :ratio="46.5863">
                    <LazyImg
                      v-if="content.imageUrl"
                      :src="content.imageUrl"
                      :srcset="content.imageSrcSet"
                      :placeholder="content.imageSvgUrl"
                      :alt="content.title"
                      class="posterimg db w-100 h-100 z-3"
                    />
                  </RatioContainer>
                </div>
              </div>
            </Slide>
          </Hooper>
        </div>
        <UIButton unstyled @click="slideRight">
          <UIIcon class="fill-white w3" name="chevron-right"></UIIcon>
        </UIButton>
      </div>
      <transition
        appear
        mode="out-in"
        enter-active-class="fadeIn animated-300"
        leave-active-class="fadeOut animated-300"
      >
        <div class="pa4 mt1" :key="currentContent && currentContent.id">
          <div class>
            <div class="flex-auto w-100">
              <div class="flex items-center justify-center">
                <div class="flex-auto bb b--geyser"></div>
                <div class="flex-shrink-0 ph5">
                  <h3 class="f-body white tc">Featured Now</h3>
                  <h3 class="contenttitle f-title mb3 white tc">{{ currentContent.title }}</h3>
                </div>
                <div class="flex-auto bb b--geyser"></div>
              </div>
              <!-- <p
                class="f-label b white mb4"
              >{{ currentContent.genres && currentContent.genres.slice(0, 3).map(({ title }) => title).join(', ') }}</p>-->
            </div>
            <div class="flex items-center">
              <div class="tc flex-auto w-33 flex flex-column">
                <div v-if="currentContent.minutePrice && currentContent.minutePrice.current">
                  <span class="f-title b white">{{ currentContent.minutePrice.current.displayPrice }}</span>
                  <p class="f-body b white">Per Minute</p>
                </div>
                <div
                  class="flex mt4 justify-center flex-gap"
                  v-if="(currentContent.monthlyPrice && currentContent.monthlyPrice.current) || (currentContent.yearlyPrice && currentContent.yearlyPrice.current)"
                >
                  <div v-if="currentContent.monthlyPrice && currentContent.monthlyPrice.current">
                    <span
                      class="f-body b white"
                    >{{ currentContent.monthlyPrice.current.displayPrice }}</span>
                    <p class="f-label geyser">Per Month</p>
                  </div>
                  <div v-if="currentContent.yearlyPrice && currentContent.yearlyPrice.current">
                    <span
                      class="f-body b white"
                    >{{ currentContent.yearlyPrice.current.displayPrice }}</span>
                    <p class="f-label geyser">Per Year</p>
                  </div>
                </div>
              </div>
              <div class="tc relative flex-auto w-50" style="height: 14rem;">
                <p
                  class="f-body white mb4 center measure-wide featureddesc h-100"
                  v-html="currentContent.shortDescription || currentContent.description"
                ></p>
                <UIButton
                  class="absolute bottom-0"
                  style="left: 50%; transform: translate(-50%, 0); box-shadow: 0 0 25px rgba(0,0,0,.4)"
                  :to="routerLink(currentContent)"
                >{{$t('Learn More')}}</UIButton>
              </div>
              <div class="tc flex-auto w-33">
                <template v-if="currentContent.rating.count">
                  <p class="f-body b b white v-mid">{{ currentContent.rating.text }}</p>
                  <p
                    class="f-body white"
                  >({{$t('{percentage} on {count} reviews', { percentage: currentContent.rating.percentage, count:currentContent.rating.count })}})</p>
                </template>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import { useFeaturedContent } from '../compositions/useFeaturedContent';
import { Hooper, Slide } from 'hooper';
import 'hooper/dist/hooper.css';
import UIButton from '@/core/shared/components/ui/UIButton';
import UIIcon from '@/core/shared/components/ui/UIIcon';
import RatioContainer from '@/core/shared/components/utils/RatioContainer';
import LazyImg from '@/core/shared/components/utils/LazyImg';
import { ref, computed } from '@vue/composition-api';

export default {
  components: {
    Hooper,
    Slide,
    UIButton,
    UIIcon,
    RatioContainer,
    LazyImg,
  },
  props: {

  },
  setup () {
    const { loading: loadingFeaturedContent, featuredContent } = useFeaturedContent();
    const currentSlide = ref(0);
    const currentContent = computed(() => {
      return featuredContent.value && featuredContent.value[currentSlide.value];
    });
    const sliderRef = ref(null);
    function onSlide (payload) {
      currentSlide.value = payload.currentSlide < 0 ? featuredContent.value.length + payload.currentSlide : payload.currentSlide % featuredContent.value.length;
    }
    function slideTo (i) {
      sliderRef.value.slideTo(i);
    }
    function slideRight (i) {
      sliderRef.value.slideNext();
    }
    function slideLeft (i) {
      sliderRef.value.slidePrev();
    }
    function routerLink (content) {
      return content.bundle?.id
        ? { name: 'marketplace-bundle-show', params: { bundleId: content.bundle.id } }
        : { name: 'marketplace-content-show', params: { contentOwnershipId: content.id } };
    }
    return {
      loadingFeaturedContent,
      featuredContent,
      onSlide,
      currentSlide,
      currentContent,
      slideTo,
      slideRight,
      slideLeft,
      sliderRef,
      routerLink,
    };
  },
};
</script>

<style lang="postcss" scoped>
@import "core/shared/styles";
.marketplace-featured {
  &:before {
    background-color: rgba(0, 0, 0, 0);
    mask-image: linear-gradient(rgba(0, 0, 0, 1), transparent);
    background-image: var(--localBackgroundImg);
    /* filter: blur(2px); */
    background-blend-mode: multiply;
    background-position: center;
    background-size: cover;
    content: "";
    position: absolute;
    top: -25px;
    left: -25px;
    right: -25px;
    bottom: -25px;
    z-index: -1;
  }
  &:after {
    background-color: rgba(0, 0, 0, 0.6);
    /* mask-image: linear-gradient(transparent, rgba(0, 0, 0, 1)); */
    background-image: var(--localBackgroundImg);
    filter: blur(7px);
    background-blend-mode: multiply;
    background-position: center;
    background-size: cover;
    content: "";
    position: absolute;
    top: -25px;
    left: -25px;
    right: -25px;
    bottom: -25px;
    z-index: -2;
  }
  & .featuredbadge {
    clip-path: polygon(100% 0, 96% 50%, 100% 100%, 0% 100%, 0 50%, 0% 0%);
  }
  & .hoopercarousel {
    mask-image: linear-gradient(
      to right,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 1) 5%,
      rgba(0, 0, 0, 1) 95%,
      rgba(0, 0, 0, 0) 100%
    );
  }
  & .featureddesc {
    mask-image: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 1) 30%,
      rgba(0, 0, 0, 0) 80%
    );
  }
  & .flex-gap {
    & > * + * {
      margin-left: var(--spacingMd);
    }
  }
  & .featuredbadgeshadow {
    /* left: -10px; */
    filter: drop-shadow(4px 2px 4px rgba(50, 50, 0, 0.5));
  }
  & .featuredbadgemin {
    clip-path: polygon(100% 0, 96% 50%, 100% 100%, 0% 100%, 0 50%, 0% 0%);
  }
  & .featuredthumbnail {
    overflow: hidden;
    border-radius: var(--radius);
    box-shadow: 0 0.2rem 0.8rem 0 rgba(0, 0, 0, 0.65);
  }
  & >>> .hooper:focus {
    outline: none;
  }
  & >>> .hooper-list {
    width: 100%;
  }
  & .thumbnailcontainer {
  }
  & .contenttitle {
    font-size: 4rem;
    line-height: 4rem;
  }
  & .animated-300 {
    animation-duration: 300ms;
  }
}
</style>
