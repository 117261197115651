<template>
  <Modal
    class="agreement-modal"
    ref="modal"
    :open="true"
    :prevent-scroll="true"
    @closeModal="(reason) => $emit('closeModal', reason)"
  >
    <div class="flex flex-column h-100">
      <i18n
        tag="h2"
        class="title f-subtitle mh6-m mh5 mv5"
        path="{title} End User License Agreement"
      >
        <template #title>{{ title }}</template>
      </i18n>
      <div class="contentwrapper mh6-m mh5 mb4 f-body flex-auto h0">
        <div class="content f-body overflow-scroll h-100">
          <div v-html="agreement.content" class="__content mb6 pr3"></div>
        </div>
      </div>
      <div class="expiredbanner bg-orange pa4 f-body white tc mb4" v-if="expired">
        <span
          class="w-70-m center db"
        >{{ $t('This content was disabled from your Launcher and your Library. To re-enable it, please accept the new End User License Agreement.')}}</span>
      </div>
      <div class="mh6-m mh5 pb5 f-body flex flex-wrap flex-shrink-0 items-center justify-between">
        <div class="flex-shrink-0 mr5">
          <div class="acceptbydate" v-if="formattedRequireAgreementByDate">
            <h6 class="f-instructions orange">{{ $t('This new EULA needs to be accepted by') }}</h6>
            <p class="f-tiny">{{ formattedRequireAgreementByDate }}</p>
          </div>
          <div class="expirationdate" v-else-if="formattedExpirationDate">
            <h6 class="f-label">{{ $t('Expires On') }}</h6>
            <p class="f-tiny">{{ formattedExpirationDate }}</p>
          </div>
          <div class="latestupdate" v-else>
            <h6 class="f-label">{{ $t('Latest Update') }}</h6>
            <p class="f-tiny">{{ formattedLatestUpdateDate }}</p>
          </div>
        </div>
        <template v-if="!requireAgreement">
          <UIButton class="backbtn db" secondary @click="back">{{ $t('Back') }}</UIButton>
        </template>
        <div class="acceptdeclinebuttons mt4 mt0-m flex flex-auto" v-else>
          <UIButton
            class="declinebtn db mr2 w-50 flex-auto"
            ghost
            @click="decline"
          >{{ $t('Decline') }}</UIButton>
          <UIButton class="acceptbtn db w-50 flex-auto" @click="accept">{{ $t('Accept') }}</UIButton>
        </div>
      </div>
    </div>
  </Modal>
</template>

<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import Modal from '@/core/shared/components/Modal';
import UIButton from '@/core/shared/components/ui/UIButton';
import UIIcon from '@/core/shared/components/ui/UIIcon';
import moment from 'moment';

@Component({
  components: {
    Modal,
    UIButton,
    UIIcon,
  },
  props: {
    title: {
      required: true,
    },
    agreement: {
      required: true,
    },
    expired: {
      type: Boolean,
    },
    requireAgreement: {
      type: Boolean,
    },
  },
})
export default class AgreementModal extends Vue {
  get formattedLatestUpdateDate () {
    return this.agreement.updatedAt && moment(this.agreement.updatedAt).format('LL');
  }

  get formattedExpirationDate () {
    return this.agreement.endsAt && moment(this.agreement.endsAt).format('LL');
  }

  get formattedRequireAgreementByDate () {
    return !this.agreement.isCurrent && this.requireAgreement && this.agreement.startsAt && moment(this.agreement.startsAt).format('LL');
  }

  closeModal (reason) {
    this.$refs.modal.close(reason);
  }

  decline () {
    this.$emit('decline', this.agreement);
    this.closeModal('decline');
  }

  back () {
    this.$emit('back');
    this.closeModal('back');
  }

  accept () {
    this.$emit('accept', this.agreement);
    this.closeModal('accept');
  }
}
</script>

<style scoped lang="postcss">
@import "core/shared/styles";

.agreement-modal {
  & >>> .panel {
    min-height: 300px;
    max-height: 100%;
    display: flex;
    flex: 1 1 100%;
    /* display: flex; */
  }
  & >>> .main-content {
    /* height: 100%; */
    flex: 1 1 auto;
    min-height: 0;
  }
  & >>> pre {
    white-space: pre-wrap;
  }
  & .contentwrapper {
    position: relative;
    &:after {
      content: "";
      pointer-events: none;
      background: linear-gradient(to top, #fff, rgba(255, 255, 255, 0));
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      z-index: 5;
      height: 30%;
    }
  }
  & .acceptdeclinebuttons {
    min-width: 30rem;
    max-width: 60rem;
  }
  & .content {
    white-space: pre-wrap;
  }
}
</style>
