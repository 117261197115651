<template>
  <div class="game-basics-form f-body">
    <UIInput
      class="fullrow"
      :title="$t('Content Title')"
      v-model="localGame.title"
      :disabled="disableTitle"
      :required="true"
      rules="required|max:70"
    />
    <UIInput
      textarea
      defaultMinHeight
      class="inputdesc fullrow"
      v-if="localGame.ownership"
      v-model="localGame.ownership.description"
      :title="$t('Content ownership description')"
      :disabled="true"
      :instructions="$t('This description by the content creator may contain installation instructions.')"
    />
    <UIInput
      textarea
      defaultMinHeight
      class="inputdesc fullrow"
      v-model="localGame.description"
      :title="$t('Description')"
      :instructions="$t('This description will be visible to the customer in the HMD.')"
    />
    <div>
      <UIFormItem
        :title="$t('Content Image')"
        :instructions="$t('This image will be seen by players when they preview your content in the launcher.')"
        :required="true"
      ></UIFormItem>
      <FileUploadWithThumbnail
        id="image"
        type="image"
        :media="getMedia('image')"
        :specs="$t('Recommended Dimensions: 600x280px, 2MB maximum')"
        :required="true"
        :ratio="280 * 100 / 600"
        class="mt2"
        accept="image/*"
        @input="onFileUploadInput"
        @clear="onFileUploadClear"
        :rules="`required|image|sizeMB:${2}`"
        :fieldNameOverride="$t('Content Image')"
      />
    </div>
    <div>
      <UIFormItem
        :title="$t('Content Trailer')"
        :instructions="$t('This video will be seen by players when they preview your content in the launcher.')"
      ></UIFormItem>
      <FileUploadWithThumbnail
        id="video"
        type="video"
        :media="getMedia('video')"
        :specs="$t('Recommended Size: 500MB maximum')"
        :ratio="280 * 100 / 600"
        class="mt2"
        accept="video/*"
        @input="onFileUploadInput"
        @clear="onFileUploadClear"
        :rules="`mimes:video/*|sizeMB:${500}`"
        :fieldNameOverride="$t('Content Trailer')"
      />
      <div class="video-note">{{ $t('NOTE: When left empty, the default trailer for this content will show. After uploading your video will be converted. This may take a few minutes!') }}</div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import UIInput from '@/core/shared/components/ui/UIInput';
import UISelect from '@/core/shared/components/ui/UISelect';
import UIFormItem from '@/core/shared/components/ui/UIFormItem';
import FileUploadWithThumbnail from '@/core/shared/components/FileUploadWithThumbnail';

@Component({
  components: {
    UIInput,
    UISelect,
    UIFormItem,
    FileUploadWithThumbnail,
  },
  props: {
    game: { required: true },
    disableTitle: { type: Boolean },
  },
})
export default class GameBasicsForm extends Vue {
  get localGame () {
    return new Proxy(this.game, {
      get (obj, k) {
        return obj[k];
      },
      set: (obj, k, val) => {
        this.$emit('edit', k, val);
        return true;
      },
    });
  }

  getMedia (type) {
    // const poster = this.localGame.imageUrl || (this.localGame._imageBlob && this.localGame._imageBlob.src)
    const savedMedia = this.localGame[`${type}Url`] && {
      title: '',
      src: this.localGame[`${type}Url`],
      // poster
    };
    const newMedia = this.localGame[`_${type}Blob`] && {
      title: this.localGame[`_${type}Blob`].title,
      src: this.localGame[`_${type}Blob`].src,
      // poster
    };
    return newMedia || savedMedia;
  }

  onFileUploadInput ({ id, ...media }) {
    this.localGame[`_${id}Blob`] = media;
  }

  onFileUploadClear ({ id }) {
    if (`_${id}Blob` in this.localGame) {
      this.localGame[`_${id}Blob`] = null;
    } else {
      this.localGame[`${id}Url`] = null;
    }
  }
}
</script>

<style scoped lang="postcss">
@import "core/shared/styles";

.game-basics-form {
  display: grid;
  column-gap: var(--spacingSm);
  row-gap: var(--spacingLg);
  grid-template-columns: 1fr;
  @media (--tablet) {
    grid-template-columns: 1fr 1fr;
  }
  & .fullrow {
    grid-column: 1 / -1;
  }
  & .video-note {
    @apply --f6;
    margin-top: 1rem;
    color: var(--colorSBRedFlat);
  }
}
</style>
