<template>
  <RouterView></RouterView>
</template>

<script>
import Vue from 'vue';
import Component from 'vue-class-component';

@Component()
export default class TagRoute extends Vue {
  created () {
    this.$store.dispatch('getTags');
    this.$store.dispatch('getGamesForTagsRoute');
  }
}
</script>
