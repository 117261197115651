<template>
  <Confirm
    class="show confirm"
    @closeConfirm="close"
    :show="true"
    :prevent-scroll="true"
    :show-close-btn="true"
    :buttons="buttons"
    icon="icon-trash"
    :title="isLicensedGame ? $t('Do you want to delete this content and terminate your licenses?') : $t('Do you want to delete this content?')"
  >
    <template #content>
      <div class="pb4">
        <p class="mv3 f-body">
          <template v-if="isCDSGame">{{ $t('This content will be removed from your Library, uninstalled from your computers and its license(s) will be terminated.') }}</template>
          <template v-else-if="isLicensedGame">{{ $t('Its license(s) will be terminated and it will be removed from your Library. Because it is not a SpringboardVR title, if you want it uninstalled from your station’s computer, you need to uninstall it manually.') }}</template>
          <template v-else>{{ $t('Because it is not a SpringboardVR title, if you want it uninstalled from your station’s computer, you need to uninstall it manually.') }}</template>
        </p>
        <p class="orange fill-error f-instructions" v-if="isLicensedGame">
          <UIIcon name="alert-triangle-fill" class="mr1 v-mid" style="width:1.5rem" />
          {{ $t('Any unused monthly or yearly licenses will be lost.') }}
        </p>
        <p class="orange fill-error f-instructions" v-if="isBundledGame">
          <UIIcon name="alert-triangle-fill" class="mr1 v-mid" style="width:1.5rem" />
          {{ $t('The bundle license(s) containing this content will be removed.') }}
        </p>
      </div>
    </template>
  </Confirm>
</template>

<script>
import Confirm from '@/core/shared/components/Confirm';
import UIIcon from '@/core/shared/components/ui/UIIcon';

export default {
  props: {
    isCDSGame: { required: true },
    isLicensedGame: { required: true },
    isBundledGame: { required: true },
  },
  components: {
    Confirm,
    UIIcon,
  },
  methods: {
    close () {
      this.$emit('closeConfirm');
    },
  },
  computed: {
    buttons () {
      return [
        {
          name: this.$t('Cancel'),
          type: 'cancel',
        },
        {
          name: this.$t('Delete Content'),
          action: () => {
            this.$emit('confirm');
            return Promise.resolve();
          },
        },
      ];
    },
  },
};
</script>

<style scoped lang="postcss">
@import "core/shared/styles";

.confirm {
}
</style>
