<template>
  <div class="content-new-route" v-if="localGame">
    <ValidationObserver v-slot="{ pristine }" ref="validationObserver">
      <BreadcrumbNavConnected class="mb4" />
      <ContentBlock>
        <template #instructions>
          <h5>{{ $t('Content Basics') }}</h5>
          <p>{{ $t('Basic information for this title.') }}</p>
        </template>
        <GameBasicsForm :game="localGame" :disable-title="!!preset" @edit="editGame" />
      </ContentBlock>
      <ContentBlock>
        <template #instructions>
          <h5>{{ $t('Content Category') }}</h5>
          <p>{{ $t('This information will be visible in the Launcher to your customers and help categorize your content.') }}</p>
        </template>
        <GameDetailsForm :game="localGame" :contentOwnership="preset" @edit="editGame" />
      </ContentBlock>
      <ContentBlock :required="true">
        <template #instructions>
          <h5>{{ $t('Launch Settings') }}</h5>
          <p
            class="mt3"
          >{{ $t('Customize how our desktop software will launch this content by either specifying a Universal Resource Indicator or a file path.') }}</p>
          <p
            class="mt3"
          >{{ $t('A Universal Resource Indicator is for applications like Steam where you can provide a unique URL that will trigger the game to launch.') }}</p>
          <p
            class="mt3"
          >{{ $t('Path-based launch lets you specify the exact file path to the content you wish to launch.') }}</p>
          <i18n tag="p" class="mt3" path="Read more about {link}.">
            <template #link>
              <UIButton
                link
                href="https://support.springboardvr.com/hc/en-us/articles/360019594554"
                target="_blank"
                rel="noopener"
              >{{ $t('Launch Settings') }}</UIButton>
            </template>
          </i18n>
        </template>
        <LaunchSettingsForm :game="localGame" :required="true" @edit="editGame" />
      </ContentBlock>
      <BottomBar
        :saving="isSaving"
        :valid="(!preset ? !pristine : true) && isValid"
        :actionText="$t('Create Entry')"
        :backRouterLink="{ name: 'content-library' }"
        @save="onSave"
      />
    </ValidationObserver>
  </div>
</template>

<script>
import Component, { mixins } from 'vue-class-component';
import FocusModeMixin from '@/core/shared/misc/FocusModeMixin';
import ContentBlock from '@/core/shared/components/ContentBlock';
import UIFormItem from '@/core/shared/components/ui/UIFormItem';
import UIButton from '@/core/shared/components/ui/UIButton';
import BreadcrumbNavConnected from '@/core/shared/components/_connected/BreadcrumbNavConnected';
import BottomBar from '@/core/shared/components/BottomBar';
import LaunchSettingsForm from '@/content/shared/LaunchSettingsForm';
import GameDetailsForm from '@/content/shared/GameDetailsForm';
import GameBasicsForm from '@/content/shared/GameBasicsForm';
import { ValidationObserver } from 'vee-validate';

@Component({
  components: {
    ContentBlock,
    UIFormItem,
    UIButton,
    BottomBar,
    BreadcrumbNavConnected,
    LaunchSettingsForm,
    GameDetailsForm,
    GameBasicsForm,
    ValidationObserver,
  },
  beforeRouteEnter (from, to, next) {
    next(vm => {
      if (!vm.$store.state.contentNew.newGame.licensingSource) vm.$router.push({ name: 'content-add' });
    });
  },
  data: () => {
    return {
      isValid: false,
    };
  },
})
export default class ContentNewRoute extends mixins(FocusModeMixin) {
  isSaving = false
  get localGame () {
    const game = { ...(this.preset || {}), ...this.$store.state.contentNew.newGame };
    return new Proxy(game, {
      get (obj, k) {
        return obj[k];
      },
      set: (obj, k, val) => {
        this.editGame(k, val);
        return true;
      },
    });
  }

  get preset () {
    return this.$store.state.contentNew.presetId && this.$store.getters['entities/Game'](this.$store.state.contentNew.presetId);
  }

  async onSave () {
    try {
      this.isSaving = true;
      const { id, title } = await this.$store.dispatch('contentNew/createGame');
      const { href } = this.$router.resolve({ name: 'content-library-show', params: { gameId: id } });
      const sucessMsg = this.$t('{title} was successfully added to your Library. Don’t forget to {enable}.', {
        title,
        enable: `<a class="link-style" href="${href}">enable it on your stations</a>`,
      });
      this.$store.commit('setFlash', {
        type: 'success',
        message: sucessMsg,
      });
      this.$router.push({ name: 'content-library' });
    } catch (e) {
      const failMsg = this.$t('There was an error adding the content to your Library, please try again.');
      this.$store.commit('setFlash', {
        type: 'error',
        message: failMsg,
      });
      throw e;
    } finally {
      this.isSaving = false;
    }
  }

  async editGame (k, val) {
    // NOTE(Jack): Silently validating on the observer here because it doesn't seem to properly pick up on file upload changes
    this.isValid = await this.$refs.validationObserver.validate({ silent: true });
    this.$store.commit('contentNew/updateGame', { [k]: val });
  }

  get title () {
    return this.preset ? this.$t('Content Preset') : this.$t('Custom Content Entry');
  }

  created () {
    this.$store.commit('setTitle', this.title);
    this.setBreadcrumb();
  }

  async mounted () {
    this.isValid = await this.$refs.validationObserver.validate({ silent: true });
  }

  setBreadcrumb () {
    this.$store.commit('setBreadcrumbItems', [
      {
        name: this.$t('My Library'),
        routerLink: { name: 'content-library' },
      },
      {
        name: this.title,
      },
    ]);
  }
}
</script>

<style scoped lang="postcss">
@import "core/shared/styles";

.content-new-route {
  @apply --container;
  padding-top: var(--spacingSm);
  @media (--desktop) {
    padding-top: var(--spacingMd);
  }
}
</style>
