<template>
  <header
    class="header-games-show"
    :style="{ '--localBackgroundImg': `url('${content.imageSvgUrl || content.imageUrl}')` }"
  >
    <div class="container f-body">
      <div class="subheader items-center">
        <BreadcrumbNavConnected class="breadcrumb white mr-auto" />
        <UIButton
          class="communitycta f-tinycta"
          v-if="hasMarketplaceAccess && ($permissions['content-only'] ? content.contentGuideUrl : (content.contentGuideUrl || content.communityUrl))"
          :href="$permissions['content-only'] ? content.contentGuideUrl : (content.contentGuideUrl || content.communityUrl)"
          target="_blank"
          rel="noopener"
          tiny
          white
          icon="external-link"
        >{{ content.contentGuideUrl ? $t('View Content Guide') : $t('More Info on SpringboardVR Community') }}</UIButton>
        <span class="deletebtnslot" v-if="$scopedSlots.deletebtn">
          <slot name="deletebtn"></slot>
        </span>
      </div>
      <ContentBlock class="contentblock">
        <template #instructions>
          <OwnershipCard
            :usage="usage"
            :details="true"
            class="headercontentcard"
            :content="content"
          />
        </template>
        <template #additional>
          <div class="headerinfo">
            <div class="flex">
              <h2 class="contenttitle f-title white">{{ content.title }}</h2>
              <div class="ml-auto" v-if="ownership && ownership.rating.count">
                <p class="f-body b b white v-mid">{{ ownership.rating.text }}</p>
                <p
                  class="f-body white nowrap"
                  v-if="ownership.rating.count > 100"
                >({{ $t('{percentage} on {count} reviews', { percentage: ownership.rating.percentage, count: ownership.rating.count }) }})</p>
              </div>
            </div>
            <!-- bring that back later -->
            <!-- <div class="techicons pv3 mt4" style="font-size: 1.2rem">
              <UIIcon
                class="techlogo flex-shrink-0 fill-white dib v-mid mr5"
                name="vive"
                v-if="content.headsetHtcVive"
                viewBox="0 0 1576.2 340.9"
                style="width: 10em"
              ></UIIcon>
              <UIIcon
                class="techlogo flex-shrink-0 fill-white dib v-mid mr5"
                name="rift"
                v-if="content.headsetOculusRift"
                viewBox="0 0 1576.2 340.9"
                style="width: 10em"
              ></UIIcon>
              <UIIcon
                class="techlogo flex-shrink-0 fill-white dib v-mid mr5"
                name="wmr"
                v-if="content.headsetWindowsMr"
                viewBox="0 0 2500 2500"
                style="width: 2.75em"
              ></UIIcon>
            </div>-->
            <hr class="separator w-100 mv5" />
            <dl class="headerdl white">
              <!-- bring that back later -->
              <!-- <div class="techcaption">
                <dd class="mb2">{{ $t('Headset Type') }}</dd>
                <dt class="f-header">
                  <p v-if="content.headsetHtcVive">{{ $t('Vive') }}</p>
                  <p v-if="content.headsetOculusRift">{{ $t('Oculus Rift') }}</p>
                  <p v-if="content.headsetWindowsMr">{{ $t('WMR') }}</p>
                </dt>
              </div>-->
              <div
                class="experiencetype"
                v-if="ownership && ownership.displayExperienceType !== undefined"
              >
                <dd class="mb2">{{ $t('Experience Type') }}</dd>
                <dt class="f-header ttc">{{ ownership.displayExperienceType }}</dt>
              </div>
              <div class="agerating">
                <dd class="mb2">{{ $t('Age Rating') }}</dd>
                <dt class="f-header">{{ content.displayAgeRating }}</dt>
              </div>
              <div class="lbeversion" v-if="ownership && ownership.lbeVersion !== undefined">
                <dd class="mb2">{{ $t('Arcade Version') }}</dd>
                <dt class="f-header">{{ content.lbeVersion ? $t('Yes') : $t('No') }}</dt>
              </div>
            </dl>
          </div>
        </template>
      </ContentBlock>
    </div>
  </header>
</template>

<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import OwnershipCard from '@/content/shared/OwnershipCard';
import BreadcrumbNavConnected from '@/core/shared/components/_connected/BreadcrumbNavConnected';
import BottomBar from '@/core/shared/components/BottomBar';
import ContentBlock from '@/core/shared/components/ContentBlock';
import UIIcon from '@/core/shared/components/ui/UIIcon';
import UIButton from '@/core/shared/components/ui/UIButton';
import { computed } from '@vue/composition-api';

@Component({
  components: {
    OwnershipCard,
    ContentBlock,
    BreadcrumbNavConnected,
    BottomBar,
    UIIcon,
    UIButton,
  },
  props: {
    usage: { required: true },
    content: {
      required: true,
      validator (val) {
        return val.__typename === 'ContentOwnership' || val.__typename === 'Game';
      },
    },
  },
  setup (props) {
    const ownership = computed(() => {
      return props.content.__typename === 'ContentOwnership' ? props.content : props.content.ownership;
    });
    return {
      ownership,
    };
  },
})
export default class HeaderGameShow extends Vue {
  get hasMarketplaceAccess () {
    return this.$store.getters.currentOrganization.hasMarketplaceAccess;
  }
}
</script>

<style scoped lang="postcss">
@import "core/shared/styles";
.header-games-show {
  position: relative;
  overflow: hidden;
  &:before {
    background-color: rgba(0, 0, 0, 0.6);
    background-image: var(--localBackgroundImg);
    filter: blur(16px);
    background-blend-mode: multiply;
    background-position: center;
    background-size: cover;
    content: "";
    position: absolute;
    top: -25px;
    left: -25px;
    right: -25px;
    bottom: -25px;
    z-index: -1;
  }
  padding-top: var(--spacingSm);
  @media (--tablet) {
    padding-bottom: var(--spacingLg);
    padding-top: var(--spacingLg);
    padding-right: var(--spacingXL);
  }
  & .contentblock {
    margin-bottom: 4rem;
    @media (--tablet) {
      display: flex;
      align-items: stretch;
      margin-bottom: var(--spacingSm);
      & >>> .instructions {
        flex: 0 0 35rem;
        margin-right: var(--spacingMd);
      }
      & >>> .content {
        flex: 1 1 auto;
      }
    }
  }
  & .contenttitle {
    font-size: 4.6rem;
    line-height: 4.8rem;
  }
  & .contenttitle,
  & .separator {
    display: none;
    @media (--tablet) {
      display: block;
    }
  }

  & .techicons {
    display: none;
    @media (--tablet) {
      display: initial;
    }
  }
  & .techcaption {
    display: initial;
    @media (--tablet) {
      display: none;
    }
  }
  & .subheader {
    margin-bottom: var(--spacingXS);
    width: 100%;

    & .deletebtnslot {
      display: block;
      margin-top: var(--spacingSm);
      width: 100%;
      text-align: right;
    }

    @media (--tablet) {
      display: flex;

      & .deletebtnslot {
        display: span;
        margin-top: 0;
        width: auto;
        text-align: left;
      }
    }
  }
  & .headercontentcard {
    cursor: default;
    margin: var(--spacingMd) 0 !important;
    @media (--tablet) {
      margin: 0 !important;
    }
  }
  & .headerinfo {
    display: flex;
    @media (--tablet) {
      padding-left: var(--spacingLg);
    }
    @media (--desktop) {
      padding-left: var(--spacingXL);
    }
    justify-content: center;
    height: 100%;
    flex-direction: column;
  }
  & .headerdl {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    @media (--tablet) {
      grid-template-columns: repeat(3, 1fr);
    }
    grid-gap: var(--spacingMd);
  }
}
</style>
