import Vue from 'vue';

export const clearOverrides = (state) => {
  state.stationGamesOverrides = {};
};

export const updateStationGame = (state, stationGame) => {
  const {
    id,
    _id,
    enabled,
    _uninstall,
    _originalValue,
    station,
    game,
  } = stationGame;
  const cId = id || _id;
  if (cId in state.stationGamesOverrides && _originalValue === enabled) {
    // here we want to reset the local override for stationGames if their original value is unchanged
    // because we don't want to create some new stationGames with enabled: false for "nothing"
    Vue.delete(state.stationGamesOverrides, cId);
  } else {
    Vue.set(state.stationGamesOverrides, cId, {
      id,
      _id,
      enabled,
      _uninstall,
      station: { id: station.id },
      game: { id: game.id },
    });
  }
};
