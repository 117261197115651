<template>
  <div class="game-details-form f-body">
    <UISelect
      class="ageratingselect"
      :title="$t('Age Rating')"
      :required="true"
      :placeholder="$t('Select')"
      :instructions="contentOwnership && contentOwnership.ageRating !== null && $t('Originally set by the content creator as {value}.',  { value: contentOwnership.displayAgeRating })"
      v-model="localGame.ageRating"
      rules="required|dropdown"
      :name="$t('Age Rating')"
    >
      <option
        v-for="({ text, value }) in ageRatingOptionFormatted"
        :key="value"
        :value="value"
      >{{ text }}</option>
    </UISelect>
    <UISelect
      class="difficultyselect"
      v-model="localGame.difficulty"
      :title="$t('Difficulty')"
      :placeholder="$t('Select')"
      :instructions="contentOwnership && $t('Originally set by the content creator as {value}.',  { value: contentOwnership.difficultyDisplay })"
      :name="$t('Difficulty')"
      required
      rules="required|dropdown"
    >
      <option value="1">{{ $t('Easy') }}</option>
      <option value="2">{{ $t('Medium') }}</option>
      <option value="3">{{ $t('Hard') }}</option>
    </UISelect>
    <TagMultiselect
      class="selecttags"
      v-model="localGame.organizationTags"
      :title="$t('Tags')"
      name="tags"
    >
      <template #instructions>
        <i18n path="Tags are visible in the Launcher. Go to the {link} to create new tags.">
          <template #link>
            <UIButton link :to="{ name: 'tags' }">{{ $t('Tags page') }}</UIButton>
          </template>
        </i18n>
      </template>
    </TagMultiselect>
    <UIFormItem class="playermode fullrow" :title="$t('Number of Players')" :root="true">
      <div class="flex items-center" style="height: 48px;">
        <UICheckbox
          class="checkboxsingleplayer"
          v-model="localGame.singleplayer"
          :title="$t('Single Player')"
          :name="$t('Single Player')"
        />
        <UICheckbox
          class="checkboxsingleplayer ml4"
          v-model="localGame.multiplayer"
          :title="$t('Multiplayer')"
          :name="$t('Multiplayer')"
        />
      </div>
      <template
        #instructions
        v-if="contentOwnership && (contentOwnership.singleplayer || contentOwnership.multiplayer)"
      >
        <i18n path="Originally set by the content creator as {value}.">
          <!--prettyhtml-preserve-whitespace-->
          <template #value>
          <template v-if="contentOwnership.singleplayer">{{ $t('Single player') }}</template>
            <template v-if="contentOwnership.singleplayer && contentOwnership.multiplayer">,&nbsp;</template>
            <template v-if="contentOwnership.multiplayer">
              {{ $t('Multiplayer') }}
              <template v-if="contentOwnership.minPlayers && contentOwnership.maxPlayers && contentOwnership.minPlayers !== contentOwnership.maxPlayers">
                &nbsp;
                <i18n path="({min} to {max})">
                  <template #min>{{ contentOwnership.minPlayers }}</template>
                  <template #max>{{ contentOwnership.maxPlayers }}</template>
                </i18n>
              </template>
            </template>
          </template>
        </i18n>
      </template>
    </UIFormItem>
    <slot />
  </div>
</template>

<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import UIInput from '@/core/shared/components/ui/UIInput';
import UIButton from '@/core/shared/components/ui/UIButton';
import UISelect from '@/core/shared/components/ui/UISelect';
import UIMultiselect from '@/core/shared/components/ui/UIMultiselect';
import UICheckbox from '@/core/shared/components/ui/UICheckbox';
import UIFormItem from '@/core/shared/components/ui/UIFormItem';
import { formatAgeRating } from '@/core/helpers/FormatHelpers';

const TagMultiselect = {
  props: UIMultiselect.options.props,
  created () {
    this.$store.dispatch('fetchAllTags');
  },
  render (h) {
    return h(UIMultiselect, {
      scopedSlots: this.$scopedSlots,
      model: this.$vnode.data.model,
      props: {
        ...this.$props,
      },
      attrs: {
        options: this.$store.getters.allTagsSortedByAlpha,
        multiple: true,
        trackBy: 'id',
        label: 'text',
        ...this.$attrs,
      },
    });
  },
};
@Component({
  components: {
    UIInput,
    UIButton,
    UISelect,
    TagMultiselect,
    UICheckbox,
    UIFormItem,
  },
  props: {
    game: { required: true },
    contentOwnership: { },
  },
})
export default class GameDetailsForm extends Vue {
  get localGame () {
    return new Proxy(this.game, {
      get (obj, k) {
        return obj[k];
      },
      set: (obj, k, val) => {
        this.$emit('edit', k, val);
        return true;
      },
    });
  }

  get ageRatingOptionFormatted () {
    const options = Array(22).fill(0).map((v, i) => ({
      value: i,
      text: formatAgeRating(i),
    }));
    return options;
  }
}
</script>

<style scoped lang="postcss">
@import "core/shared/styles";

.game-details-form {
  display: grid;
  row-gap: var(--spacingLg);
  column-gap: var(--spacingSm);
  grid-template-columns: 1fr;
  @media (--tablet) {
    grid-template-columns: 1fr 1fr;
  }
  & .fullrow {
    grid-column: 1 / -1;
  }
}
</style>
