<template>
  <div class="tag-titles">
    <template v-if="games.length">
      <div class="searchandfilter">
        <UIInput :placeholder="$t('Search for a Title')" type="search" v-model="search" />
        <UISelect class="filter" v-if="tagId !== 'new'" v-model="filter" :title="$t('Show all')">
          <option value="all">{{ $t('Titles in My Library') }}</option>
          <option value="with">{{ $t('Titles with this Tag') }}</option>
          <option value="without">{{ $t('Titles without this Tag') }}</option>
        </UISelect>
      </div>

      <div class="searchtable">
        <div class="tableheader f6 storm">
          <div class="mobile">
            {{ $t('Enable Tag on Title') }}
            <br />
            {{ $t('Also Tagged with') }}
          </div>
          <div class="tablet">{{ $t('Enable Tag on Title') }}</div>
          <div class="tablet">{{ $t('Also Tagged with') }}</div>
          <div class="tr">{{ $t('Edit Title') }}</div>
        </div>
        <template v-if="gameResults.length > 0">
          <div class="tablesubheader">
            <UISwitch
              class="selectall"
              :title="$t('Select All')"
              :value="isSelectAllOn(gameResults)"
              @change="toggleAllSelected(gameResults, !isSelectAllOn(gameResults))"
            />
          </div>
          <div class="gameresult" v-for="game in gameResults" :key="game.id">
            <UISwitch
              class="mobile"
              :title="game.title"
              :instructions="getGameTags(game)"
              :value="isGameSelected(game)"
              @change="toggleTagOnGame(game)"
            />
            <UISwitch
              class="tablet"
              :title="game.title"
              :value="isGameSelected(game)"
              @change="toggleTagOnGame(game)"
            />
            <p class="f6 tablet">{{ getGameTags(game) }}</p>
            <UIButton
              class="tr editcontent"
              tiny
              icon="edit"
              target="_blank"
              :to="{ name: 'content-library-show', params: { gameId: game.id } }"
            />
          </div>
        </template>

        <div class="empty noresults f-body tc center fill-geyser fadeIn animated" v-else>
          <UIIcon name="no-results" class="dib mv5" style="width:8rem;" />
          <p>{{ $t("We couldn't find any titles matching your search.") }}</p>
        </div>
      </div>
    </template>

    <div class="empty nogames f-body tc center fill-geyser fadeIn animated" v-else>
      <UIIcon name="no-results" class="dib mv5" style="width:8rem;" />
      <p v-if="$permissions['education'] || $permissions['enterprise-operator']">{{ $t('Your Library is empty.') }}</p>
      <i18n
        v-else
        tag="p"
        path="Your Library is empty. Go to the {marketplaceLink} to add content to your Library."
      >
        <template #marketplaceLink>
          <router-link :to="{ name: 'marketplace' }" class="link-style">{{ $t('Marketplace') }}</router-link>
        </template>
      </i18n>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import Component from 'vue-class-component';

import UIInput from '@/core/shared/components/ui/UIInput';
import UISelect from '@/core/shared/components/ui/UISelect';
import UIIcon from '@/core/shared/components/ui/UIIcon';
import UISwitch from '@/core/shared/components/ui/UISwitch';
import UIButton from '@/core/shared/components/ui/UIButton';

@Component({
  components: {
    UIInput,
    UISelect,
    UIIcon,
    UISwitch,
    UIButton,
  },
  props: {
    tag: {
      type: Object,
      required: true,
    },
    tagId: {
      type: String,
      required: true,
    },
    games: {
      type: Array,
      required: true,
    },
    filteredGameIds: {
      type: Array,
    },
  },
  watch: {
    search: {
      handler: 'onSearchOrFilterChanged',
      immediate: true,
      deep: true,
    },
    filter: {
      handler: 'onSearchOrFilterChanged',
      immediate: true,
      deep: true,
    },
  },
})
export default class TagTitles extends Vue {
  search = '';
  filter = 'all';

  onSearchOrFilterChanged () {
    this.$emit('tagGameSearch', this.search, this.filter);
  }

  get gameResults () {
    if (this.filteredGameIds) {
      return this.games.filter(game => ~this.filteredGameIds.indexOf(game.id));
    } else {
      return this.games;
    }
  }

  get localTag () {
    return new Proxy(this.tag, {
      set: (target, property, value) => {
        this.$emit('edit', property, value);
        return true;
      },
    });
  }

  isSelectAllOn (games) {
    return games.every(game => {
      return !!this.localTag.games.find(tagGame => tagGame.id === game.id);
    });
  }

  getGameTags (game) {
    return game.organizationTags.filter(tag => {
      return tag && tag.text && tag.text !== this.localTag.text;
    }).map(tag => tag.text).sort().join(', ');
  }

  isGameSelected (game) {
    return ~this.getGameIndexInTag(game);
  }

  getGameIndexInTag (game) {
    return this.tag.games.findIndex(tagGame => {
      return tagGame.id === game.id;
    });
  }

  toggleAllSelected (games, enable) {
    let newGames = [...this.localTag.games].map(game => {
      return { id: game.id };
    });

    games.forEach(game => {
      const gameIndex = newGames.findIndex(tagGame => {
        return tagGame.id === game.id;
      });

      if (enable && gameIndex === -1) {
        newGames = [
          ...newGames,
          { id: game.id },
        ];
      }

      if (!enable) {
        newGames = [
          ...newGames.slice(0, gameIndex),
          ...newGames.slice(gameIndex + 1),
        ];
      }
    });

    this.localTag.games = newGames;
  }

  toggleTagOnGame (game) {
    const gameIndex = this.getGameIndexInTag(game);
    if (~gameIndex) {
      this.localTag.games = [
        ...this.tag.games.slice(0, gameIndex),
        ...this.tag.games.slice(gameIndex + 1),
      ];
    } else {
      this.localTag.games = [
        ...this.localTag.games,
        { id: game.id },
      ];
    }
  }
}
</script>

<style lang="postcss" scoped>
@import "core/shared/styles";

.tag-titles {
  display: grid;
  grid-gap: var(--spacingMd);

  & .searchandfilter {
    display: grid;
    grid-gap: var(--spacingMd);

    @media (--tablet) {
      grid-template-columns: 1fr 1fr;
      align-items: end;
    }
  }

  & .searchtable {
    display: grid;
    grid-gap: var(--spacingMd);

    & .tableheader,
    & .gameresult {
      display: grid;
      grid-template-columns: 1fr 5rem;
      align-items: center;

      & .tablet {
        display: none;
      }

      @media (--tablet) {
        grid-gap: var(--spacingMd);
        grid-template-columns: 1fr 1fr 5rem;

        & .tablet {
          display: flex;
        }
      }
    }

    & .tableheader {
      padding-bottom: var(--spacingSm);
      border-bottom: 0.1rem solid var(--colorStorm);
    }
  }
}
</style>
