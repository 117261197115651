<template>
  <div class="launch-settings-form f-body">
    <template v-if="!isCDS">
      <UISelect
        class="launchmethodselect"
        v-model="localGame.launchMethod"
        :title="$t('Launch Method')"
        :placeholder="$t('Select a Launch Method')"
        :required="required"
        @change="resetFields"
        rules="required|dropdown"
      >
        <option value="path">{{ $t('Path') }}</option>
        <option value="uri">{{ $t('Uniform Resource Identifier (URI)') }}</option>
      </UISelect>
      <UIInput
        v-if="localGame.launchMethod === 'uri'"
        v-model="localGame.uri"
        :title="$t('URI')"
        :instructions="$t('For Steam title it looks like “steam://launch/512321/VR”')"
        required
        rules="required"
      />
      <UIInput
        v-if="localGame.launchMethod === 'path'"
        v-model="localGame.filePath"
        :title="$t('Path')"
        required
        rules="required"
      />
    </template>
    <UIInput
      v-if="isCDS || localGame.launchMethod === 'path'"
      v-model="localGame.arguments"
      :title="$t('Command Line Arguments')"
    />
  </div>
</template>

<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import UIInput from '@/core/shared/components/ui/UIInput';
import UISelect from '@/core/shared/components/ui/UISelect';

@Component({
  components: {
    UIInput,
    UISelect,
  },
  props: {
    game: { required: true },
    storedGame: { },
    required: { type: Boolean },
  },
})
export default class LaunchSettingsForm extends Vue {
  get isCDS () {
    return this.game.cds;
  }

  get localGame () {
    return new Proxy(this.game, {
      get (obj, k) {
        return obj[k];
      },
      set: (obj, k, val) => {
        this.$emit('edit', k, val);
        return true;
      },
    });
  }

  resetFields ({ target }) {
    if (target.value === 'uri') {
      this.localGame.filePath = null;
      if (this.storedGame) this.localGame.uri = this.storedGame.uri;
    } else if (target.value === 'path') {
      this.localGame.uri = null;
      if (this.storedGame) this.localGame.filePath = this.storedGame.filePath;
    }
  }
}
</script>

<style scoped lang="postcss">
@import "core/shared/styles";

.launch-settings-form {
  display: grid;
  row-gap: var(--spacingLg);
  grid-template-columns: 1fr;
  @media (--tablet) {
    grid-template-columns: 1fr 1fr;
  }
  column-gap: var(--spacingSm);
  & .launchmethodselect + * {
    grid-column-start: 1;
  }
}
</style>
