<template>
  <div class="eula-status" :class="{'-disabled': disabled}">
    <UIButton @click="$emit('preview', agreement)" link :disabled="disabled" class="f-body">
      <span>
        <slot>{{ $t('View') }}</slot>
      </span>
      <span v-if="agreement.endsAt">&nbsp;(expires {{expirationDateFormatted}})</span>
    </UIButton>
    <p class="licenseagreementstatus f-instructions mt2">
      <span v-if="pending || disabled" class="orange fill-error">
        <span>{{ $t('Status: Pending Approval') }}</span>
      </span>
      <span v-else-if="accepted">{{ $t('Status: Accepted') }}</span>
    </p>
  </div>
</template>

<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import UIButton from '@/core/shared/components/ui/UIButton';
import UIIcon from '@/core/shared/components/ui/UIIcon';
import moment from 'moment';

@Component({
  components: {
    UIButton,
    UIIcon,
  },
  props: {
    agreement: { required: true },
    status: { type: String },
  },
})
export default class EULAStatus extends Vue {
  get expirationDateFormatted () {
    return this.agreement.endsAt && moment(this.agreement.endsAt).format('LL');
  }

  get pending () {
    return this.status === 'pending';
  }

  get accepted () {
    return this.status === 'accepted';
  }

  get disabled () {
    return this.status === 'disabled';
  }
}
</script>

<style scoped lang="postcss">
@import "core/shared/styles";
.eula-status {
  &.-disabled {
    opacity: 0.5;
    cursor: not-allowed;
    & button {
      cursor: not-allowed;
    }
  }
}
</style>
