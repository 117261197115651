<template>
  <tr class="library-item-tr f-thead">
    <td class="draghandle -disabled">
      <UIIcon class="w2 fill-inherit" name="menu"/>
    </td>
    <td class="imagecell dn dtc-m" style="max-width: 150px; min-width: 100px; width: 10%;">
      <RatioContainer class="ratiocontainer" :ratio="46.5863">
        <div class="image bg-black-10 pa2 mb3 w-100 h-100">&nbsp;</div>
      </RatioContainer>
    </td>
    <td colspan="10">
      <h1 class="title bg-near-white pa2 mb3 w-30">&nbsp;</h1>
    </td>
  </tr>
</template>

<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import UIButton from '@/core/shared/components/ui/UIButton';
import UIIcon from '@/core/shared/components/ui/UIIcon';
import RatioContainer from '@/core/shared/components/utils/RatioContainer';

@Component({
  components: {
    UIButton,
    UIIcon,
    RatioContainer,
  },
})
export default class LibraryItemTrPlaceholder extends Vue {
}
</script>

<style scoped lang="postcss">
@import "core/shared/styles";
.library-item-tr {
  --borderWidth: 6px;
  @media (--tablet) {
    --borderWidth: 10px;
  }
  & .w-1 {
    width: 1px;
  }
  & >>> .w2 {
    width: 2em;
  }
  & .currentmonthcost {
    white-space: nowrap;
  }
  & .colorindicator {
    &.-increase {
      background: var(--colorError);
    }
    &.-decrease {
      background: var(--colorSuccess);
    }
    position: absolute;
    top: 0;
    left: calc(-1 * var(--borderWidth));
    bottom: 0;
    border-top-left-radius: var(--radius);
    border-bottom-left-radius: var(--radius);
    width: 6px;
    @media (--tablet) {
      width: 10px;
    }
  }
  & .pricechangenotification {
    &.-increase {
      background-image: var(--colorError);
    }
    &.-decrease {
      background-image: var(--colorSuccess);
    }
    background-clip: text;
    color: transparent;
  }
  border-collapse: separate;
  border-spacing: var(--borderWidth);
  border: 0;
  & td,
  & th {
    vertical-align: middle;
    padding: 12px 2px;
    @media (--tablet) {
      padding: 8px 5px;
    }
    position: relative;
  }
  & td {
    &:before {
      content: "";
      top: 0;
      left: calc(-1 * var(--borderWidth));
      width: calc(100% + 1px + var(--borderWidth));
      height: 100%;
      position: absolute;
      @apply --panelDropShadow;
      z-index: -2;
      background-color: var(--colorWhite);
    }
    &:after {
      content: "";
      top: 0;
      left: calc(-1 * var(--borderWidth));
      width: calc(100% + 1px + var(--borderWidth));
      height: 100%;
      position: absolute;
      z-index: -1;
      background-color: var(--colorWhite);
    }
    &:first-child:after,
    &:first-child:before {
      border-top-left-radius: var(--radius);
      border-bottom-left-radius: var(--radius);
    }
    &:last-child:after,
    &:last-child:before {
      left: calc(-1 * var(--borderWidth));
      width: calc(100% + 2 * var(--borderWidth));
      border-top-right-radius: var(--radius);
      border-bottom-right-radius: var(--radius);
    }
  }
  & .editcell {
    padding: 0 var(--spacingXS);
    width: 1px;
    white-space: nowrap;
    @media (--tablet) {
      padding: 0 var(--spacingMd);
    }
  }
  & .draghandle {
    cursor: grab;
    position: relative;
    width: 42px;
    text-align: center;
    fill: var(--colorFjord);
    transition: fill 0.4s ease-in-out;
    &.-disabled {
      cursor: not-allowed;
      fill: var(--colorGeyser);
    }
  }
  & .titlecell {
    max-width: 13vw;
  }
  & .currentmonthusage {
    position: relative;
    &:after {
      content: "";
      z-index: 5;
      position: absolute;
      width: 100%;
      height: 140%;
      top: -20%;
      left: 0px;
      border: 1px solid var(--colorGeyser);
      border-width: 0 1px;
    }
  }
}
</style>
