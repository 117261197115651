<template>
  <div v-if="content" class="content-card relative">
    <div v-if="usage === 'marketplace' && ownership && ownership.badgeTop10" class="top10badgeshadow f-body b absolute left-0 mt4">
      <span class="top10badge fill-white tc dib bg-gradient-red white pa3 ph4">
        <svg
          viewBox="0 0 95 69"
          xmlns="http://www.w3.org/2000/svg"
          fill-rule="evenodd"
          clip-rule="evenodd"
          class="white w2 ml2 v-mid dib"
          stroke-linejoin="round"
          stroke-miterlimit="2"
        >
          <path
            d="M75.9056732 68.2818104H18.8912668L-.0005796 8.7259l31.7688 18.3411872L47.39847.0000696l15.6090704 27.0246592 31.7688-18.3411872-18.8706672 59.5982688zm-50.83008-8.47168h44.6457536L80.120334 26.9188328 59.894198 38.6097512 47.39847 16.9434296 34.902742 38.6097512 14.676606 26.9188328l10.3989872 32.8912976z"
            fill-rule="nonzero"
          />
        </svg>
        <span class="ml2 v-mid dib">{{ $t('Top 10') }}</span>
      </span>
    </div>
    <div class="badge absolute right-0 z-5 f-tiny white mt3 flex flex-column items-end" style="margin-top:1rem;">
      <span v-if="usage === 'marketplace' && game" class="librarybadge bg-green ph2 pv1 db mb2">{{ $t('In Library') }}</span>
      <span v-if="usage === 'marketplace' && ownership.bundle" class="bundlebadge bg-red ph2 pv1 db mb2">{{ $t('Bundle') }}</span>
    </div>
    <router-link tag="a" class="pb4 h-100 nostyle" :to="routerLink">
      <div class="image">
        <RatioContainer class="ratiocontainer" :ratio="46.5863">
          <LazyImg
            v-if="content.imageUrl"
            :src="content.imageUrl"
            :srcset="content.imageSrcSet"
            :placeholder="content.imageSvgUrl"
            :alt="content.title"
            class="posterimg db w-100 h-100 z-3"
          />
        </RatioContainer>
      </div>
      <span v-if="ownership && ownership.hasPriceChanges && usage === 'marketplace'" class="pricechangenotification f-instructions db v-mid pr2">
        <template v-if="content.percentageOff">
          <span
            v-if="content.percentageOff === '100%'"
          >{{ $t('Free until {date}', { date: prices.minute.discountDisplayEndDate}) }}</span>
          <span
            v-else
          >{{ $t('{percentage} Off until {date}', { percentage: content.percentageOff, date: prices.minute.discountDisplayEndDate }) }}</span>
        </template>
        <template v-else-if="details">
          <span class="db" v-for="(priceChange, type) in ownership.priceChanges" :key="type">
            <i18n v-if="priceChange.kind === 'increase'" path="{price} increases to {newprice} on {date}">
              <!--prettyhtml-preserve-whitespace-->
              <template #price><template v-if="type === 'minute'">{{ $t('Price Per Minute') }}</template>
              <template v-if="type === 'monthly'">{{ $t('Monthly Price') }}</template>
              <template v-if="type === 'yearly'">{{ $t('Yearly Price') }}</template></template>
              <template #date>{{priceChange.displayMinDateChange}}</template>
              <template #newprice>{{priceChange.newPriceDisplay}}</template>
            </i18n>
            <i18n v-else-if="priceChange.kind === 'decrease'" path="{price} decreases to {newprice} on {date}">
              <!--prettyhtml-preserve-whitespace-->
              <template #price><template v-if="type === 'minute'">{{ $t('Price Per Minute') }}</template>
              <template v-if="type === 'monthly'">{{ $t('Monthly Price') }}</template>
              <template v-if="type === 'yearly'">{{ $t('Yearly Price') }}</template></template>
              <template #date>{{priceChange.displayMinDateChange}}</template>
              <template #newprice>{{priceChange.newPriceDisplay}}</template>
            </i18n>
          </span>
        </template>
        <template v-else>
          <span>{{ $t('Price changes') }}</span>
        </template>
      </span>
      <div class="content relative mb3">
        <div class="basic tc">
          <h1 class="title f4 truncate">{{ content.title }}</h1>
          <h4
            class="nbstations f8 mt2"
            v-if="usage === 'library' && game"
            :style="{ opacity: 'stationsInstalled' in game ? 1 : 0 }"
          >{{ $tc('No Station Enabled | 1 Station Enabled | {n} Stations Enabled', game.stationsInstalled) }}</h4>
          <h4
            class="manuallyadded f-tiny mt2"
            v-if="game && !game.license"
          >{{ $t('Manually Added Content') }}</h4>
        </div>
      </div>
      <dl class="prices flex justify-around" v-if="ownership && usage === 'marketplace'">
        <div
          v-for="price in Object.values(prices).filter(({ available }) => available)"
          :key="price.class"
          class="tc"
          :class="price.class"
        >
          <dt class="f-tiny db">
            <template v-if="hasPriceRecentlyChanged(price) && !content.percentageOff">
              <span class="blue">{{ $t('New') }}</span> -
            </template>
            {{ price.title }}
          </dt>
          <dd
            class="f-header dib v-mid mr1 relative"
            :class="price.priceBeforeDiscount ? 'blue' : ''"
          >
            {{ price.displayPrice }}
            <span
              class="pricebeforediscount f-label absolute right-0 strikethrough"
              style="transform: translate(calc(100% + 5px));"
              v-if="price.priceBeforeDiscount"
            >{{ price.priceBeforeDiscount }}</span>
          </dd>
        </div>
      </dl>
      <div class="stats" v-if="game && game.license && usage === 'library'">
        <div class="stat statusage" v-if="game.license.upcomingInvoiceAmount && game.license.upcomingInvoiceAmount.displayValue">
          <div class="f-thead mb2">{{ $t("Upcoming Invoice") }}</div>
          <div class="value">
            <span
              class="f-header dib v-mid mr1"
            >{{ game.license.upcomingInvoiceAmount.displayValue }}</span>
            <small class="f-tiny slate dib v-mid">{{ $t('USD') }}</small>
          </div>
        </div>
        <div class="stat statcost" v-if="game.license.currentTotalUsage && game.license.currentTotalUsage.displayValue">
          <div class="f-thead mb2">{{ $t("Usage This Month") }}</div>
          <div class="value">
            <span class="f-header dib v-mid mr1">{{ game.license.currentTotalUsage.displayValue }}</span>
            <small class="f-tiny slate dib v-mid">{{ $t('min') }}</small>
          </div>
        </div>
      </div>
      <div class="warningscontainer mt3 tc">
        <UIButton
          warning
          class="pendingeulawarning warning tc p0 w-100"
          @click.stop="openEULAModal(ownership)"
          icon="alert-triangle-fill"
          v-if="ownership && game && ownership.operatorLicense && (ownership.operatorLicense.promptForCurrentLicense || ownership.operatorLicense.promptForUpcomingLicense)"
        >{{ $t('Pending EULA Approval') }}</UIButton>
        <template v-if="game">
          <UIButton
            warning
            class="installerrorwarning warning tc mt2 w-100"
            @click.stop="$router.push({ name: 'content-station-management', params: { gameId: game.id } })"
            icon="alert-triangle-fill"
            v-if="game.installErrorMessage"
          >{{ game.installErrorMessage }}</UIButton>
          <UIButton
            warning
            class="transitioncdswarning warning tc mt2 w-100"
            @click.stop="$router.push({ name: 'content-station-management', params: { gameId: game.id } })"
            icon="alert-triangle-fill"
            v-if="game.cdsTransitionError"
          >{{ $t('Available on Content Distribution System') }}</UIButton>
        </template>
        <h4
          class="regionalpricing f-tiny mt2"
          v-if="ownership && ownership.regionalPricing"
        >{{ $t('Regionally Priced') }}</h4>
      </div>
    </router-link>
  </div>
</template>

<script>
import Component, { mixins } from 'vue-class-component';
import UIButton from '@/core/shared/components/ui/UIButton';
import UIIcon from '@/core/shared/components/ui/UIIcon';
import RatioContainer from '@/core/shared/components/utils/RatioContainer';
import LazyImg from '@/core/shared/components/utils/LazyImg';
import EULAMixin from './EULAMixin';
import { get } from 'lodash-es';
import moment from 'moment';
import { hasPriceRecentlyChanged, isPriceGettingRemoved } from '@/core/helpers/FormatHelpers';

@Component({
  components: {
    UIButton,
    UIIcon,
    RatioContainer,
    LazyImg,
  },
  props: {
    usage: { required: true, type: String },
    details: { required: true, type: Boolean },
    content: {
      required: true,
      validator (val) {
        return val.__typename === 'ContentOwnership' || val.__typename === 'Game';
      },
    },
  },
  methods: {
    hasPriceRecentlyChanged,
  },
})
export default class OwnershipCard extends mixins(EULAMixin) {
  get game () {
    return this.content.__typename === 'Game' ? this.content : this.content.operatorGame;
  }

  get ownership () {
    return this.content.__typename === 'Game' ? this.content.ownership : this.content;
  }

  get prices () {
    return {
      minute: {
        class: 'perminuteprice',
        title: this.$t('Per Minute'),
        available: !!get(this.ownership, 'minutePrice.current') && !isPriceGettingRemoved(this.ownership.minutePrice),
        price: get(this.ownership, 'minutePrice.current.price', null),
        displayPrice: get(this.ownership, 'minutePrice.current.displayPrice', null),
        startsAt: get(this.ownership, 'minutePrice.current.startsAt', null),
        priceBeforeDiscount: this.ownership.percentageOff && get(this.ownership, 'minutePrice.upcoming.displayPrice', null),
        discountDisplayEndDate: get(this.ownership, 'minutePrice.current.endsAt', null) && moment(get(this.ownership, 'minutePrice.current.endsAt', null)).format('LL'),
      },
      monthly: {
        class: 'permonthprice',
        title: this.$t('Per Month'),
        available: !!get(this.ownership, 'monthlyPrice.current') && !isPriceGettingRemoved(this.ownership.monthlyPrice),
        price: get(this.ownership, 'monthlyPrice.current.price', null),
        displayPrice: get(this.ownership, 'monthlyPrice.current.displayPrice', null),
        startsAt: get(this.ownership, 'monthlyPrice.current.startsAt', null),
      },
      yearly: {
        class: 'peryearprice',
        title: this.$t('Per Year'),
        available: !!get(this.ownership, 'yearlyPrice.current') && !isPriceGettingRemoved(this.ownership.yearlyPrice),
        price: get(this.ownership, 'yearlyPrice.current.price', null),
        displayPrice: get(this.ownership, 'yearlyPrice.current.displayPrice', null),
        startsAt: get(this.ownership, 'yearlyPrice.current.startsAt', null),
      },
    };
  }

  get routerLink () {
    if (this.$route.name === 'marketplace') {
      return this.content.bundle?.id
        ? { name: 'marketplace-bundle-show', params: { bundleId: this.content.bundle.id } }
        : { name: 'marketplace-content-show', params: { contentOwnershipId: this.content.id } };
    }
    return {};
  }
}
</script>

<style scoped lang="postcss">
@import "core/shared/styles";

.content-card {
  @apply --panelDropShadow;
  @apply --f5;

  overflow: hidden;
  border-radius: var(--radius);
  background-color: var(--colorWhite);
  display: flex;
  flex-direction: column;
  cursor: pointer;

  & .top10badge {
    line-height: 0;
    clip-path: polygon(
      100% 0,
      calc(100% - 8px) 50%,
      100% 100%,
      0% 100%,
      0 50%,
      0% 0%
    );
  }
  & .top10badgeshadow {
    left: -10px;
    filter: drop-shadow(4px 2px 4px rgba(50, 50, 0, 0.5));
    z-index: 500;
  }
  & .pricebeforediscount {
    top: 0;
    line-height: 1;
    &:after {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      content: "";
      background-size: 100% auto;
      background-position: 50%;
      background-repeat: no-repeat;
      background-image: url('data:image/svg+xml; utf8, <svg viewBox="0 0 100% 100%" preserveRatio="none" xmlns="http://www.w3.org/2000/svg"><line x1="0" y1="40%" x2="100%" y2="60%" style="stroke:rgb(0,0,0);stroke-width:1"/></svg>');
    }
  }

  & >>> .ratiocontainer {
    overflow: hidden;
  }
  & .posterimg {
    background: var(--colorPlaceholderGradient);
  }
  & .librarybadge {
    box-shadow: 0 0 0.4rem 0 rgba(0, 0, 0, 0.25);
  }
  & .bundlebadge {
    box-shadow: 0 0 0.4rem 0 rgba(0, 0, 0, 0.25);
    z-index: 500;
  }
  & .pricechangenotification {
    color: var(--colorWhite);
    padding: var(--spacingXS);
    background: var(--colorInfo);
    text-align: center;
  }
  & .image {
    @apply --f6;

    position: relative;
    border-radius: var(--radius) var(--radius) 0 0;
    width: calc(
      100% + 2px
    ); /* workaround rasterization inconsistency working with percentages */
    left: -1px; /* workaround rasterization inconsistency working with percentages */
    height: auto;
    text-align: center;
    margin: 0;
  }
  & .title {
    padding-top: var(--spacingSm);
  }
  & .warningscontainer {
    padding: 0 var(--spacingSm);
  }
  & .content {
    flex: 1 1 auto;
    display: flex;
    align-items: flex-start;

    /* padding: var(--spacingXS); */
    /* @media (--tablet) { */
    padding: 0 var(--spacingSm);
    /* } */

    & .basic {
      flex: 1 1 auto;
      width: auto;
      min-width: 0;
    }

    & .price {
      flex: 0 0 auto;
      border-radius: var(--radius);
      padding: 1rem 1.5rem;
      border: 0.1rem solid var(--colorFjord);
    }
  }

  & .actions {
    padding: 0 var(--spacingSm) var(--spacingSm);
    flex: 0 0 auto;
    & small {
      @apply --f6;
    }
  }

  & .stats {
    display: flex;
    & .stat {
      flex: 1 1 auto;
      text-align: center;
      border-right: 0.1rem solid var(--colorGeyser);
      &:last-child {
        border-right: none;
      }
    }
  }

  & .nostyle:link {
    text-decoration: inherit;
    color: inherit;
    cursor: auto;
  }

  & .nostyle:visited {
      text-decoration: inherit;
      color: inherit;
      cursor: auto;
  }
}
</style>
