<template>
  <Modal
    class="edit-licenses-modal"
    :class="`step${step}`"
    v-bind="$attrs"
    :open="true"
    :title="`${$t('Edit Licenses')} - ${game.title}`"
    :prevent-scroll="true"
    @closeModal="(reason) => $emit('closeModal', reason)"
  >
    <div class="content">
      <h4
        class="step f-tiny mb2 mt4 ph4"
      >{{ $t('Step {current}/{total}', { current: step, total: 2 }) }}</h4>
      <!-- STEP 1  -->
      <template v-if="step === 1">
        <h4 class="f-body b mb4 ph4">{{ $t('Update Licenses Quantity')}}</h4>
        <div class="licenserow minutelicenserow pa4" v-if="availableLicenses.minute">
          <h4 class="licensename f-header" data-license-type="minute">
            <template v-if="hasPriceRecentlyChanged(ownership.minutePrice.current) && !ownership.percentageOff">
              <span class="blue">{{ $t('New') }}</span> -
            </template>
            {{ $t('Per Minute License')}}
          </h4>
          <small
            class="displayprice priceperminute f-body relative"
            :class="ownership.percentageOff ? 'blue b' : ''"
          >
            {{ ownership.minutePrice.current.displayPrice }}
            <span
              class="pricebeforediscount f-label absolute right-0 strikethrough"
              style="transform: translate(calc(100% + 5px));"
              v-if="ownership.percentageOff"
            >{{ ownership.priceChanges.minute.priceBeforeDiscount }}</span>
          </small>
          <p
            class="pricechange pricechangeminute blue f-label"
            v-if="ownership.priceChanges.minute"
          >
            <template v-if="ownership.percentageOff">
              <span
                v-if="ownership.percentageOff === '100%'"
              >{{ $t('Free until {date}', { date: ownership.priceChanges.minute.discountDisplayEndDate}) }}</span>
              <span
                v-else
              >{{ $t('{percentage} Off until {date}', { percentage: ownership.percentageOff, date: ownership.priceChanges.minute.discountDisplayEndDate }) }}</span>
            </template>
            <i18n v-else path="{newprice} starting {date}">
              <template #newprice>{{ ownership.priceChanges.minute.newPriceDisplay }}</template>
              <template #date>{{ ownership.priceChanges.minute.displayDateChange }}</template>
            </i18n>
          </p>
          <p
            class="a-area f-label slate"
          >{{ $t('Active: used when monthly or yearly licenses are not available.') }}</p>
        </div>
        <div class="licenserow monthlylicenserow pa4" v-if="availableLicenses.monthly">
          <h4 class="licensename f-header" data-license-type="monthly">
            <template v-if="hasPriceRecentlyChanged(ownership.monthlyPrice.current)">
              <span class="blue">{{ $t('New') }}</span> -
            </template>
            {{ $t('Monthly License')}}
          </h4>
          <small
            class="displayprice pricepermonth f-body"
          >{{ ownership.monthlyPrice.current.displayPrice }}</small>
          <i18n
            tag="p"
            class="pricechange pricechangemonthly blue f-label"
            v-if="ownership.priceChanges.monthly"
            path="{newprice} starting {date}"
          >
            <template #newprice>{{ ownership.priceChanges.monthly.newPriceDisplay }}</template>
            <template #date>{{ ownership.priceChanges.monthly.displayDateChange }}</template>
          </i18n>
          <CounterState
            :counter.sync="monthlyLicensesRenewCount"
            v-slot="{ counter, canSubstract, add, substract }"
          >
            <div class="a-area monthlylicensecontrol flex items-center">
              <UIButton
                @click="substract"
                :disabled="!canSubstract || monthlyIsGettingRemoved"
                icon="round-minus"
                tiny
                secondary
                class="removebtn mr3"
              ></UIButton>
              <small class="f-body relative">
                <span
                  class="monthlylicensescount absolute tc w-100"
                  style="left: 50%; transform: translateX(-50%);"
                >{{ $tc('0 Licenses | 1 License | {count} Licenses', counter) }}</span>
                <!-- mock text in order to prevent width glitch -->
                <span class="o-0">{{ $tc('0 Licenses | 1 License | {count} Licenses', 100) }}</span>
              </small>
              <UIButton
                @click="add"
                icon="round-plus"
                tiny
                secondary
                class="addbtn ml3"
                :disabled="monthlyIsGettingRemoved"
              ></UIButton>
            </div>
          </CounterState>
          <p
            v-if="monthlyLicensesRenewCount"
            class="b-area monthlyrenewingcount f-label slate"
          >{{ $tc('{count} license will be renewed on the first of next month. | {count} licenses will be renewed on the first of next month.', monthlyLicensesRenewCount) }}</p>
          <p class="c-area orange fill-error f-instructions" v-if="monthlyLicensesDiff < 0">
            <UIIcon class="mr1" style="width:1.5rem;vertical-align:top;" name="alert-triangle-fill"></UIIcon>
            {{ $tc('You still have 1 license until the end of the current month. | You still have {count} licenses until the end of the current month.', this.game.license.monthlyLicenses.length) }}
          </p>
        </div>
        <div class="licenserow yearlylicenserow pa4" v-if="availableLicenses.yearly">
          <h4 class="licensename f-header" data-license-type="yearly">
            <template v-if="hasPriceRecentlyChanged(ownership.yearlyPrice.current)">
              <span class="blue">{{ $t('New') }}</span> -
            </template>
            {{ $t('Yearly License')}}
          </h4>
          <small
            class="displayprice priceperyear f-body"
          >{{ ownership.yearlyPrice.current.displayPrice }}</small>
          <i18n
            tag="p"
            class="pricechange pricechangeyearly blue f-label"
            v-if="ownership.priceChanges.yearly"
            path="{newprice} starting {date}"
          >
            <template #newprice>{{ ownership.priceChanges.yearly.newPriceDisplay }}</template>
            <template #date>{{ ownership.priceChanges.yearly.displayDateChange }}</template>
          </i18n>
          <p
            class="a-area f-body"
          >{{ $tc('0 Licenses | 1 License | {count} Licenses', yearlyLicensesCount) }}</p>
          <div class="b-area">
            <template v-if="yearlyLicensesCount">
              <p class="f-label mt3">{{ $t('Automatically renew the license expiring on:') }}</p>
              <ul class="yearlylicenseslist">
                <li
                  class="yearlylicenseitem mt2 flex flex-wrap items-center"
                  v-for="license in yearlyLicenses"
                  :key="license.id || license._id"
                >
                  <UICheckbox
                    class="db mr4 mt2"
                    :title="formatDisplayDate(license.endsAt || license.renewsAt, { utc: true })"
                    :disabled="yearlyIsGettingRemoved"
                    v-model="license.renewable"
                  />
                  <UIButton
                    tiny
                    secondary
                    icon="trash"
                    class="mr4 mt2"
                    @click="deleteYearlyLicense(license)"
                    v-if="!license.id"
                  >{{ $t('Delete') }}</UIButton>
                  <span
                    class="orange fill-error f-instructions w-100 w-auto-m mt2"
                    v-if="!license.renewable"
                  >
                    <UIIcon
                      class="mr1"
                      style="width:1.5rem;vertical-align:top;"
                      name="alert-triangle-fill"
                    ></UIIcon>
                    {{ $t('This license won’t renew. You can still use it until {date}.', { date: getFormattedDateMinusOneDay(license.endsAt || license.renewsAt) }) }}
                  </span>
                </li>
              </ul>
            </template>
            <UIButton
              icon="round-plus"
              tiny
              secondary
              @click="addYearlyLicense"
              :disabled="yearlyIsGettingRemoved"
              class="yearlyaddlicensebtn mt3"
            >{{ $t('Add License') }}</UIButton>
          </div>
        </div>
      </template>
      <!-- END STEP 1  -->
      <!-- STEP 2  -->
      <template v-else-if="step === 2">
        <h4 class="f-body b mb4 ph4">{{ $t('Review Terms')}}</h4>
        <div class="tablesummary mb5 tl w-100">
          <template v-for="(item, i) in orderSummary">
            <div class="pl4 itemname" :class="i%2 && 'odditem'" :key="item.classification">
              <h6 class="f-thead dn db-m">{{ $t('Item') }}</h6>
              <p class="f-header">{{ item.title }}</p>
              <i18n
                path="Renewal date: {date}"
                class="f-tiny"
                v-if="item.renewalDate && item._billable"
              >
                <template #date>
                  <span
                    v-html="formatDisplayDate(item.renewalDate, { nonBreakingSpace: true, utc: true }) + ' - UTC 12:00 am'"
                  ></span>
                </template>
              </i18n>
              <i18n
                path="Expiry date: {date}"
                class="f-tiny"
                v-else-if="item.expiryDate && item._billable"
              >
                <template #date>
                  <span
                    v-html="formatDisplayDate(item.expiryDate, { nonBreakingSpace: true, utc: true }) + ' - UTC 12:00 am'"
                  ></span>
                </template>
              </i18n>
            </div>
            <div
              class="f-header itemquantity"
              :class="i%2 && 'odditem'"
              :key="item.classification + 'b'"
            >
              <h6 class="f-thead itemlabel">{{ $t('Quantity') }}</h6>
              <p class="itemvalue">{{ item.quantity }}</p>
            </div>
            <template v-if="!item.details">
              <div class="itemprice" :class="i%2 && 'odditem'" :key="item.classification + 'c'">
                <h6
                  class="f-thead itemlabel"
                >{{ item.proration ? $t('Prorated Price') : $t('Unit Price') }}</h6>
                <p class="f-header itemvalue">{{ item.unitAmount && item.unitAmount.displayValue }}</p>
                <small
                  class="f-tiny"
                  v-if="item.proration"
                >Full price: {{item.fullUnitAmount && item.fullUnitAmount.displayValue}}</small>
              </div>
              <div
                class="itemtotal f-header pr4"
                :class="i%2 && 'odditem'"
                :key="item.classification + 'd'"
              >
                <h6 class="f-thead itemlabel">{{ $t('Total Price') }}</h6>
                <span class="itemvalue">{{ item.amount && item.amount.displayValue }}</span>
              </div>
            </template>
            <template v-else>
              <div
                class="itemdetails f-header pr4"
                colspan="2"
                :class="i%2 && 'odditem'"
                :key="item.classification + 'e'"
              >
                <h6 class="f-thead itemlabel">{{ $t('Details') }}</h6>
                <h6 class="f-body itemvalue">{{ item.details }}</h6>
              </div>
            </template>
          </template>
        </div>
        <UseVuex v-slot="{ getters }">
          <p
            class="ph4 f-body b mb4"
            v-if="getters.currentOrganization.defaultCreditCard && order.total.value"
          >{{ $t('We will charge your credit card ending in {lastDigits} immediately.', { lastDigits: getters.currentOrganization.defaultCreditCard.last4 }) }}</p>
        </UseVuex>
      </template>
      <!-- END STEP 2  -->
      <div class="buttons pa4 bt b--geyser">
        <div v-if="step === 2 && order.total.value" class="total">
          <small class="f-thead">{{ $t('Amount Billed Immediately') }}</small>
          <h6 class="f2">USD {{ order.total.displayValue }}</h6>
        </div>
        <UIButton
          ghost
          class="cancelcta"
          @click="close"
          :style="{ gridColumnStart: shouldShowCloseButton ? -1 : 'auto' }"
        >{{ shouldShowCloseButton ? $t('Close') : $t('Cancel') }}</UIButton>
        <template v-if="step === 1">
          <UIButton
            class="maincta"
            @click="nextStep"
            :disabled="!hasChange || loadingOrder"
            v-if="!shouldShowCloseButton"
          >{{ $t('Next') }}{{ loadingOrder ? '...' : ''}}</UIButton>
        </template>
        <template v-else-if="step === 2">
          <UIButton
            ghost
            class="previouscta"
            @click="previousStep"
            v-if="step === 2"
          >{{ $t('Previous') }}</UIButton>
          <UIButton class="maincta" @click="confirm" :disabled="loadingOrder">
            <template v-if="order.total.value && isOnlyBillableTransaction">{{ $t('Pay') }}</template>
            <template
              v-else-if="order.total.value && isBillableTransaction && !isOnlyBillableTransaction"
            >{{ $t('Update & Pay') }}</template>
            <template v-else>{{ $t('Update') }}</template>
          </UIButton>
        </template>
      </div>
    </div>
  </Modal>
</template>

<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import Modal from '@/core/shared/components/Modal';
import Confirm from '@/core/shared/components/Confirm';
import UIIcon from '@/core/shared/components/ui/UIIcon';
import UIButton from '@/core/shared/components/ui/UIButton';
import UICheckbox from '@/core/shared/components/ui/UICheckbox';
import UseVuex from '@/core/shared/components/utils/UseVuex';
import moment from 'moment';
import { get, isEqual, keyBy } from 'lodash-es';
import { hasPriceRecentlyChanged, getFormattedDateMinusOneDay, formatDisplayDate, isPriceGettingRemoved } from '@/core/helpers/FormatHelpers';
import { generateId } from '@/core/shared/helpers/VariousHelper';

const CounterState = {
  props: {
    counter: { required: true, type: Number },
  },
  computed: {
    canSubstract () { return this.counter > 0; },
  },
  methods: {
    add () { return this.$emit('update:counter', this.counter + 1); },
    substract () { return this.$emit('update:counter', this.counter - 1); },
  },
  render (h) {
    const { canSubstract, counter, add, substract } = this;
    return this.$scopedSlots.default({ canSubstract, counter, add, substract });
  },
};

@Component({
  components: {
    UIIcon,
    UIButton,
    UICheckbox,
    UseVuex,
    Modal,
    Confirm,
    CounterState,
  },
  props: {
    game: { required: true },
  },
  methods: {
    hasPriceRecentlyChanged,
    getFormattedDateMinusOneDay,
    formatDisplayDate,
  },
})
export default class EditLicensesModal extends Vue {
  step = 1
  monthlyLicensesDiff = 0
  newYearlyLicenses = []
  yearlyLicensesChanges = {}
  order = null
  loadingOrder = false

  get ownership () { return this.game.ownership; }
  get hasChange () {
    let result = false;
    if (this.monthlyLicensesDiff !== 0) result = true;
    if (this.newYearlyLicenses.length !== 0) result = true;
    if (Object.keys(this.yearlyLicensesChanges).length !== 0) result = true;
    return result;
  }

  get availableLicenses () {
    return {
      minute: get(this.ownership, 'minutePrice.current', false),
      monthly: get(this.ownership, 'monthlyPrice.current', false),
      yearly: get(this.ownership, 'yearlyPrice.current', false),
    };
  }

  get orderSummary () {
    const summary = [];
    const orderItemsByClassication = keyBy(this.order.items, 'classification');
    if (orderItemsByClassication['monthly-new']) {
      summary.push({
        _billable: true,
        title: this.$t('Add Monthly License'),
        ...orderItemsByClassication['monthly-new'],
      });
    }
    if (orderItemsByClassication['yearly-new-renewing']) {
      summary.push({
        _billable: true,
        title: this.$t('Add Yearly License'),
        ...orderItemsByClassication['yearly-new-renewing'],
      });
    }
    if (orderItemsByClassication['yearly-new-expiring']) {
      summary.push({
        _billable: true,
        title: this.$t('Add Yearly License'),
        ...orderItemsByClassication['yearly-new-expiring'],
      });
    }
    if (this.order.yearlyCancelItemsCount > 0) {
      summary.push({
        _billable: false,
        classification: 'yearly-cancel',
        title: this.$t('Cancel Yearly License Renewal'),
        details: this.$t('You can use your yearly license until the end of the cycle but it won’t be renewed.'),
        quantity: this.order.yearlyCancelItemsCount,
      });
    }
    if (this.order.yearlyRenewalItemsCount > 0) {
      summary.push({
        _billable: false,
        classification: 'yearly-renewal',
        title: this.$t('Renew Yearly License'),
        details: this.$t('You will be billed on the day your yearly license renews.'),
        quantity: this.order.yearlyRenewalItemsCount,
      });
    }
    if (orderItemsByClassication['monthly-cancel']) {
      summary.push({
        type: 'monthly-cancel',
        _billable: false,
        title: this.$t('Cancel Monthly License Renewal'),
        details: this.$t('You can use your monthly license until the end of the current month but it won’t be renewed.'),
        ...orderItemsByClassication['monthly-cancel'],
      });
    }

    return summary;
  }

  get isBillableTransaction () {
    return this.orderSummary && this.orderSummary.some(({ _billable }) => _billable);
  }

  get isOnlyBillableTransaction () {
    return this.orderSummary && !this.orderSummary.some(({ _billable }) => !_billable);
  }

  get monthlyLicensesRenewCount () {
    const currentCount = this.game.license.monthlyLicenses ? this.game.license.monthlyLicenses.filter(({ renewable }) => renewable).length : 0;
    return currentCount + (this.monthlyLicensesDiff || 0);
  }

  set monthlyLicensesRenewCount (value) {
    const currentCount = this.game.license.monthlyLicenses ? this.game.license.monthlyLicenses.filter(({ renewable }) => renewable).length : 0;
    const diff = value - currentCount;
    this.monthlyLicensesDiff = diff;
  }

  get monthlyIsGettingRemoved () {
    return isPriceGettingRemoved(this.ownership.monthlyPrice);
  }

  get yearlyIsGettingRemoved () {
    return isPriceGettingRemoved(this.ownership.yearlyPrice);
  }

  get shouldShowCloseButton () {
    const shouldShowNextButton = (this.availableLicenses.monthly && !this.monthlyIsGettingRemoved) || (this.availableLicenses.yearly && !this.yearlyIsGettingRemoved);
    return !shouldShowNextButton;
  }

  get yearlyLicenses () {
    const { newYearlyLicenses, yearlyLicensesChanges } = this;
    const modifiedYearlyLicenses = this.game.license.yearlyLicenses ? this.game.license.yearlyLicenses.map(
      license => new Proxy(license, {
        get: (object, property) => {
          const merged = { ...license, ...this.yearlyLicensesChanges[license.id] };
          return merged[property];
        },
        set: (object, property, value) => {
          if (isEqual(license[property], value)) {
            this.$delete(yearlyLicensesChanges[license.id], property);
            if (!Object.keys(yearlyLicensesChanges[license.id]).length) this.$delete(yearlyLicensesChanges, license.id);
          } else {
            if (!yearlyLicensesChanges[license.id]) this.$set(yearlyLicensesChanges, license.id, { id: license.id, [property]: value });
            else this.$set(yearlyLicensesChanges[license.id], property, value);
          }
          return true;
        },
      }),
    ) : [];
    return [...modifiedYearlyLicenses, ...newYearlyLicenses];
  }

  get yearlyLicensesCount () {
    return this.yearlyLicenses.length;
  }

  addYearlyLicense () {
    this.newYearlyLicenses.push({
      _id: generateId(),
      endsAt: moment().add(1, 'year').toISOString(),
      renewable: true,
    });
  }

  deleteYearlyLicense ({ _id: idToFind }) {
    const index = this.newYearlyLicenses.findIndex(({ _id }) => _id === idToFind);
    this.newYearlyLicenses.splice(index, 1);
  }

  async nextStep () {
    this.loadingOrder = true;
    // if there already an order: eg when user is going back, we are passing the id so it can update
    const orderId = this.order ? this.order.id : undefined;
    try {
      const [, order] = await Promise.all([
        // get CC last 4 digit if not loaded
        this.$store.dispatch('getCreditCardLast4'),
        this.$store.dispatch('storeLicensesOrder', {
          id: orderId,
          contentOwnershipLicense: {
            id: this.game.license.id,
          },
          monthlyLicenses: (this.game.license.monthlyLicenses ? this.game.license.monthlyLicenses.length : 0) + this.monthlyLicensesDiff,
          yearlyLicenses: [
            ...this.newYearlyLicenses.map(({ renewable }) => ({ renewable })),
            ...Object.values(this.yearlyLicensesChanges),
          ],
        }),
      ]);
      this.order = order;
      this.step++;
    } finally {
      this.loadingOrder = false;
    }
  }

  previousStep () {
    this.step--;
  }

  close () {
    this.$emit('closeModal');
  }

  async confirm () {
    this.loadingOrder = true;
    try {
      await this.$store.dispatch('createNetLicensePurchaseInvoice', {
        id: this.order.id,
        contentOwnershipLicense: {
          id: this.game.license.id,
        },
        monthlyLicenses: (this.game.license.monthlyLicenses ? this.game.license.monthlyLicenses.length : 0) + this.monthlyLicensesDiff,
        yearlyLicenses: [
          ...this.newYearlyLicenses.map(({ renewable }) => ({ renewable })),
          ...Object.values(this.yearlyLicensesChanges),
        ],
      });
      await this.$store.dispatch('completeLicensesOrder', {
        id: this.order.id,
        contentOwnershipLicense: {
          id: this.game.license.id,
        },
      });
      await this.$store.dispatch('getGameLicenseDetails', this.game);
      const { href } = this.$router.resolve({ name: 'billing-invoices' });
      const sucessMsgBillable = this.$t('Your licenses were successfully updated. See the {link} for your purchase.', {
        link: `<a class="link-style" href="${href}" onclick="document.querySelector('#app').__vue__.$router.push({ name: 'billing-invoices' });return false;">${this.$t('invoice')}</a>`,
      });
      const sucessMsgUpdate = this.$t('Your licenses were successfully updated.');
      this.$store.commit('setFlash', {
        type: 'success',
        message: this.isBillableTransaction ? sucessMsgBillable : sucessMsgUpdate,
      });
    } catch (ex) {
      this.$store.commit('setFlash', {
        type: 'error',
        message: ex,
      });
    } finally {
      this.loadingOrder = false;
    }
    // TODO error: There was an error updating your licenses. [Stripe error message].
    this.close();
  }
}
</script>

<style scoped lang="postcss">
@import "core/shared/styles";

.edit-licenses-modal {
  & .content {
    min-width: 75vw;
  }
  & .pricebeforediscount {
    top: 0;
    line-height: 1;
    &:after {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      content: "";
      background-size: 100% auto;
      background-position: 50%;
      background-repeat: no-repeat;
      background-image: url('data:image/svg+xml; utf8, <svg viewBox="0 0 100% 100%" preserveRatio="none" xmlns="http://www.w3.org/2000/svg"><line x1="0" y1="40%" x2="100%" y2="60%" style="stroke:rgb(0,0,0);stroke-width:1"/></svg>');
    }
  }
  & .monthlylicensecontrol {
    @media (--tablet) {
      height: 0; /*HACK workaround chrome flexbox/grid glitch*/
    }
  }
  & .licenserow {
    display: grid;
    grid-template-columns: min-content auto;
    grid-auto-rows: max-content;
    align-items: baseline;
    grid-template-areas:
      "name price"
      "pricechange pricechange"
      "a a"
      "b b"
      "c c";
    row-gap: 1rem;
    @media (--tablet) {
      grid-template-areas: "name ." "price a" "pricechange b" ". c";
      column-gap: 3.5rem;
      justify-items: start;
      grid-template-columns: minmax(20%, 196px) 1fr;
    }
    &:nth-child(2n) {
      background-color: var(--colorCasper);
    }
  }
  & .licensename {
    white-space: nowrap;
    grid-area: name;
    @media (--tablet) {
      white-space: initial;
    }
  }
  & .pricechange {
    grid-area: pricechange;
  }
  & .a-area {
    grid-area: a;
  }
  & .b-area {
    grid-area: b;
  }
  & .c-area {
    grid-area: c;
  }
  & .displayprice {
    grid-area: price;
    /* grid-column-start: auto; */
    @apply --f3;
    &:before {
      content: "-";
      margin: 0 0.5rem 0 0.7rem;
    }
    @media (--tablet) {
      @apply --f5;
      &:before {
        all: initial;
      }
      /* grid-column-start: 1; */
    }
  }
  & .removebtn,
  & .addbtn {
    font-size: 1.4rem;
    & >>> .icon {
      height: 2.6rem;
      width: 2.6rem;
      @media (--tablet) {
        width: 1.5rem;
        height: 1.5rem;
      }
    }
  }
  & .tablesummary {
    display: grid;
    /* grid-gap: 1rem; */
    @media (--tablet) {
      grid-gap: 0;
      grid-template-columns: 40% repeat(3, minmax(auto, 1fr));
    }
  }
  & .odditem {
    background-color: var(--colorCasper);
  }
  & .itemquantity,
  & .itemprice,
  & .itemtotal,
  & .itemdetails {
    padding: 0 2rem;
    display: grid;
    grid-template-columns: 1fr auto;
    align-items: center;
    justify-content: space-between;
    @media (--tablet) {
      display: block;
      padding: 0;
    }
  }
  & .itemname,
  & .itemquantity,
  & .itemprice,
  & .itemtotal,
  & .itemdetails {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    @media (--tablet) {
      padding-top: 3rem;
      padding-bottom: 3rem;
    }
  }
  & .itemtotal,
  & .itemdetails {
    padding-bottom: 2.5rem;
  }
  & .itemname {
    padding-right: 2rem;
    padding-left: 2rem;
    padding-bottom: 3rem;
    padding-top: 3rem;
    position: relative;
    &:after {
      content: "";
      position: absolute;
      bottom: 1.5rem;
      left: 50%;
      width: calc(100% - 4rem);
      transform: translate(-50%, 0);
      border-bottom: 1px solid var(--colorSlate);
    }
    @media (--tablet) {
      &:after {
        content: none;
      }
      /* padding-left: 0; */
      margin: 0;
      padding-bottom: 2rem;
      padding-left: 2rem;
      border-bottom: none;
    }
  }
  & .itemdetails {
    grid-template-columns: 1fr;
    grid-gap: 1rem;
    & .itemvalue {
      @apply --f5;
    }
    @media (--tablet) {
      grid-column: 3 / -1;
      padding-right: 3rem;
    }
  }
  & .itemlabel {
    @apply --f8;
    @media (--tablet) {
      @apply --f8;
      color: var(--colorManatee);
    }
  }
  & .itemvalue {
    @apply --f5;
    @media (--tablet) {
      @apply --f3;
    }
  }
  & .buttons {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 2rem;
  }
  &.step1 {
    & .buttons {
      grid-template-areas: "cancelcta maincta";
      @media (--tablet) {
        grid-template-columns: 1fr minmax(20%, auto) minmax(20%, auto);
        grid-template-areas: "total cancelcta maincta";
      }
    }
  }
  &.step2 {
    & .buttons {
      grid-template-areas: "total total" "previouscta cancelcta" "maincta maincta";
      @media (--tablet) {
        grid-template-columns: auto auto auto auto;
        grid-template-areas: "total previouscta cancelcta maincta";
      }
    }
  }
  & .total {
    text-align: center;
    @media (--tablet) {
      text-align: left;
    }
  }
  & .total {
    grid-area: total;
  }
  & .cancelcta {
    grid-area: cancelcta;
  }
  & .previouscta {
    grid-area: previouscta;
  }
  & .maincta {
    grid-area: maincta;
  }
}
</style>
