<template>
  <div class="content-show-controllers-route">
    <template v-if="localGame">
      <BreadcrumbNavConnected class="mb4"/>
      <ContentBlock>
        <template #instructions>
          <h5>{{ $t('Vive Controller Instructions') }}</h5>
          <p>{{ $t('These instructions will only appear in the launcher if the station is using a Vive headset. Windows MR and Oculus support coming soon.') }}</p>
        </template>
        <div class="formgrid f-body mb5">
          <UISwitch
            :title="$t('Display instructions in the launcher')"
            :instructions="$t('Provide in-VR controller instructions for your customers, accessible via the dashboard overlay. If all the fields are left blank, the instructions panel will not appear.')"
            v-model="localGame.controlsEnabled"
          />
        </div>
        <div class="formgrid f-body">
          <UIInput
            :disabled="!localGame.controlsEnabled"
            :title="$t('Trigger')"
            v-model="localGame.controlsTrigger"
          />
          <UIInput
            :disabled="!localGame.controlsEnabled"
            :title="$t('Grip Buttons')"
            v-model="localGame.controlsGrip"
          />
          <UIInput
            :disabled="!localGame.controlsEnabled"
            :title="$t('Touchpad')"
            v-model="localGame.controlsTouchpad"
          />
          <UIInput
            :disabled="!localGame.controlsEnabled"
            :title="$t('Menu Button')"
            v-model="localGame.controlsMenu"
          />
          <UIInput
            :disabled="!localGame.controlsEnabled"
            :title="$t('Additional Instructions')"
            :instructions="$t('Some space reserved for additional text/tips, this will not be attached to a button.')"
            v-model="localGame.controlsExtra"
          />
        </div>
      </ContentBlock>
    </template>
  </div>
</template>

<script>
import Component from 'vue-class-component';
import Vue from 'vue';
import ContentBlock from '@/core/shared/components/ContentBlock';
import BreadcrumbNavConnected from '@/core/shared/components/_connected/BreadcrumbNavConnected';
import UIInput from '@/core/shared/components/ui/UIInput';
import UISwitch from '@/core/shared/components/ui/UISwitch';

@Component({
  components: {
    ContentBlock,
    BreadcrumbNavConnected,
    UIInput,
    UISwitch,
  },
  props: {
    game: { required: true },
  },
})
export default class ContentShowControllersRoute extends Vue {
  get localGame () {
    return new Proxy(this.game, {
      get (obj, k) {
        return obj[k];
      },
      set: (obj, k, val) => {
        this.$emit('edit', k, val);
        return true;
      },
    });
  }
}
</script>

<style scoped lang="postcss">
@import "core/shared/styles";

.content-show-controllers-route {
  @apply --container;
  padding-top: var(--spacingSm);
  @media (--desktop) {
    padding-top: var(--spacingMd);
  }
  & .formgrid {
    display: grid;
    grid-gap: var(--spacingMd);
    grid-template-columns: 1fr;
    @media (--tablet) {
      grid-template-columns: 1fr 1fr;
    }
  }
}
</style>
