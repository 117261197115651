<template>
  <div class="tag-show container">
    <div class="showtop">
      <BreadcrumbNav :items="[ { name: $t('Tags'), routerLink: { name: 'tags' } }, { name: $t('Edit Tag') } ]" />
      <UIButton tiny icon="trash" v-if="tagId !== 'new'" @click="confirmDeleteTag">{{ $t('Delete Tag') }}</UIButton>
    </div>

    <ValidationObserver v-slot="{ pristine, valid }" ref="validationObserver">
      <ContentBlock v-if="show" :loading="isLoadingTag" :required="true">
        <template #instructions>
          <h5>{{ $t('Tag Details') }}</h5>
          <p>{{ $t('Your tag’s name and how many times it is being used.') }}</p>
        </template>

        <TagDetails :tag="tag" :games="games" @edit="editTag" />
      </ContentBlock>

      <ContentBlock v-if="show" :loading="isLoadingLicenses">
        <template #instructions>
          <h5>{{ $t('Tag your Titles') }}</h5>
          <p>{{ $t('Pick titles from your Library to be tagged.') }}</p>
        </template>

        <TagTitles v-if="tag.games" :tag="tag" :tagId="tagId" :games="games" :filteredGameIds="filteredGameIds" @edit="editTag" @tagGameSearch="tagGameSearch" />
      </ContentBlock>

      <BottomBar :valid="!pristine && valid" :backRouterLink="{ name: 'tag-index' }" :saving="saving" @save="saveChanges" />
    </ValidationObserver>
  </div>
</template>

<script>
import Component, { mixins } from 'vue-class-component';

import FocusModeMixin from '@/core/shared/misc/FocusModeMixin';
import BottomBar from '@/core/shared/components/BottomBar';
import BreadcrumbNav from '@/core/shared/components/BreadcrumbNav';
import ContentBlock from '@/core/shared/components/ContentBlock';
import UIButton from '@/core/shared/components/ui/UIButton';

import { isEqual, sortBy } from 'lodash-es';
import { ValidationObserver } from 'vee-validate';

import TagDetails from './components/TagDetails';
import TagTitles from './components/TagTitles';

@Component({
  components: {
    BreadcrumbNav,
    BottomBar,
    ContentBlock,
    TagDetails,
    TagTitles,
    UIButton,
    ValidationObserver,
  },
  props: {
    tagId: {
      type: String,
      required: true,
    },
  },
})
export default class TagShowRoute extends mixins(FocusModeMixin) {
  defaultTag = { text: '', games: [] };
  tagChanges = {};
  saving = false;

  get tagInStore () {
    return (this.tagId === 'new') ? this.defaultTag : this.$store.getters['entities/Tag'](this.tagId);
  }

  get tag () {
    return { ...this.tagInStore, ...this.tagChanges };
  }

  get show () {
    return this.tag && this.tag.games && this.games;
  }

  get isLoadingTag () {
    return this.$store.state.tags.isFetchingTags && !this.tag.text;
  }

  get isLoadingLicenses () {
    return this.$store.state.library.isFetchingLicenses && this.games.length === 0;
  }

  editTag (property, value) {
    const dirty = !isEqual(this.tagInStore[property], value);
    if (dirty) this.$set(this.tagChanges, property, value);
    else this.$delete(this.tagChanges, property);
  }

  get games () {
    const games = this.$store.getters['entities/Game'](this.$store.state.library.games);
    if (games) {
      return sortBy(games.map(game => {
        return {
          id: game.id,
          title: game.title,
          organizationTags: game.organizationTags,
        };
      }), 'title');
    }
  }

  get filteredGameIds () {
    return this.$store.state.tags.filteredGameIds;
  }

  async tagGameSearch (search, filter) {
    this.$store.dispatch('tagGameSearch', {
      search,
      filter,
      tag: this.tag,
    });
  }

  created () {
    if (this.tagId === 'new') {
      this.tagChanges = { operatorOnly: true };
      this.$store.commit('setTitle', this.$t('New Tag'));
    } else {
      this.$store.commit('setTitle', this.$t('Edit Tag'));
    }
  }

  async saveChanges () {
    try {
      this.saving = true;
      const { isValid } = await this.$refs.validationObserver.validateWithInfo();
      if (!isValid) return;

      await this.$store.dispatch('storeTag', { id: this.tag.id, ...this.tagChanges });
      this.$store.commit('setFlash', {
        message: `${this.tag.text} ${this.$t('saved')}`,
        type: 'success',
      });
      this.$router.push({ name: 'tag-index' });
    } catch (e) {
      this.$store.commit('setFlash', {
        type: 'error',
        message: this.$t('There was an error saving this tag, please try again.'),
      });
    } finally {
      this.saving = false;
    }
  }

  confirmDeleteTag () {
    this.$store.commit('setConfirm', {
      show: true,
      title: this.$t('Are you sure you want to delete this tag?'),
      icon: 'icon-trash',
      buttons: [
        {
          name: this.$t('Cancel'),
          type: 'cancel',
        },
        {
          name: this.$t('Delete Tag'),
          action: async () => {
            try {
              this.$store.commit('setLoadingOverlay', true);
              await this.$store.dispatch('deleteTag', this.tag);
              this.$store.commit('setFlash', {
                type: 'success',
                message: this.$t('The tag was successfully deleted.'),
              });
              this.$router.push({ name: 'tag-index' });
            } catch (e) {
              this.$store.commit('setFlash', {
                type: 'error',
                message: this.$t('There was an error deleting this tag, please try again.'),
              });
            } finally {
              this.$store.commit('setLoadingOverlay', false);
            }
          },
        },
      ],
    });
  }

  beforeDestroy () {
    this.$store.dispatch('resetTagGameSearch');
  }
}
</script>

<style lang="postcss" scoped>
@import 'core/shared/styles';

.tag-show {
  margin-top: var(--spacingMd);

  & .showtop {
    display: flex;
    align-items: center;
    width: 100%;
    margin-bottom: var(--spacingMd);

    & > * {
      flex: 1 1 auto;

      &:last-child {
        flex: 0 0 auto;
        text-align: right;
      }
    }

  }
}
</style>
