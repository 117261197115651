<template>
  <div class="library-route">
    <FunctionalState ref="agreementmodalstate" v-slot="{ game }">
      <AgreementModalConnected
        ref="agreementmodal"
        class="agreementmodal"
        :ownership="game && game.ownership"
      />
    </FunctionalState>
    <div class="header-bar flex-m items-start mb5">
      <div class="mr4">
        <h2 class="f-header mb3">{{ $t('Adding and reordering content in your Library') }}</h2>
        <p
          class="f-body measure-wide"
          v-if="$permissions['content-only']"
        >{{ $t('These are all the titles you’ve added to your Library from the Marketplace or manually. Reorder them here to change the order they appear in the SpringboardVR Launcher. You share the same Library across all the stations in your account.') }}</p>
        <p
          class="f-body measure-wide"
          v-else
        >{{ $t('These are all the titles you’ve added to your Library from the Marketplace or manually. Reorder them here to change the order in which they appear in the Launcher.') }}</p>
      </div>
      <UIButton :to="{ name: 'content-add' }" ghost class>{{ $t('Add Content Manually') }}</UIButton>
      <UIButton :to="{ name: 'marketplace' }" primary>{{ $t('Add Marketplace Content') }}</UIButton>
    </div>
    <Panel class="searchpanel mb5 fadeIn animated" v-if="gamesCount && gamesCount > 0">
      <UIInput class="w-50-m" type="search" :title="$t('Search')" v-model="search" />
    </Panel>
    <div class="bundlescontainer" v-if="bundleLicenses.length">
      <h2 class="f-header">{{ $t('Bundles') }}</h2>
      <table class="bundletable w-100">
        <thead class="f-thead fadeIn animated">
          <th class="tc nowrap draghandle" :class="'-disabled'"></th>
          <th class="tl dn-m">{{ $t('Content Name') }}&thinsp;/&thinsp;{{ $t('Cost this Month') }}</th>
          <th class="imageth tl nowrap dn dtc-m">{{ $t('Image') }}</th>
          <th class="tl nowrap dn dtc-m">{{ $t('Bundle Name') }}</th>
          <th class="tl nowrap dn dtc-m">{{ $t('Content') }}</th>
          <th class="tl nowrap dn dtc-m">{{ $t('Discount') }}</th>
          <th class="nowrap dn dtc-m">{{ $t('Quantity') }}</th>
        </thead>
        <LibraryBundleItemTr
          v-for="bundleLicense in bundleLicenses"
          :key="bundleLicense.id"
          :bundleLicense="bundleLicense" />
      </table>
    </div>
    <div class="gamescontainer">
      <h2 class="f-header" v-if="bundleLicenses.length">{{ $t('Content') }}</h2>
      <div
        class="empty f-body tc w-60 center fill-geyser fadeIn animated"
        v-if="!isLoading && !games.length"
      >
        <UIIcon name="no-results" class="dib mv5" style="width:8rem;" />
        <i18n
          v-if="!search"
          tag="p"
          path="You don’t have content in your Library yet. To add new titles, go to the {link1} or {link2}."
        >
          <template #link1>
            <router-link class="link-style" :to="{ name: 'marketplace' }">{{ $t('Marketplace') }}</router-link>
          </template>
          <template #link2>
            <router-link
              class="link-style"
              :to="{ name: 'content-add' }"
            >{{ $t('add content manually') }}</router-link>
          </template>
        </i18n>
        <template v-else>
          <p>{{ $t('Sorry, we couldn’t find a title matching your search.') }}</p>
          <i18n tag="p" path="Try updating your search or find new content in the {link}.">
            <template #link>
              <router-link class="link-style" :to="{ name: 'marketplace' }">{{ $t('Marketplace') }}</router-link>
            </template>
          </i18n>
        </template>
      </div>
      <table class="licensetable w-100" v-else-if="isLoading || games.length">
        <thead class="f-thead fadeIn animated" v-show="gamesCount">
          <th class="tc nowrap draghandle" :class="!!search ? '-disabled' : ''">
            <UIIcon class="w2 fill-inherit" name="reorder" />
          </th>
          <th class="tl dn-m">{{ $t('Content Name') }}&thinsp;/&thinsp;{{ $t('Cost this Month') }}</th>
          <th class="imageth tl nowrap dn dtc-m">{{ $t('Image') }}</th>
          <th class="tl nowrap dn dtc-m">{{ $t('Content Name') }}</th>
          <th class="nowrap dn dtc-m" v-show="games.length">{{ $t('Cost this Month') }}</th>
          <th class="nowrap dn dtc-m" v-show="games.length">{{ $t('Usage this Month') }}</th>
          <th class="nowrap dn dtc-m" v-show="games.length">{{ $t('Installed') }}</th>
          <th class="nowrap dn dtc-m" v-show="games.length">&nbsp;</th>
        </thead>
        <Draggable
          tag="tbody"
          class="fadeIn animated"
          v-model="gameIds"
          v-bind="draggableOpts"
          @input="sortGames"
        >
          <template v-if="games.length">
            <LibraryItemTr
              class="licensetr"
              v-for="game in games"
              :key="game.id"
              :game="game"
              :loading="!loadedGamesSet.has(game.id)"
              :disable-reorder="!!search"
              @visible="onLibraryTrVisible"
            ></LibraryItemTr>
          </template>
          <template v-else>
            <LibraryItemTrPlaceholder class="licensetr" v-for="i in gamesCount" :key="i"></LibraryItemTrPlaceholder>
          </template>
        </Draggable>
      </table>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import UIButton from '@/core/shared/components/ui/UIButton';
import UIInput from '@/core/shared/components/ui/UIInput';
import UIIcon from '@/core/shared/components/ui/UIIcon';
import LibraryItemTrPlaceholder from './components/LibraryItemTrPlaceholder';
import Panel from '@/core/shared/components/Panel';
import Draggable from 'vuedraggable';
import AgreementModalConnected from '@/content/shared/AgreementModalConnected';
import FunctionalState from '@/core/shared/components/utils/FunctionalState';
import { catchQueryErrors } from '@/core/shared/helpers/ErrorHelper';

@Component({
  name: 'LibraryRoute',
  components: {
    FunctionalState,
    Draggable,
    UIButton,
    UIInput,
    UIIcon,
    Panel,
    AgreementModalConnected,
    LibraryBundleItemTr: () => import('./components/LibraryBundleItemTr'),
    LibraryItemTr: () => import('./components/LibraryItemTr'),
    LibraryItemTrPlaceholder,
  },
  watch: {
    search () {
      this.$store.dispatch('searchLicenses');
    },
    isLoading (val) {
      if (!val) this.cacheGames = this.games;
    },
    '$route.query': {
      async handler (val) {
        if (val.requireEula) {
          const id = val.requireEula;
          try {
            await this.$store.dispatch('getGameAgreement', { id });
          } catch (e) {
            catchQueryErrors(e, () => {
              this.resetQueryString();
            });
          }
          const game = this.$store.getters['entities/Game'](id);
          if (game) this.requireAgreementsForGame(game);
        }
      },
      immediate: true,
    },
  },
})
export default class LibraryRoute extends Vue {
  cacheGames = []
  get games () {
    if (this.loading) return this.cacheGames;
    return this.search
      ? this.$store.getters['entities/Game'](this.$store.state.library.searchResults)
      : this.$store.getters['entities/Game'](this.$store.state.library.games);
  }

  get gameIds () {
    return this.$store.state.library.games;
  }

  set gameIds (value) {
    return this.$store.commit('library/set', { path: 'games', value });
  }

  get bundleLicenses () {
    const result = this.$store.getters['entities/BundleLicense/all']();
    return (result ?? []).sort((a, b) => a.title > b.title);
  }

  get draggableOpts () {
    return { handle: '.draghandle', animation: 150, scroll: true, disabled: !!this.search || !this.games.length };
  }

  get isLoading () {
    return this.$store.state.library.isFetchingLicenses;
  }

  get gamesCount () {
    return this.$store.state.library.paginatorInfo && this.$store.state.library.paginatorInfo.perPage;
  }

  get search () {
    return this.$store.state.library.search;
  }

  set search (value) {
    this.$store.commit('library/set', { path: 'search', value });
  }

  async created () {
    this.$store.commit('setTitle', this.$t('My Library'));
    await this.$store.dispatch('fetchBundleLicenses');
    await this.$store.dispatch('getMyLicenses');
  }

  activated () {
    this.$store.commit('setTitle', this.$t('My Library'));
  }

  sortGames () {
    this.$store.dispatch('saveGamesOrder');
  }

  resetQueryString () {
    const { requireEula, ...queryWithoutEula } = this.$route.query;
    this.$router.push({ ...this.$route, query: queryWithoutEula });
  }

  get loadingGamesSet () {
    return new Set(this.$store.state.library.gamesLoading);
  }

  get loadedGamesSet () {
    return new Set(this.$store.state.library.gamesFullyLoaded);
  }

  onLibraryTrVisible ({ id }) {
    if (!this.loadingGamesSet.has(id)) {
      const gamesLoading = [...this.$store.state.library.gamesLoading, id];
      this.$store.commit('library/set', { path: 'gamesLoading', value: gamesLoading });
      this.$store.dispatch('lazyLoadGames');
    }
  }

  async requireAgreementsForGame (game) {
    await this.$nextTick();
    await this.$refs.agreementmodalstate.setState({ game });
    try {
      await this.$refs.agreementmodal.requireAgreementsForGame();
      this.$router.push({ name: 'content-library-show', params: { gameId: game.id } });
    } catch (e) {
      this.resetQueryString();
    }
  }
}
</script>

<style scoped lang="postcss">
@import "core/shared/styles";

.library-route {
  @apply --container;
  margin: 3rem 0;
  & .bundlescontainer {
    display: grid;
    grid-gap: var(--spacingSm);
  }
  & .bundletable {
    border-collapse: separate;
    border-spacing: var(--borderWidth);
    border: 0;
    margin-bottom: var(--spacingMd);
    & td,
    & th {
      vertical-align: middle;
      padding: 8px 5px;
      position: relative;
    }
  }

  & .gamescontainer {
    display: grid;
    grid-gap: var(--spacingSm);
  }
  --borderWidth: 6px;
  @media (--tablet) {
    --borderWidth: 10px;
  }
  & .w-1 {
    width: 1px;
  }
  & .header-bar {
    display: grid;
    grid-gap: var(--spacingSm);
    @media (--tablet) {
      grid-template-columns: 1fr auto auto;
    }
  }
  & .draghandle {
    fill: var(--colorFjord);
    transition: fill 0.4s ease-in-out;
    &.-disabled {
      fill: var(--colorGeyser);
    }
  }
  & .licensetable {
    border-collapse: separate;
    border-spacing: var(--borderWidth);
    border: 0;
    & td,
    & th {
      vertical-align: middle;
      padding: 8px 5px;
      position: relative;
    }
  }
  & .gamestableheader,
  & .licensecard {
    display: grid;
    grid-template-columns: 4rem minmax(100px, 150px) minmax(auto, 5fr) 1fr 1fr 1fr 1fr;
    align-items: center;
    grid-gap: var(--spacingMd);
    padding-left: var(--spacingMd);
    padding-right: var(--spacingMd);
  }
  @media (min-width: 1000px) and (max-width: 1110px) {
    & >>> .imagecell,
    & .imageth {
      display: none;
    }
  }
}
</style>
