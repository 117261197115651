<template>
  <div class="content-card">
    <div>
      <RatioContainer class="ratiocontainer" :ratio="46.5863">
        <div class="gradient h-100 w-100 absolute"></div>
      </RatioContainer>
    </div>
    <div class="bottom">
      <div class="content">
        <div class="basic tc">
          <h1 class="title gradient h2 mb3 w-50 ml-auto mr-auto">&nbsp;</h1>
          <h1 class="subtitle gradient mb5 h2 w-40 ml-auto mr-auto">&nbsp;</h1>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import idToGradient from '@/core/shared/helpers/idToGradient';
import RatioContainer from '@/core/shared/components/utils/RatioContainer';
@Component({
  components: {
    RatioContainer,
  },
})
export default class OwnershipCardPlaceholder extends Vue {
  get background () { return idToGradient('0'); }
}
</script>

<style scoped lang="postcss">
@import "core/shared/styles";

.content-card {
  @apply --panelDropShadow;
  @apply --f5;

  overflow: hidden;
  border-radius: var(--radius);
  background-color: var(--colorWhite);
  display: flex;
  flex-direction: column;
  cursor: pointer;

  & >>> .ratiocontainer {
    overflow: hidden;
  }
  & .gradient {
    background: var(--colorPlaceholderGradient);
  }

  & .bottom {
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  & .content {
    flex: 1 1 auto;
    display: flex;
    align-items: flex-start;

    /* padding: var(--spacingXS); */
    /* @media (--tablet) { */
    padding: var(--spacingSm);
    /* } */

    & .basic {
      flex: 1 1 auto;
      margin-right: var(--spacingSm);
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      width: auto;
    }
  }
}
</style>
