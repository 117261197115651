export default function idToGradient (id) {
  const firstNumber = getFirstNumber(id);
  const rotation = getRotation(firstNumber);
  const colorPair = getColorPair(firstNumber);
  return (
    'linear-gradient(' +
    rotation +
    'deg, ' +
    colorPair.primary +
    ' 0%, ' +
    colorPair.secondary +
    ' 100%)'
  );
}

function getFirstNumber (id) {
  return String(id)
    .split('')
    .reverse()
    .find(char => !Number.isNaN(parseInt(char)));
}

function getRotation (number) {
  return (number * 360) / 10;
}

function getColorPair (number) {
  if (number <= 3) {
    return {
      primary: '#ec4e36',
      secondary: '#ec3679',
    };
  } else if (number <= 6) {
    return {
      primary: '#3484eb',
      secondary: '#34e0eb',
    };
  } else {
    return {
      primary: '#f24847',
      secondary: '#f29d47',
    };
  }
}
