import {
  requestGameKey,
  persistState,
  installStationGame,
  uninstallStationGame,
  enableStationGame,
  disableStationGame,

  stationGames,

  clearOverrides,
  updateStationGame,
} from './common';

import {
  setStationId,
  stationGamesMapKeydByGame,
} from './from-station';

import {
  batchRequestGameKey,
  requestGameKeysForLocation,
  requestGameKeysForExperience,
  installAllStationGamesTransitioningtoCDS,
  installAllStationGamesForExperience,
  uninstallAllStationGamesForExperience,
  disableAllStationGamesForExperience,
  enableAllStationGamesForExperience,
  installAllStationGamesForLocation,
  uninstallAllStationGamesForLocation,
  disableAllStationGamesForLocation,
  enableAllStationGamesForLocation,
  installAllStationGamesForAccount,
  uninstallAllStationGamesForAccount,
  disableAllStationGamesForAccount,
  enableAllStationGamesForAccount,

  stationGamesTransitioningToCDS,
  locationsWithAtLeastOneStation,
  experiencesWithStationsByLocation,
  stationsByExperience,
  stationGamesByStation,
  getAllStationGamesForExperience,
  getAllStationGamesForLocation,

  setGameId,
} from './from-content';

const commonModule = {
  strict: true,
  namespaced: true,
  state: () => ({
    stationGamesOverrides: {},
  }),
  actions: {
    persistState,
    requestGameKey,
    installStationGame,
    uninstallStationGame,
    enableStationGame,
    disableStationGame,
  },
  getters: {
    stationGames,
  },
  mutations: {
    clearOverrides,
    updateStationGame,
  },
};

export const fromStation = {
  ...commonModule,
  state: () => ({
    stationGamesOverrides: {},
    stationId: null,
  }),
  actions: {
    ...commonModule.actions,
  },
  getters: {
    ...commonModule.getters,
    stationGamesMapKeydByGame,
  },
  mutations: {
    ...commonModule.mutations,
    setStationId,
  },
};

export const fromContent = {
  ...commonModule,
  state: () => ({
    stationGamesOverrides: {},
    gameId: null,
  }),
  actions: {
    ...commonModule.actions,
    batchRequestGameKey,
    requestGameKeysForLocation,
    requestGameKeysForExperience,
    installAllStationGamesTransitioningtoCDS,
    installAllStationGamesForExperience,
    uninstallAllStationGamesForExperience,
    disableAllStationGamesForExperience,
    enableAllStationGamesForExperience,
    installAllStationGamesForLocation,
    uninstallAllStationGamesForLocation,
    disableAllStationGamesForLocation,
    enableAllStationGamesForLocation,
    installAllStationGamesForAccount,
    uninstallAllStationGamesForAccount,
    disableAllStationGamesForAccount,
    enableAllStationGamesForAccount,
  },
  getters: {
    ...commonModule.getters,
    stationGamesTransitioningToCDS,
    locationsWithAtLeastOneStation,
    experiencesWithStationsByLocation,
    stationsByExperience,
    stationGamesByStation,
    getAllStationGamesForExperience,
    getAllStationGamesForLocation,
  },
  mutations: {
    ...commonModule.mutations,
    setGameId,
  },
};
