import { gql } from '@/core/shared/api';

export const persistState = async ({
  commit,
  state,
  dispatch,
  rootGetters,
}) => {
  const { stationGamesOverrides } = state;
  const stationGamesToSave = Object.values(stationGamesOverrides);

  if (!stationGamesToSave.length) return;

  const subQueriesArgs = {
    gamesToStore: [],
    gamesToInstall: [],
    gamesToUninstall: [],
  };
  for (const stationGame of stationGamesToSave) {
    const {
      enabled,
      game: { id: gameId },
      _uninstall,
      id,
      status,
      station,
    } = stationGame;
    const game = rootGetters['entities/Game'](gameId);
    let bucket = subQueriesArgs.gamesToStore;
    if (game.cds && enabled) bucket = subQueriesArgs.gamesToInstall;
    else if (game.cds && _uninstall) bucket = subQueriesArgs.gamesToUninstall;
    const payload = {
      id,
      enabled,
      status,
      game: { id: game.id },
      station: { id: station.id },
    };
    bucket.push(payload);
  }

  const query = `
    mutation (
      $gamesToStore: [StationGameInput]
      $gamesToInstall: [StationGameInput]
      $gamesToUninstall: [StationGameInput]
    ) {
      storedGames: storeStationGames(stationGames: $gamesToStore) {
        id
        enabled
        status
        game {
          id
          stationsInstalled
        }
        station { id }
      }
      installedGames: installStationGames(stationGames: $gamesToInstall) {
        id
        enabled
        status
        game {
          id
          stationsInstalled
        }
        station { id }
      }
      uninstalledGames: uninstallStationGames(stationGames: $gamesToUninstall) {
        id
        enabled
        status
        game {
          id
          stationsInstalled
        }
        station { id }
      }
    }
  `;

  const { storedGames, installedGames, uninstalledGames } = await gql.request(
    query,
    subQueriesArgs,
  );

  commit('clearOverrides');
  dispatch(
    'insertEntities',
    {
      name: 'StationGame',
      payload: [...storedGames, ...installedGames, ...uninstalledGames],
    },
    { root: true },
  );
};

export const requestGameKey = async (
  { commit, dispatch, rootGetters },
  stationGame,
) => {
  commit('setLoading', true, { root: true });
  const requestGameKeyQuery = `
    mutation (
      $station: StationInput
      $contentOwnership: ContentOwnershipInput
    ) {
      gameKey: requestGameKey(
        station: $station
        contentOwnership: $contentOwnership
      ) {
        id
        key
        station { id }
        game { id }
      }
    }
  `;
  const { station, game } = stationGame;
  const { ownership } = rootGetters['entities/Game'](game.id);
  const variables = {
    station: { id: station.id },
    contentOwnership: { id: ownership.id },
  };
  try {
    await gql.request(requestGameKeyQuery, variables);

    const { game: updatedGame } = await gql.request(
      `query { game(id:"${game.id}") {
        id
        stationGames {
          id
          key {
            id
            key
          }
          station { id }
          game { id }
        }
      } }`,
    );
    dispatch('insertEntities', {
      name: 'Game',
      payload: updatedGame,
    }, { root: true });
  } finally {
    commit('setLoading', false, { root: true });
  }
};

export const installStationGame = ({ commit, dispatch }, stationGame) => {
  return dispatch('enableStationGame', stationGame);
};

export const uninstallStationGame = ({ commit }, stationGame) => {
  commit('updateStationGame', {
    ...stationGame,
    enabled: false,
    _uninstall: true,
  });
};

export const enableStationGame = ({ commit }, stationGame) => {
  commit('updateStationGame', {
    ...stationGame,
    enabled: true,
  });
};

export const disableStationGame = ({ commit }, stationGame) => {
  commit('updateStationGame', {
    ...stationGame,
    enabled: false,
    _uninstall: false,
  });
};
