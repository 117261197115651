import { groupBy, keyBy, flatMap } from 'lodash-es';

export const stationGamesTransitioningToCDS = (state, getters) => {
  const { stationGames } = getters;
  return (
    stationGames &&
    stationGames.filter(
      ({ enabled, targetStatus, status }) => enabled && !targetStatus && !status,
    )
  );
};

export const locationsWithAtLeastOneStation = (
  state,
  getters,
  rootState,
  rootGetters,
) => {
  const locations = rootGetters['entities/Location/all']();
  return (
    locations.length &&
    locations.filter(({ stations }) => stations && stations.length)
  );
};

export const experiencesWithStationsByLocation = (
  state,
  getters,
  rootState,
  rootGetters,
) => {
  const stations = rootGetters['entities/Station/all']();
  const experiences = rootGetters['entities/Experience/all']();
  const experiencesWithStations =
    experiences &&
    experiences.filter(
      ({ id }) =>
        stations.filter(({ experience }) => experience && experience.id === id)
          .length,
    );
  return groupBy(experiencesWithStations, 'location.id');
};

export const stationsByExperience = (
  state,
  getters,
  rootState,
  rootGetters,
) => {
  return groupBy(rootGetters['entities/Station/all'](), 'experience.id');
};

export const stationGamesByStation = (
  state,
  getters,
  rootState,
  rootGetters,
) => {
  const stations = rootGetters['entities/Station/all']();
  const stationGamesByStation = keyBy(getters.stationGames, 'station.id');
  const generateDefaultStationGame = station => ({
    _id: `${state.gameId}:${station.id}`,
    enabled: false,
    status: null,
    game: { id: state.gameId },
    station: { id: station.id },
  });
  // here we have to seed local stationGame when it does not exists in the backend
  const generatedStationGames = stations.map(({ id }) => {
    const stationGame =
      stationGamesByStation[id] || generateDefaultStationGame({ id });
    const override =
      state.stationGamesOverrides[stationGame.id || stationGame._id];
    const localPendingStatus = {
      _originalValue: stationGame.enabled,
    };
    if (override) {
      if (override.enabled) {
        localPendingStatus._status = 'install_pending_save';
      }
      if (override.enabled && stationGame.status === 'installed') {
        localPendingStatus._status = 'enabled_pending_save';
      }
      if (!override.enabled && !override._uninstall) {
        localPendingStatus._status = 'disabled_pending_save';
      }
      if (!override.enabled && override._uninstall) {
        localPendingStatus._status = 'uninstall_pending_save';
      }
    }

    return new Proxy(stationGame, {
      get (target, property) {
        if (property in localPendingStatus) return localPendingStatus[property];
        if (override && property in override) return override[property];
        return target[property];
      },
    });
  });

  return keyBy(generatedStationGames, 'station.id');
};

export const getAllStationGamesForExperience = (state, getters) => {
  const { stationsByExperience, stationGamesByStation } = getters;
  return experience => {
    const { id } = experience;
    const stations = stationsByExperience[id];
    return stations.map(({ id }) => stationGamesByStation[id]);
  };
};

export const getAllStationGamesForLocation = (state, getters) => {
  const {
    experiencesWithStationsByLocation,
    getAllStationGamesForExperience,
  } = getters;
  return location => {
    const { id } = location;
    const experiences = experiencesWithStationsByLocation[id];
    return flatMap(experiences, experience =>
      getAllStationGamesForExperience(experience),
    );
  };
};
