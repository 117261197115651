import { keyBy } from 'lodash-es';

export const stationGamesMapKeydByGame = (
  state,
  getters,
  rootState,
  rootGetters,
) => {
  const games = rootGetters['entities/Game/all']();
  const stationGamesMapKeydByGame = keyBy(getters.stationGames, 'game.id');

  const generateDefaultStationGame = game => ({
    _id: `${game.id}:${state.stationId}`,
    _originalValue: false,
    enabled: false,
    status: null,
    game: {
      id: game.id,
    },
    station: {
      id: state.stationId,
    },
  });

  // here we have to seed local stationGame when it does not exists in the backend
  const generatedStationGames = games.map(({ id }) => {
    const stationGame =
      stationGamesMapKeydByGame[id] || generateDefaultStationGame({ id });
    const override =
      state.stationGamesOverrides[stationGame.id || stationGame._id];
    const localPendingStatus = { _originalValue: stationGame.enabled };

    if (override) {
      if (override.enabled) {
        localPendingStatus._status = 'install_pending_save';
      }
      if (override.enabled && stationGame.status === 'installed') {
        localPendingStatus._status = 'enabled_pending_save';
      }
      if (!override.enabled && !override._uninstall) {
        localPendingStatus._status = 'disabled_pending_save';
      }
      if (!override.enabled && override._uninstall) {
        localPendingStatus._status = 'uninstall_pending_save';
      }
    }

    return new Proxy(stationGame, {
      get (target, property) {
        if (property in localPendingStatus) return localPendingStatus[property];
        if (override && property in override) return override[property];
        return target[property];
      },
    });
  });

  return keyBy(generatedStationGames, 'game.id');
};
