<script>
// <UseVuex v-slot="{ state }>
//     <NowLine :currentTime="state.currentTime">
// </UseVuex>

import Vue from 'vue';
import Component from 'vue-class-component';

@Component({
  functional: true,
})
export default class UseVuex extends Vue {
  render (h, ctx) {
    const { state, getters } = ctx.parent.$store;
    return ctx.scopedSlots.default({ state, getters });
  }
}

</script>
