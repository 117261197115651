<template>
  <ValidationObserver ref="validationObserver" v-slot="{ pristine, invalid }">
    <Confirm
      class="license-source-modal show"
      :message="$t('Where did you get a license for this title?')"
      icon="copyright"
      :buttons="buttons"
      @closeConfirm="onCancel"
      v-bind="$props"
      v-on="$listeners"
      :show="true"
      :disableConfirm="pristine || invalid"
    >
      <template #content>
        <form class="form tl" @submit.prevent="onSubmit">
          <UISelect
            class="reasonselect"
            :title="$t('I got my license from:')"
            :placeholder="$t('Pick an option')"
            :instructions="$t('Most titles (even free) require you to have a commercial license. If you aren’t licensing from SpringboardVR, make sure you have a commercial license to use this title.')"
            v-model="internalValue.licensingSource"
            required
            rules="dropdown"
            vid="licenceSourceReason"
          >
            <option value="steam">{{$t('Steam Site Licensing / Cyber Cafe Program')}}</option>
            <option value="direct_deal">{{$t('Directly from the Content Creator / Studio')}}</option>
            <option value="other">{{$t('Other licensing platform')}}</option>
            <option value="na">{{$t('Not Applicable / I don’t have one')}}</option>
          </UISelect>
          <p class="f-instructions mv4">
            <span class="red">*</span>
            {{$t('Required')}}
          </p>
        </form>
      </template>
    </Confirm>
  </ValidationObserver>
</template>

<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import Modal from '@/core/shared/components/Modal';
import Confirm from '@/core/shared/components/Confirm';
import UIIcon from '@/core/shared/components/ui/UIIcon';
import UIButton from '@/core/shared/components/ui/UIButton';
import UISelect from '@/core/shared/components/ui/UISelect';
import { ValidationObserver } from 'vee-validate';

@Component({
  components: {
    UIIcon,
    UISelect,
    UIButton,
    Modal,
    Confirm,
    ValidationObserver,
  },
})
export default class LicenceSourceModal extends Vue {
  internalValue = {
    licensingSource: null,
  }

  get buttons () {
    return [
      {
        name: this.$t('Cancel'),
        type: 'cancel',
      },
      {
        name: this.$t('Add Content'),
        type: 'confirm',
        action: () => {
          return this.onSubmit();
        },
      },
    ];
  }

  async onSubmit () {
    const { isValid } = await this.$refs.validationObserver.validateWithInfo();
    if (isValid) {
      this.$emit('submit', this.internalValue);
    }
  }

  onCancel () {
    this.$emit('cancel');
  }
}
</script>

<style scoped lang="postcss">
@import "core/shared/styles";

.license-source-modal {
}
</style>
